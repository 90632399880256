@import "../../../style.scss";
.programLink {
    @extend .d-flex,
    .flex-column;
    border-left: 1px solid #cccccc;
    .nameProgram {
        font-size: ptr(12);
        font-weight: normal;
        @extend .d-flex,
        .align-items-center;
        span {
            transform: rotate(90deg);
            display: block;
            margin: 0 0 0 ptr(5);
            font-size: ptr(8);
        }
    }
}

.findIsvcontainer {
    background-color: #f7f7f7;
    min-height: 100vh;
    height: auto;
    /*.container.Isv{
    max-width: 100%;
    .listIsv{
      width: 75%;
      vertical-align: top;
      @extend .d-inline-block;
    }
  }*/
    .container {
        &.mx-auto.minHeightwin {
            max-width: 100%;
            width: 100%;
            padding: 0;
            @media (max-width: 1700px) {
                padding: 0 0 0 ptr(370);
            }
            @media (max-width: 1024px) {
                padding: 0 ptr(16);
                .listOfIsv,
                .scrollContainer {
                    height: auto;
                    padding: 0;
                }
            }
        }
    }
    .listOfIsvMatch {
        .isvLogo {
            &.blacklisted {
                position: relative;
                &::before {
                    content: "Blacklisted";
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    background: #fff;
                    width: 100%;
                    font-weight: 600;
                    padding: 2px 7px;
                    font-size: 14px;
                }
                img {
                    filter: blur(2px);
                }
            }
        }
    }
}

.getintouch {
    display: block;
    min-height: ptr(32);
    height: ptr(32);
    width: ptr(130);
    margin: ptr(20) auto;
    padding: 0;
}

::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;
    height: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    background: transparent;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

.filterFindIsv {
    position: fixed;
    left: 0;
    top: ptr(134);
    width: ptr(370);
    /*display:inline-block;*/
    background-color: #fff;
    height: calc(100vh - 106px);
    box-shadow: 2px 2px 10px rgba($color: #000000, $alpha: 0.06);
    overflow-y: auto;
    .contentFilter {
        padding: ptr(20);
        h2.title {
            text-align: center;
            font-size: ptr(20);
            font-weight: 600;
            margin: 0;
            padding: 0 0 ptr(10) 0;
            color: #333333;
        }
        .infoProgram {
            font-weight: normal;
            font-size: ptr(20);
            color: #333333;
            text-align: center;
            margin: 0;
            padding: 0 0 ptr(20) 0;
        }
        .btn-devinsider {
            @extend .d-flex,
            .align-items-center,
            .justify-content-center;
            width: 100%;
            height: ptr(32) !important;
            min-height: ptr(32);
            border: none;
            font-weight: 600;
            font-size: ptr(16);
            margin: ptr(16) 0;
            span[class*="icon-"] {
                margin: 0 ptr(10) 0 0;
            }
            &.cancel {
                color: #787878;
                border: 1px solid #cecece;
                background-color: #e7e7e7;
            }
        }
    }
    .infoProgram {
        font-weight: normal;
        font-size: ptr(20);
        color: #333333;
        text-align: center;
        margin: 0;
        padding: 0 0 ptr(20) 0;
    }
    .btn-devinsider {
        @extend .d-flex,
        .align-items-center,
        .justify-content-center;
        width: 100%;
        height: ptr(32) !important;
        min-height: ptr(32);
        border: none;
        font-weight: 600;
        font-size: ptr(16);
        margin: ptr(16) 0;
        span[class*="icon-"] {
            margin: 0 ptr(10) 0 0;
        }
        &.cancel {
            color: #787878;
            border: 1px solid #cecece;
            background-color: #e7e7e7;
        }
    }
    @media (max-width: 1024px) {
        position: static;
        width: 100%;
        height: auto;
        margin-top: ptr(20);
    }
}

.block {
    width: 100%;
    border-top: 1px solid #c4c4c4;
    padding: ptr(22) 0;
    margin: 0;
    &.no-border {
        border-top: none;
    }
    .btn {
        margin-top: 0;
    }
    .resetFilter {
        @extend .d-flex,
        .align-items-center,
        .justify-content-center;
        font-size: ptr(14);
        font-weight: normal;
        color: #495057;
        span {
            margin: 0 ptr(5) 0 0;
            color: #d8d8d8;
        }
    }
    .listCheck {
        display: none;
    }
    h3.title {
        color: #333333;
        font-size: ptr(16);
        font-weight: 600;
        cursor: pointer;
        position: relative;
        &.withChild {
            &:before {
                position: absolute;
                top: 5px;
                right: 5px;
                background: url(../../../resources/images/selectgris.png) center no-repeat;
                background-size: 90% auto;
                content: "";
                width: ptr(15);
                height: ptr(15);
                opacity: 0.5;
                transition: all 500ms;
            }
        }
        &.open {
            &:before {
                transform: rotate(180deg);
            }
            &~.listCheck {
                display: block;
            }
        }
    }
    .custom-control {
        margin-bottom: ptr(8);
        font-size: ptr(14);
        z-index: 0;
    }
    .rc-slider {
        margin: ptr(20) 0 ptr(30) 0;
    }
}

.btn-devinsider.cancel {
    color: #787878;
    border: 1px solid #cecece;
    background-color: #e7e7e7;
}

.seemore {
    font-size: ptr(14);
    color: $orange;
    &:hover {
        color: $orange;
    }
}

.listOfIsv {
    width: ptr(860);
    margin: 0 auto;
    @media (max-width: 1200px) {
        padding-left: ptr(16);
    }

    .loader {
        margin-top: 5em;
    }
}

.minHeightwin {
    @extend .d-flex,
    .align-items-start;
    width: ptr(880);
    @media (max-width: 1366px) {
        margin: 0 0 0 ptr(370) !important;
    }
}

.findMoreContainer {
    @extend .d-flex,
    .align-items-start,
    .justify-content-between;
    .instruction {
        width: ptr(286);
        margin: ptr(60) 0 0 0;
        .getintouch {
            display: block;
            margin: ptr(10) auto;
        }
    }
    @media (max-width: 1024px) {
        .scrollContainer {
            height: auto;
        }
    }
    @media (max-width: 768px) {
        flex-direction: column !important;
        .instruction,
        .listOfIsv {
            width: 100%;
            padding-left: 0;
        }
    }
}

.headerOfList,
.leftHead {
    @extend .d-flex,
    .align-items-center,
    .justify-content-between;
}

.headerOfList {
    margin: 0 0 ptr(10) 0;
    padding: 0;
    height: ptr(50);
    @media (max-width: 1200px) {
        padding: 0 ptr(16);
    }
    @media (max-width: 500px) {
        flex-direction: column-reverse !important;
        padding: ptr(40) 0 ptr(20) 0;
        height: auto;
        .leftHead {
            flex-direction: column !important;
            .number {
                padding: ptr(20) 0;
            }
            form,
            .formContentOpen {
                margin: 0 ptr(20);
                height: ptr(40);
                border-radius: 0;
                border: 1px solid #e7e7e7;
            }
        }
        .rightHead {
            .sortedby {
                padding: 0;
                flex-direction: column;
                label {
                    width: 100%;
                    padding-bottom: ptr(5);
                }
                .triOreder {
                    background-color: #fff;
                    width: 100%;
                    border: 1px solid #e7e7e7;
                }
            }
        }
        .number,
        form,
        .rightHead,
        .leftHead,
        .formContentOpen {
            width: 100% !important;
        }
    }
}

.leftHead {
    color: #4f4f4f;
    font-size: ptr(16);
    form,
    .formContentOpen {
        margin: 0 0 0 ptr(20);
        padding-left: ptr(28);
        background: #fff;
        cursor: pointer;
        input {
            border: none;
            background: transparent;
            display: none;
            font-size: ptr(14);
            color: #787878;
        }
        @extend .d-flex,
        .align-items-center;
        border-radius: ptr(20);
        overflow: hidden;
        width: ptr(28);
        height: ptr(28);
        transition: all 500ms;
        &.open {
            width: auto;
            input {
                display: block;
            }
        }
    }
    .formContentOpen {
        background: #fff url(../../../resources/images/search.svg) center left 6px no-repeat;
        background-size: ptr(15) auto;
    }
}

.rightHead {
    .tri__single-value {
        font-weight: 600;
        color: #6d6d6d;
        max-width: calc(100% - 20px);
    }
}

.scrollContainer {
    height: calc(100vh - 268px);
    overflow: auto;
    .aboutSection {
        margin-bottom: ptr(20);
    }
}

.listOfIsvMatch {
    @extend .d-flex,
    .flex-wrap;
    li {
        width: ptr(270);
        min-height: ptr(170);
        border: 1px solid #dadadb;
        @extend .d-flex,
        .align-items-start;
        padding: ptr(20) ptr(10);
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.16);
        margin: 0 ptr(15) ptr(15) 0;
        // padding: 0;
        // border: none;
        // box-shadow: none;
        .isvLogo {
            width: ptr(83);
            height: ptr(83);
            border: 1px solid #c3c3c3;
            margin: 0 ptr(10) 0 0;
            // overflow: hidden;
            &.empty {
                background: url(../../../resources/images/defaultCompany.png) center no-repeat;
                background-size: 40px auto;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .isvInfo {
            width: calc(100% - 93px);
            h3.title {
                font-size: ptr(16);
                font-weight: bold;
                color: #373737;
                /*cursor: pointer;*/
                span{
                    cursor: pointer;
                }
                span[class*="icon-"] {
                    position: relative;
                    /*z-index: 2;*/
                    cursor: pointer;
                    margin: 0 0 0 ptr(10);
                    &.icon-star-off {
                        color: #4f4f4f;
                    }
                    &.icon-star-on {
                        color: $orange;
                    }
                }
            }
            .description {
                cursor: pointer;
            }
            .country,
            .typeInvestor {
                @extend .d-flex,
                .align-items-center;
                color: #4e5961;
                margin: 0 0 ptr(10) 0;
                img {
                    margin: 0 ptr(5) 0 0;
                }

                &.country{

                    align-items: self-start!important;
                }

                &.country b {
            text-wrap: nowrap;
    }
            }
            .typeInvestor {
                font-size: ptr(14);
                margin: 0;
                b {
                    text-transform: uppercase;
                }
            }
            .matchValue {
                border-radius: ptr(30);
                @extend .d-flex,
                .align-items-center,
                .justify-content-center;
                border: 1px solid;
                font-size: ptr(16);
                font-weight: bold;
                width: ptr(110);
                margin: 0 0 ptr(10) 0;
                &.m95 {
                    background: transparent;
                    border-color: #00ab4a !important;
                    color: #00ab4a !important;
                    &.locked {
                        background: #00ab4a;
                        border-color: #00ab4a !important;
                        color: #fff !important;
                    }
                }

                &.m90 {
                    background: transparent;
                    border-color: #58c255 !important;
                    color: #58c255 !important;
                    &.locked {
                        background: #58c255;
                        border-color: #58c255 !important;
                        color: #fff !important;
                    }
                }
                &.m80 {
                    background: transparent;
                    border-color: #6cc76a !important;
                    color: #6cc76a !important;
                    &.locked {
                        background: #6cc76a;
                        border-color: #6cc76a !important;
                        color: #fff !important;
                    }
                }
                &.m65 {
                    background: transparent;
                    border-color: #6cc76a !important;
                    color: #6cc76a !important;
                    &.locked {
                        background: #6cc76a;
                        border-color: #6cc76a !important;
                        color: #fff !important;
                    }
                }
                &.m60 {
                    background: transparent;
                    border-color: #7a8288 !important;
                    color: #7a8288 !important;
                    &.locked {
                        background: #7a8288;
                        border-color: #7a8288 !important;
                        color: #fff !important;
                    }
                }
                &.mJoining {
                    background: transparent;
                    border-color: #e06661 !important;
                    color: #e06661 !important;
                    &.locked {
                        background: #e06661;
                        border-color: #e06661 !important;
                        color: #fff !important;
                    }
                }
                span.icon-nextbtn {
                    font-size: ptr(10);
                    margin: ptr(2) ptr(5) 0 0;
                }
            }
            .containerAction {
                @extend .d-flex,
                .align-items-center,
                .justify-content-start;
                font-weight: bold;
                font-size: ptr(16);
                min-height: ptr(40);
                height: auto;
                color: #777777;
                span {
                    margin-right: ptr(5);
                }
                .btn {
                    height: ptr(35);
                    min-height: ptr(35);
                    min-width: ptr(75);
                    font-size: ptr(14);
                    font-weight: bold;
                    color: #777777;
                    /*&:first-child {
                     margin-right: ptr(5);
                      }*/
                }
                .unlocked {
                    font-size: ptr(13);
                    font-weight: normal;
                }
            }
        }
        &:nth-child(3n + 3) {
            margin-right: 0;
        }
        &.locked {
            position: relative;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
            .isvLogo {
                position: relative;
                img {
                    filter: blur(3px);
                }
                .balcklist {
                    position: absolute;
                    width: 100%;
                    bottom: ptr(5);
                    left: 0;
                    text-align: center;
                    font-size: ptr(14);
                    font-weight: bold;
                }
            }
            // h3.title {
            //     span.name {
            //         filter: blur(5px);
            //     }
            // }
            .matchValue {
                &.m90 {
                    /*background: #00ab4a;*/
                    border-color: #00ab4a;
                    color: #fff;
                }
                &.m80 {
                    /*background: #58c255;*/
                    border-color: #58c255;
                    color: #fff;
                }
                &.m60 {
                    /*background: #7a8288;*/
                    border-color: #7a8288;
                    color: #fff;
                }
                &.m60 {
                    /*background: #7a8288;*/
                    border-color: #7a8288;
                    color: #fff;
                }
                &.mJoining {
                    /*background: #e06661;*/
                    border-color: #e06661;
                    color: #fff;
                }
            }
            // &:before {
            //     position: absolute;
            //     width: 100%;
            //     height: 100%;
            //     content: "";
            //     background: rgba($color: #000000, $alpha: 0.3);
            //     top: 0;
            //     left: 0;
            //     z-index: 1;
            //     transition: all 200ms;
            // }
            &:hover {
                &:before {
                    z-index: -1;
                    opacity: 0;
                }
            }
        }
        &.person {
            .isvLogo {
                border-radius: 100%;
            }
        }
        .btn-group {
            display: none!important;
        }
        &:hover {
            .btn-group {
                display: flex!important;
                position: relative;
                z-index: 2;
                button {
                    /*background: transparent;*/
                    border-color: #7b8389;
                }
            }
            .lockedPage,
            .unlocked {
                display: none!important;
            }
        }
        @media (max-width: 1200px) {
            width: 48%;
            margin: 0 1% ptr(16) 1% !important;
        }
        @media (max-width: 500px) {
            width: 100%;
        }
    }
    &.listUnlocked,
    &.listEvents {
        @extend .d-block;
        li {
            width: calc(100% - 10px);
            margin: 0 ptr(10) ptr(20) 0 !important;
            min-height: auto;
            padding: ptr(20);
            position: relative;
            .alert-dismissible {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                z-index: 2;
                width: 60%;
                height: ptr(50);
                @media( max-width: 768px) {
                    width: 90%;
                }
                @media (max-width: 500px) {
                    height: ptr(100);
                }
            }
            h3.title {
                color: $bleu;
                font-weight: 600;
            }
            .matchValue {
                font-weight: 600;
                font-size: ptr(14);
                border: none;
                padding: 0;
                justify-content: flex-start !important;
                margin: 0;
                background-color: transparent !important;
                b {
                    text-transform: uppercase;
                    font-weight: 600;
                    color: $gris;
                    padding: 0 ptr(10) 0 0;
                }
            }
            .country,
            .typeInvestor {
                align-items: sefl-start!important;
                b {
                    font-weight: 600;
                    padding: 0 ptr(10) 0 0;
                    text-wrap: nowrap;
                }
            }
            .isvInfo {
                @extend .d-flex,
                .justify-content-between;
                .actionUnlocked {
                    @extend .d-flex,
                    .flex-column,
                    .align-items-end;
                    .btnContent {
                        margin: 0 0 ptr(16) 0;
                        display: flex;
                        button.btn {
                            width: ptr(122);
                            height: ptr(32);
                            min-height: ptr(32);
                            padding: 0;
                            margin: 0 0 ptr(20) 0 !important;
                            border: 1px solid $orange;
                            &.view {
                                background-color: transparent;
                                color: $orange;
                                margin-right: ptr(10) !important;
                            }
                            &.unlockedBtn {
                                background: #7a8288;
                                border-color: #7a8288;
                            }
                        }
                    }
                    .unlockedBy {
                        @extend .d-flex,
                        .align-items-center;
                        font-size: ptr(14);
                        padding: 0 0 ptr(3) 0;
                        b {
                            font-weight: 600;
                            padding: 0 ptr(10) 0 0;
                        }
                        &.statusContact {
                            font-weight: 600;
                            position: relative;
                            padding: 0 0 0 ptr(20);
                            span {
                                margin-right: ptr(5);
                            }
                            &:before {
                                content: "";
                                height: ptr(10);
                                width: ptr(10);
                                border-radius: ptr(10);
                                position: absolute;
                                top: ptr(6);
                                left: ptr(5);
                            }
                            &.notConnected {
                                &:before {
                                    background-color: #f30028;
                                }
                            }
                            &.hold {
                                &:before {
                                    background-color: #ff9900;
                                }
                            }
                            &.connected {
                                &:before {
                                    background-color: #04aa09;
                                }
                            }
                            &.archived {
                                &:before {
                                    background-color: #4f4f4f;
                                }
                            }
                        }
                    }
                }
            }
            &.locked {
                h3.title {
                    span.name {
                        filter: none;
                    }
                }
                &::before {
                    display: none;
                }
            }
        }
        @media (max-width: 500px) {
            li {
                width: 100%;
                background: #fff;
                margin: 0 0 ptr(20) 0 !important;
                flex-direction: column !important;
                .isvLogo {
                    margin: ptr(10) auto;
                }
                .isvInfo {
                    width: 100%;
                    flex-direction: column !important;
                    .actionUnlocked {
                        flex-direction: column-reverse !important;
                        width: 100%;
                        .btnContent,
                        .unlockedBy,
                        .statusContact {
                            width: 100%;
                        }
                        .btnContent {
                            margin: 0;
                            padding: ptr(20) 0 0 0;
                            display: flex;
                            justify-content: space-between;
                            flex-direction: row !important;
                        }
                    }
                    .calendar {
                        padding: 0;
                        .infoEvent {
                            flex-direction: column !important;
                            div[class*="Event"] {
                                padding-bottom: ptr(10);
                            }
                        }
                    }
                    .txtLabel {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

.fixePagination {
    height: ptr(60);
    padding: 0;
}

.unlockedSelect {
    label {
        font-size: ptr(14);
        display: inline-block;
        padding-right: ptr(10);
    }
    select {
        appearance: none;
        background: url(../../../resources/images/select.svg) center right 5px no-repeat;
        background-size: 10px auto;
        width: ptr(210);
        height: ptr(35);
        color: $gris;
        padding: 0 0 0 ptr(16);
        &:focus-visible {
            outline: 0;
        }
    }
}

.blacklistPopup {
    border-radius: ptr(6);
    width: 100%;
    margin: ptr(20) auto;
    box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.16);
    display: block;
    padding: ptr(20) ptr(16);
    font-size: ptr(15);
    color: #373737;
    h3.title {
        color: #373737;
        font-size: ptr(16);
        font-weight: 600;
        margin: 0;
        padding: 0 0 ptr(16) 0;
    }
    button.btn {
        min-height: ptr(38);
        text-align: center;
        height: ptr(38);
        padding: 0 ptr(20);
        &.cancel {
            background-color: #e4e6eb;
            color: #57585a;
            font-weight: normal;
            border: none;
        }
        &.downloadBtn {
            margin: ptr(10) 0 ptr(20) 0;
        }
    }
}

ul.listAddFile {
    li {
        @extend .d-flex,
        .align-items-center;
        margin: 0 0 ptr(20) 0;
        .choseFile {
            @extend .d-flex;
            margin: 0 ptr(20) 0 0;
        }
        .btn.chooseFile {
            border-radius: ptr(4) 0 0 ptr(4);
            font-weight: normal;
            span {
                margin: 0 ptr(10) 0 0;
            }
        }
        .containerfilechoosed {
            @extend .d-flex,
            .align-items-center;
            overflow: hidden;
            padding: 0 0 0 ptr(10);
            position: relative;
            border: 1px solid #d2d6de;
            background-color: #eeeeee;
            border-left: 0;
            width: ptr(400);
            border-radius: 0 ptr(4) ptr(4) 0;
            height: ptr(38);
            .remove {
                background-color: #d9534f;
                color: #fff;
                width: ptr(38);
                height: ptr(36);
                @extend .d-none,
                .align-items-center,
                .justify-content-center;
                position: absolute;
                top: 0;
                right: 0;
                font-size: ptr(28);
                border-radius: 0 ptr(4) ptr(4) 0;
            }
            &:hover {
                color: $bleu;
                .remove {
                    @extend .d-flex;
                }
            }
        }
        .addFile {
            margin: 0 0 0 ptr(20);
        }
    }
}

.listOfBlacklist {
    margin: ptr(30) auto;
    width: 100%;
    height: auto;
    border-collapse: collapse;
    border: 0;
    color: #7b7b7b;
    a,
    a:hover {
        color: #7b7b7b;
    }
    a:hover {
        text-decoration: underline;
    }
    tr.title {
        color: #373737;
    }
    td {
        border: 1px solid #373737;
        border-collapse: collapse;
        height: ptr(34);
        text-align: center;
    }
}

/*Categories icons*/

.cat .icon-msg-find-investors::before, .cat .icon-msg-classified-ads::before, .cat .icon-msg-find-programs::before{
    content:" ";
    display: block;
     background: url(../../../resources/images/search.svg) center no-repeat;
     width: 30px;
     height: 30px;
}

.listOfIsv.findMore li.locked {
    border: none;
    box-shadow: none;
    padding: 0
}