@import "../../../../style.scss";

.BillingCenter {
    .contentBorder.forBillingCenter {
        .visa {
            align-items: center;
        }

        width:ptr(700);
        margin: 0 auto;

        @media (max-width: 1024px) {
            width: 100%;
        }
    }
}

.forBillingCenter {
    .contentBorder {
        .radius {
            padding: 0;
        }
    }

    .nav {
        align-items: center;
        text-align: center;
    }

    .form-group {
        padding-right: ptr(10) !important;
    }

    .continue {
        text-align: center;

        span {
            font-size: ptr(35);
        }

        p {
            font-size: ptr(18);
            font-weight: 600;
        }
    }

    .program {
        @extend .d-flex;
        align-items: center;
        border: 1px solid #C1C1C1;
        padding: ptr(15);

        .image {
            width: ptr(70);
            height: ptr(70);

            img {
                border: 1px solid #E8E8E8;
                width: ptr(70);
                height: ptr(70);
                object-fit: contain;
            }
        }

        .desc {
            padding-left: ptr(15);

            .titleProgram {
                font-weight: 600;
            }
        }
    }

    #changeMember {
        background: url(../../../../resources/images/user-circle-solid.png) 10px center no-repeat !important;
        padding-left: ptr(50);
    }
}

.lineAccordion {
    border: none !important;
}

.planOem {
    .tit {
        min-height: ptr(110);
    }

    h6 {
        font-weight: 600;
        margin-bottom: 0;
    }

    button {
        background: #fff;
        color: $orange;
        border: 1px solid $orange;
        border-radius: 3px;
        font-size: ptr(14);
        margin-left: ptr(5);
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .row {
        margin: 0;
    }

    .lineAccordion {
        button {
            top: ptr(-185);
            right: 0;
            width: 50%;
        }
    }
    .selector{
        padding-left: 0 !important;
    }

    .infoAffiliated {
        width: ptr(100);
        .role {
            color: initial;
        }
    }
}

.forPaiement {
    padding: ptr(15) ptr(25) !important;
    &.row{
        margin: 0;
    }
    .title {
        font-weight: 600;
        padding-bottom: ptr(15);
    }

    .desc {
        font-size: ptr(15);
    }

    &.registeredCard {
        @extend .d-block;

        .titleCard {
            font-size: ptr(15);
            font-weight: 600;
            margin-bottom: 0;
        }

        .cardR {
            position: relative;
            border: 1px solid #C1C1C1;
            min-width: ptr(450);
            width: ptr(450);
            padding: ptr(20);
            margin-top: ptr(15);

            a {
                color: initial;
            }

            .others {
                position: absolute;
                right: 0;
                top: 15px;
                transform: rotate(90deg);
                font-size: 7px;
            }

            .expirationDate {
                font-size: ptr(13);
                color: #B5B5B5;
            }

            button {
                border: none;
                background: none;
            }

            .removeCard {
                border: 1px solid #BDBDBD;
                position: absolute;
                font-size: ptr(15);
                padding: 5px 15px;
                right: 15px;
                top: 35px;
            }
        }
    }
}

.forRemoveCard {
    .continue {
        div {
            text-align: left;
        }
    }

    .cardR {
        min-width: ptr(390) !important;
        width: ptr(390) !important;
        margin: ptr(25) 0;
    }
}

.separ {
    position: relative;
    margin: 0 12px 0 5px;

    &::after {
        content: ".";
        font-size: ptr(25);
        position: absolute;
        top: ptr(-15);
    }
}

.checkCard {
    ul {
        @extend .d-flex;
        margin-top: 3px;

        li {
            margin-right: ptr(5);

            img {
                height: 30px;
                border: 1px solid lightgrey;
                border-radius: 4px;
            }
        }
    }

    .form-group {
        margin: 20px;
        display: flex;
        align-items: center;
    }

    input[type='radio']:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #ffffff;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid $orange;
    }

    input[type='radio']:checked:after {
        width: 14px;
        height: 14px;
        border-radius: 15px;
        top: -3px;
        left: 0px;
        position: relative;
        background-color: #ff6600;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #fff;
    }

    input[type='radio']:checked:before {
        width: 16px;
        height: 16px;
        border-radius: 15px;
        top: -0.05rem;
        left: -1px;
        position: absolute;
        background-color: $orange;
        content: '';
        display: inline-block;
        visibility: visible;
    }
}

.registeredCard {
    min-height: ptr(300);
}

.visibility-hidden {
    visibility: hidden;
}