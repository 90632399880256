@import "../../../style.scss";
.icon-star-on {
    color: $orange;
}

.icon-star-off {
    color: inherit;
}

.leftHead {
    form::before {
        font-family: "icomoon";
        content: "\e911";
        margin-left: ptr(-23);
        font-weight: 600;
        font-size: ptr(18);
    }
}

.semi-bold {
    font-weight: 600;
}

.sTitle {
    p {
        color: #939393;
    }
    label {
        font-weight: 600;
    }
}

h3.title.DIMemberTitle {
    @extend .d-flex;
    .activeDIMember {
        color: $gris;
        font-size: ptr(12);
        @extend .d-flex;
        span[class *="icon-"] {
            margin: 0 ptr(5) 0 ptr(10) !important;
            width: ptr(14);
            height: ptr(14);
            border-radius: ptr(20);
            @extend .d-flex,
            .align-items-center,
            .justify-content-center;
            color: #fff;
            background: #58c255;
            font-size: ptr(9)
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        .activeDIMember {
            margin: ptr(5) 0 0 0 !important;
            span[class *="icon-"] {
                margin: 0 ptr(5) 0 0 !important;
            }
        }
    }
}

.Programs {
    .title {
        width: ptr(170) !important;
    }
}

.findIsvcontainer {
    .justForProfil {
        padding: ptr(25);
        border-radius: 3px;
        border: 1px solid #cecece;
        @media (max-width: 600px) {
            .btn {
                height: auto;
            }
        }
    }
}

.programContainer {
    .scrollContainer {
        /*height: calc(100vh - 175px);*/
    }
    .infoProgram {
        font-size: ptr(14);
        font-weight: normal;
        color: #4f4f4f;
    }
    .semi-bold {
        font-weight: 600;
        margin-right: ptr(5);
    }
    .gray {
        background-color: #7a8288;
        border-color: #7a8288 !important;
    }
    .topic {
        display: inline-block;
        font-size: 0.75rem;
        font-weight: 600;
        color: #444;
        margin: 0 0.3125rem 0.3125rem 0;
        background: #e8e8e8;
        padding: 0 0.3125rem;
        border-radius: 0.625rem;
        /*cursor: pointer;*/
    }
    .similarPrograms {
        ul {
            @extend .d-flex,
            .align-items-center,
            .justify-content-start;
            margin-top: ptr(10);
        }
        .info {
            width: ptr(60);
            height: ptr(60);
            border: 1px solid #c3c3c3;
            border-radius: 3px;
            margin-right: ptr(15) !important;
            padding: 0;
            overflow: hidden;
            &:hover {
                box-shadow: 1px 1px 4px #7a8288;
                transition: all 0.2s;
            }
            img {
                width: 100%;
                height: ptr(60);
                object-fit: cover;
                &:hover {
                    transform: translateY(0) scale(1.3);
                    transition: all 0.2s;
                }
            }
        }
    }
    @media (max-width: 600px) {
        .listOfIsvMatch {
            .isvInfo {
                width: 100%;
                flex-direction: column;
                .calendar {
                    padding-left: 0;
                }
                .actionUnlocked {
                    margin-top: ptr(20);
                }
                .edit {
                    margin-bottom: ptr(10);
                }
            }
        }
    }
}

.OneCaseContainer {
    h3.title {
        font-weight: 600;
        font-size: ptr(24);
        color: #000;
    }
    .contentBorder {
        max-width: ptr(700);
        margin: 0 auto;
    }
    .title {
        text-align: center;
    }
    .image {
        text-align: center;
        img {
            width: ptr(230);
            height: auto;
        }
    }
    .btn {
        font-weight: 600;
        width: 100%;
        margin-top: ptr(20);
    }
}

.TwoCaseContainer {
    h3.title {
        font-weight: 600;
        font-size: ptr(24);
        color: #000;
    }
    .progress-bar {
        background-color: #70bc1c;
        border-radius: ptr(50);
    }
    .contentBorder {
        max-width: ptr(900);
        margin: 0 auto;
    }
    .listCompletition {
        li {
            border: 1px solid #dadadb;
            padding: ptr(25);
            margin: ptr(20) 0;
            position: relative;
            border-radius: 3px;
            @extend .d-flex,
            .align-items-center,
            .justify-content-start;
            .title {
                color: #1897c8;
                font-weight: 600;
            }
        }
        .completitionLogo {
            margin: 0 ptr(20) 0 0;
            span.icon-check {
                font-size: ptr(25);
                color: #70bc1c;
            }
        }
        .linkEditLeftCompany {
            display: block !important;
            top: ptr(36);
        }
    }
}

.threeCaseContainer {
    .triOreder {
        width: ptr(110);
    }
    .listOfIsvMatch {
        li {
            width: ptr(400);
            padding: 0;
            min-height: ptr(370);
            margin: 0 ptr(15) ptr(15) 0;
            background-color: #ffffff;
            position: relative;
            @extend .d-block;
            @media (max-width: 500px) {
                width: 100%;
            }
        }
        .photoMur {
            height: ptr(120);
            img {
                width: 100%;
                object-fit: cover;
                height: ptr(120);
            }
        }
        .isvInfo {
            width: 100%;
            padding: ptr(40) ptr(15);
            height: ptr(190);
        }
        .description {
            p {
                font-size: ptr(14);
                color: #939393;
            }
            &.prog {
               /*  height: ptr(50); */
               height: auto;
                overflow: hidden;
            }
        }
        .isvLogo {
            background-color: #ffffff;
            position: absolute;
            right: 0;
            bottom: ptr(-35);
            img {
                object-fit: contain;
                // padding: ptr(5);
            }
        }
        .coverPhoto {
            position: relative;
            padding: 0;
        }
        .matchValue {
            border-radius: ptr(30);
            @extend .d-flex,
            .align-items-center,
            .justify-content-center;
            border: 1px solid;
            font-size: ptr(16);
            font-weight: bold;
            width: ptr(110);
            margin: 0 0 ptr(10) 0;
            color: #fff !important;
            position: absolute;
            bottom: ptr(-23);
            left: ptr(15);
            &.m90 {
                background: #00ab4a;
                border-color: #00ab4a;
                color: #ffffff;
            }
            &.m80 {
                background: #58c255;
                border-color: #58c255;
                color: #ffffff;
            }
            &.m65 {
                background: #6cc76a;
                border-color: #6cc76a;
                color: #ffffff;
            }
            &.m60 {
                background: #7a8288;
                border-color: #7a8288;
                color: #ffffff;
            }
            .icon-nextbtn {
                font-size: ptr(10);
                margin-right: ptr(5);
                margin-top: 1px;
            }
        }
        .star {
            position: absolute;
            top: ptr(10);
            left: ptr(15);
            background-color: #fff;
            padding: 4px;
            border-radius: 2px;
            font-size: ptr(22);
            cursor: pointer;
        }
        .foot {
            text-align: center;
            font-size: ptr(14);
            border-top: 3px solid #dadada;
            @extend .d-flex,
            .align-items-stretch;
            div {
                @extend .d-inline-block;
                border-left: 1px solid #dadada;
                padding: ptr(15) ptr(5) 0;
                width: calc(100% / 3);
                vertical-align: top;
                min-height: ptr(90);
                span {
                    img {
                        margin-right: ptr(5);
                    }
                }
            }
            .title {
                color: #939393;
                margin-bottom: 5px;
            }
        }
    }
}

.pageCompleted {
    .icon-check {
        font-size: ptr(60);
        background: #66dd6e;
        color: #fff;
        padding: ptr(20) ptr(15) ptr(15) ptr(20);
        border-radius: 50rem;
    }
}

.eventInfos {
    &.direct {
        cursor: initial;
    }
}

.premiumBadge {
    display: flex;
    align-items: center;
    .badgeP {
        display: flex;
        align-items: center;
        background: $orange;
        color: #fff;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 0 10px;
    }
    .txt {
        background: #FFE8E8;
        color: $orange;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 0 10px;
    }
}