@import "../../../style.scss";
a.choicePost {
    .radius {
        width: ptr(80);
        height: ptr(80);
        border: 2px solid #474747;
        @extend .d-flex,
        .align-items-center,
        .justify-content-center;
        position: relative;
        margin-bottom: ptr(10);
        border-radius: 100%;
        span {
            color: #474747;
            font-size: ptr(30)
        }
    }
    &.active,
    &:hover {
        .radius {
            border-color: $orange;
            span {
                color: #fff;
            }
        }
    }
    &.radius {
        &:before {
            content: "";
            position: absolute;
            top: ptr(10);
            right: ptr(10);
            width: ptr(24);
            height: ptr(24);
            background: url(../../../resources/images/checked-active.svg) center no-repeat;
            background-size: 100% auto;
            opacity: 0;
        }
        &:hover {
            &:before {
                opacity: 1;
            }
        }
    }
}

.eventCreation {
    input[type="text"]:focus-visible,
    textarea:focus-visible,
    select:focus-visible {
        border: 1px solid #BDBDBD;
        outline: none;
    }
    max-width: 100%;
    width: ptr(720);
    height: auto;
    .form-control {
        border: none!important;
        height: auto;
        padding: 0 0 ptr(30) 0;
        label {
            font-weight: 600;
            display: block;
            margin: 0;
            padding: 0 0 ptr(10) 0;
            &.addEndTime {
                color: $bleu;
                font-weight: normal;
                margin: 0 ptr(20);
                padding: 0;
                cursor: pointer;
            }
        }
        .orangecheck {
            margin: 0 0 0 ptr(20);
            label {
                font-weight: normal;
            }
        }
    }
    input[type="text"] {
        width: 100%;
        height: ptr(38);
        border-radius: 3px;
        border: 1px solid #BDBDBD;
        padding: 0 ptr(10);
    }
    textarea {
        width: 100%;
        height: ptr(170);
        border: 1px solid #BDBDBD;
        border-radius: 3px;
        padding: ptr(16);
    }
    .dateEvent {
        @extend .d-flex,
        .align-items-center,
        .flex-wrap;
        .react-datepicker-wrapper {
            width: ptr(216);
            border: 1px solid #BDBDBD;
            height: ptr(38);
            border-radius: 3px;
            background: url(../../../resources/images/calendar.png) center right 10px no-repeat;
            background-size: auto 25px;
            cursor: pointer;
            input[type="text"] {
                border: none;
                height: ptr(36);
                width: 100%;
                background: transparent;
                cursor: pointer;
            }
        }
        .timeEvent {
            width: ptr(150);
            height: ptr(38);
            border: 1px solid #BDBDBD;
            border-radius: 3px;
            appearance: none;
            background: url(../../../resources/images/select.svg) center right 10px no-repeat;
            background-size: auto 6px;
            padding: 0 ptr(30) 0 ptr(10);
            margin: 0 0 0 ptr(30)
        }
    }
    .listOfTopic {
        padding: 0 0 ptr(10) 0;
        span.label {
            font-size: ptr(15);
            display: inline-block;
            padding: 0 ptr(20) 0 0
        }
    }
}