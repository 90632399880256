@import "../../../../style.scss";
ul.NumberOfActivity {
    @extend .d-flex,
    .flex-wrap;
    padding: ptr(16);
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: .16);
    margin: 0 0 ptr(40) 0;
    li {
        color: $gris;
        width: 25%;
        padding: ptr(16) ptr(20);
        @extend .d-flex,
        .align-items-center;
        span[class *="icon-"] {
            font-size: ptr(32);
            margin-right: ptr(10);
        }
        b {
            color: $orange;
            font-weight: 600;
            display: block;
            font-size: ptr(22);
            line-height: 1;
        }
        .label {
            font-size: ptr(14);
        }
    }
    @media (max-width: 768px) {
        li {
            width: 50%;
        }
    }
}

.rightCommunityForum {
    .contactInfoItem {
        h2.title {
            font-weight: 600;
            font-size: ptr(20);
            border-bottom: 1px solid $gris;
            padding-bottom: ptr(20);
            margin-bottom: ptr(20);
        }
        .fauxText {
            padding-top: ptr(10);
        }
        .editItem {
            position: absolute;
            top: 0;
            right: 0;
            width: ptr(30);
            height: ptr(30);
            @extend .d-flex,
            .align-items-center,
            .justify-content-center;
            border: 1px solid $bleu;
            span {
                color: $bleu;
            }
        }
    }
}

.containerMemberActivity {
    position: relative;
}

.nameOfTitle {
    &.triOreder {
        width: ptr(200);
    }
    .tri__single-value {
        font-weight: 600;
        font-size: ptr(20);
        color: #444444;
        max-width: calc(100% - 30px);
    }
    .tri__menu {
        width: ptr(300);
    }
}

.blockOfList {
    width: 100%;
    margin: 0;
    padding: 0 0 ptr(30) 0;
}

.labelLeft {
    &.activityProfil {
        .block {
            padding: ptr(26) 0 0 0!important;
            margin: 0 0 ptr(36) 0;
            border-top: 1px solid #c4c4c4;
            &:last-child {
                margin-bottom: 0;
            }
            h2.title {
                font-size: ptr(16);
                font-weight: 600;
                border: none;
                margin-bottom: 0;
                padding-bottom: ptr(20);
                color: $gris;
            }
        }
    }
    .blockProfil {
        margin: ptr(-16) ptr(-16) ptr(16) ptr(-16);
        width: calc(100% + 32px);
        height: auto;
        h2.title {
            background: #e5e5e5;
            padding: ptr(10) ptr(16);
            font-size: ptr(14);
            font-weight: 600;
            color: #6d6d6d;
            text-align: center;
            margin-bottom: ptr(10);
        }
    }
}

.profilContent {
    @extend .d-flex,
    .flex-column,
    .justify-content-center;
    padding: ptr(16);
    font-size: ptr(16);
    .imgProfil {
        width: ptr(118);
        height: ptr(118);
        border-radius: ptr(118);
        margin: 0 auto ptr(16) auto;
        overflow: hidden;
        border: 1px solid #969696;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &.empty {
            background: #fff url(../../../../resources/images/user.svg) center no-repeat;
            background-size: 70% auto;
        }
    }
    .name {
        text-align: center;
        font-weight: 600;
    }
    .post {
        .showpopOverPerso {
            margin: 0 0 0 ptr(8);
        }
    }
}