.center-form {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: content-box;
  width: 70%;
  height: 70vh;
  transform: translate(-50%, -50%);
}

.title-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icons {
  position: absolute;
  left: 2rem;
}

.titles {
  align-self: center;
  text-align: center;
}

.container-picture {
  display: flex;
  flex-direction: row;
  padding: 4rem 5rem;
}

.container-with-border {
  flex: 1;
  display: flex;
  flex-direction: row;
  border: 1px solid gray;
  border-radius: 2px;
  margin: 0px 2px;
  padding: 2rem 1rem;
}

.btn-addphoto {
  margin-left: 3rem;
  flex: 0.5;
  text-align: center;
  margin-top: 10%;
}
img {
  height: 50px;
  width: 50px;
  padding: 3rem;
}
.content-image {
  text-align: center;
  border: 1px gray solid;
  height: 150px;
  width: 150px;
  border-radius: 100%;
  margin-right: 2rem;
}

.allTextDescription {
  flex: 1;
  padding-top: 2rem;
}

.btn-skyp {
  margin: 10px;
}
