@import "../../../style.scss";
.bggris {
    &.dashboard {
        min-height: calc(100vh - 47px);
        padding: 0;
        .navDashboard {
            background-color: #fff;
            width: 100%;
            min-height: ptr(58);
            display: block;
            border-bottom: 1px solid #c1c1c1;
            .container {
                padding: 0 ptr(40);
                @extend .d-flex,
                .align-items-center;
                height: ptr(58);
                font-size: ptr(14);
                color: $gris;
            }
        }
    }
}

.stepDashboard {
    @extend .d-flex;
    li {
        width: 25%;
        @extend .d-flex,
        .align-items-center;
        &.stepItem {
            padding: 0 0 0 ptr(60);
            //   background: url(../../../resources/images/fleche.svg) center left 30px
            //     no-repeat;
            background-size: 3.4px auto;
        }
        a {
            font-weight: 600;
            color: $orange;
            @extend .d-flex,
            .align-items-center;
            &.done {
                color: $gris;
                font-weight: normal;
            }
            span {
                display: block;
                margin-right: ptr(5);
            }
        }
    }
}

.withPost {
    position: relative;
    width: ptr(60);
    height: ptr(60);
    overflow: visible;
    @extend .d-flex,
    .justify-content-center;
    .imageAffiliatedcompany {
        border: 1.5px solid $orange !important;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        &.empty {
            background: #fff url(../../../resources/images/user.svg) center no-repeat;
            background-size: 70% auto;
        }
    }
    .nameOfPost {
        width: ptr(70);
        height: ptr(70);
        background-color: $orange;
        border-radius: 70px;
        margin: ptr(5) 0 0 0;
        @extend .d-flex,
        .justify-content-center,
        .align-items-end;
        color: #fff;
        font-size: ptr(14);
        font-weight: 600;
        background-position: center bottom 4px;
        background-repeat: no-repeat;
        background-size: auto 9px;
        &.isv {
            background-size: auto 9px;
            background-image: url(../../../resources/images/isv-icon.svg);
        }
        &.admin {
            background-size: auto 11px;
            background-image: url(../../../resources/images/admin-icon.svg);
            background-position: center bottom 5px;
        }
        &.program {
            background-position: center bottom 3px;
            background-size: auto 16px;
            background-image: url(../../../resources/images/program-icon.svg);
        }
        &.expert {
            background-position: center bottom 2px;
            background-size: auto 14px;
            background-image: url(../../../resources/images/expert-icon.svg);
        }
        &.investor {
            background-position: center bottom 5px;
            background-size: auto 15px;
            background-image: url(../../../resources/images/investor-icon.svg);
        }
        &.reseller {
            background-position: center bottom 5px;
            background-size: auto 14px;
            background-image: url(../../../resources/images/reseller-icon.svg);
        }
        &.expert {
            background-position: center bottom 5px;
            background-size: auto 14px;
            background-image: url(../../../resources/images/expert-icon.svg);
        }
        &.moderator {
            background-position: center bottom 5px;
            background-size: auto 14px;
            background-image: url(../../../resources/images/moderator-icon.png);
        }
    }
}

.containerDashboard {
    .container {
        padding: ptr(35) ptr(40);
        .leftDashboard {
            width: ptr(245);
            margin-right: ptr(20);
            .block {
                border: 1px solid #c1c1c1;
                background: #fff;
                border-radius: ptr(5);
                box-shadow: 0px 0px 5px rgba($color: #000, $alpha: 0.2);
                overflow: hidden;
                padding: 0;
                .imageAffiliatedcompany {
                    margin-right: 0;
                }
                margin: 0 0 ptr(10) 0;
                &.allblock {
                    font-size: ptr(14);
                    h3.title {
                        font-size: ptr(14);
                        color: #aeaeae;
                        font-weight: 600;
                        margin: 0;
                        padding: ptr(10) ptr(15);
                        background-color: #f2f2f2;
                        text-transform: uppercase;
                        text-align: left;
                    }
                    p {
                        padding: ptr(10) ptr(15);
                    }
                    &.secondBlock {
                        opacity: 0;
                        z-index: -1;
                        position: fixed;
                        width: ptr(245);
                        top: ptr(70);
                        &.showopacity {
                            opacity: 1;
                            z-index: 0;
                        }
                    }
                }
            }
            .listAffiliatedleft {
                li {
                    padding: ptr(15) ptr(8);
                    border-bottom: 1px solid #c1c1c1;
                    &:last-child {
                        border-bottom: 0;
                    }
                    &.companyItem {
                        .imageAffiliatedcompany {
                            border: none;
                            border-radius: 0;
                            &.empty {
                                background-image: url(../../../resources/images/defaultCompany.png);
                            }
                        }
                        .infoAffiliated {
                            @extend .d-flex,
                            .flex-column,
                            .justify-content-center;
                        }
                    }
                    .infoAffiliated {
                        width: calc(100% - 60px);
                        h4.title {
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
        .rightDashboard {
            width: ptr(816);
            .bigBlock {
                width: 100%;
                padding: 0;
                margin: 0 0 ptr(10) 0;
                border: 1px solid #c1c1c1;
                background-color: #fff;
                box-shadow: 0px 0px 5px rgba($color: #000, $alpha: 0.2);
                h3.title {
                    font-weight: 600;
                    padding: ptr(10) ptr(16);
                }
                &.feed {
                    h3.title {
                        font-size: ptr(20);
                        color: #444444;
                        position: relative;
                        border-bottom: 1px solid #c1c1c1;
                        a {
                            position: absolute;
                            font-size: ptr(16);
                            color: #6d6d6d;
                            right: ptr(16);
                            top: ptr(15);
                            font-weight: normal;
                        }
                    }
                }
                &.program {
                    h3.title {
                        font-size: ptr(13);
                        font-weight: 600;
                        color: #999999;
                        text-transform: uppercase;
                        margin: 0;
                        padding: ptr(16);
                    }
                }
                h3.title {
                    /*display: none;*/
                }
                &:first-child {
                    border-radius: ptr(5) ptr(5) 0 0;
                    h3.title {
                        display: block;
                    }
                }
            }
        }
    }
}

.feedList {
    margin: 0;
    padding: 0;
    li {
        width: 100%;
        margin: 0;
        padding: ptr(10) ptr(16);
        border-bottom: 1px solid #c1c1c1;
        @extend .d-flex,
        .align-items-stretch;
        &:last-child {
            border-bottom: 0;
        }
        .imageAffiliatedcompany {
            align-self: center;
        }
        .infoFeed {
            padding: 0 ptr(20);
            width: calc(100% - 250px);
            .textArticle {
                position: relative;
                margin: 0 0 ptr(20) 0;
                &.hideTxt {
                    height: ptr(35);
                    overflow: hidden;
                    &:after {
                        background: linear-gradient( rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
                        position: absolute;
                        width: 100%;
                        height: ptr(100);
                        bottom: 0;
                        left: 0;
                        content: "";
                    }
                }
            }
            .postBy {
                margin: 0;
                padding: 0 0 ptr(16) 0;
                font-size: ptr(13.5);
                a {
                    color: $bleu;
                }
            }
            .titlePost {
                font-weight: 600;
                font-size: ptr(16);
                /*padding: 0 0 ptr(16) 0;*/
                span {
                    &.article {
                        color: $orange;
                        padding: 0 ptr(20) 0 0;
                        position: relative;
                        text-transform: uppercase;
                        &:before {
                            content: "";
                            height: ptr(3);
                            width: ptr(3);
                            background: $gris;
                            border-radius: 20px;
                            position: absolute;
                            top: 50%;
                            right: ptr(10);
                        }
                    }
                    &[class*="icon-"] {
                        font-size: ptr(14);
                        color: #67b346;
                        margin: 0 ptr(5) 0 0;
                    }
                }
            }
            .textArticle {
                p {
                    height: auto;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                a {
                    display: inline-block;
                    margin: 0 0 ptr(10) 0;
                    font-weight: 600;
                    font-size: ptr(14);
                }
            }
        }
        .infoAboutPost {
            @extend .d-flex,
            .flex-column,
            .justify-content-between,
            .align-items-end;
            width: ptr(190);
            .time {
                color: $bleu;
                font-size: ptr(16);
            }
            .likeandComment {
                @extend .d-flex,
                .align-items-center;
                font-size: ptr(14);
                color: #6d6d6d;
                .comment {
                    margin: 0 ptr(10) 0 0;
                    padding: 0;
                    span {
                        margin: 0 ptr(5) 0 0;
                        display: inline-block;
                    }
                }
                .nbrLike {
                    border-radius: ptr(2);
                    padding: ptr(2) ptr(10);
                    cursor: pointer;
                    span {
                        color: $orange;
                        margin: 0 ptr(5) 0 0;
                    }
                    &:hover {
                        span {
                            color: #6D6D6D;
                        }
                    }
                }
                .linkDrop {
                    background: url(../../../resources/images/dropfeed.svg) center no-repeat;
                    background-size: 15px auto;
                    width: ptr(40);
                    height: ptr(20);
                    display: block;
                    cursor: pointer;
                    transition: all 500ms;
                    &.open {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

.seeAll {
    @extend .d-flex,
    .align-items-center;
    height: ptr(40);
    padding: 0 0 0 ptr(10);
    border-top: 1px solid #c1c1c1;
    color: #6d6d6d;
    font-weight: 600;
    span[class*="icon-"] {
        transform: rotate(180deg);
        display: block;
    }
    &:hover {
        color: $gris;
    }
}

.listProgram {
    margin: 0 0 ptr(16) 0;
    padding: 0 ptr(16);
    li {
        @extend .d-flex,
        .justify-content-between;
        margin: 0;
        padding: ptr(10) 0;
        border-bottom: 2px dashed #c1c1c1;
        .nameItem {
            font-weight: 600;
            font-size: ptr(17);
        }
        .visit {
            border-radius: 3px;
            border: 1px solid #cecece;
            background: #e7e7e7;
            padding: ptr(5) ptr(20);
            color: $gris;
            &:hover {
                background: rgba($color: #e7e7e7, $alpha: 0.5);
            }
        }
    }
}

.listDiscussion {
    margin: 0;
    padding: ptr(15) ptr(10);
    width: 100%;
    li {
        width: 100%;
        padding: 0 0 ptr(10) 0;
        a {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            color: #6d6d6d;
            font-weight: 600;
            span {
                display: inline-block;
                margin: 0 ptr(5) 0 0;
                color: #c1c1c1;
            }
        }
    }
}

.calendar {
    margin: 0;
    padding: ptr(10) ptr(16) 0 ptr(16);
    .item {
        padding: 0 0 ptr(10) 0;
        .date {
            @extend .d-flex;
            color: $bleu;
            font-weight: 600;
            cursor: pointer;
            .cal {
                border-radius: ptr(3);
                box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.3);
                min-width: ptr(35);
                height: ptr(45);
                line-height: 1;
                color: #000;
                font-weight: bold;
                @extend .d-flex,
                .flex-column,
                .align-items-center;
                padding: ptr(10) 0 0 0;
                position: relative;
                overflow: hidden;
                margin: 0 ptr(10) 0 0;
                &:before {
                    position: absolute;
                    background-color: $orange;
                    width: 100%;
                    height: ptr(5);
                    content: "";
                    top: 0;
                    left: 0;
                }
                .month {
                    text-transform: uppercase;
                }
                .day {
                    font-size: ptr(20);
                }
            }
        }
        .infoEvent {
            @extend .d-flex,
            .justify-content-between;
            padding: ptr(10) 0;
            font-weight: 600;
            font-size: ptr(12);
            div[class*="Event"] {
                padding: 0 0 0 ptr(20);
                background-position: center left;
                background-repeat: no-repeat;
                background-size: ptr(16) auto;
                &.dateEvent {
                    background-image: url(../../../resources/images/dateEvent.svg);
                }
                &.placeEvent {
                    background-image: url(../../../resources/images/placeEvent.svg);
                }
                &.hourEvent {
                    background-image: url(../../../resources/images/info.svg);
                }
            }
        }
    }
}

.categContainer {
    @extend .d-flex,
    .flex-row;
    font-size: ptr(15);
    color: #6d6d6d;
    .category {
        padding: 0 ptr(10) 0 0;
        margin-bottom: ptr(5);
        /*min-width: ptr(90);*/
        @extend .d-flex,
        .flex-wrap,
        .align-items-center;
        &::after {
            content: "|";
            margin-left: ptr(10);
            color: #6d6d6d;
        }
    }
    .subCategory {
        @extend .d-flex,
        .flex-wrap;
        width: 250px;
        justify-content: flex-start;
        span {
            font-size: ptr(13);
            font-weight: 600;
            color: #444444;
            background-color: #e8e8e8;
            border-radius: 10px;
            display: block;
            padding: 0 ptr(5);
            margin: 0 ptr(5) ptr(5) 0;
            cursor: pointer;
        }
        @media (max-width: 768px) {
            width: auto;
        }
    }
}

.categContainer {
    @extend .d-flex,
    .flex-row;
    font-size: ptr(15);
    color: #6d6d6d;
    .category {
        /*margin: 0 ptr(10) 0 0;*/
        padding: 0 ptr(10) 0 0;
        /*border-right: 1px solid #969696;*/
    }
    .subCategory {
        @extend .d-flex,
        .flex-wrap;
        span {
            font-size: ptr(13);
            font-weight: 600;
            color: #444444;
            background-color: #e8e8e8;
            border-radius: 10px;
            padding: 0 ptr(5);
            margin: 0 ptr(5) ptr(5) 0;
            cursor: pointer;
        }
    }
}

.emptyFile {
    width: 100%;
    height: ptr(300);
    margin: 0 0 ptr(20) 0;
    text-align: center;
    font-weight: 600;
    color: #b5b2b2;
    &.max {
        background: #fff url(../../../resources/images/empty.png) center top 50px no-repeat;
        background-size: ptr(80) auto;
        padding: ptr(170) 0 0 0;
        border: 1px solid #c1c1c1;
    }
}

.transparenceDiv {
    opacity: 0;
    z-index: -1;
    position: relative;
}