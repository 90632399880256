@import "../../../style.scss";

.NotFoundContainer {
    h3.title{
        font-weight: 600;
        font-size: ptr(24);
        color: #000;
    }
    .contentBorder{
        max-width: ptr(700);
        margin: 0 auto;
    }
    .title{
        text-align: center;
    }
    .image{
        text-align: center;
        img{
            width: ptr(230);
            height: auto;
        }
    }
    .btn{
        font-weight: 600;
        width: 100%;
        margin-top: ptr(20);
    }
}