/* @font-face {
    font-family: "icomoon";
    src: url("../../fonts/icomoon.eot?9xptwu");
    src: url("../../fonts/icomoon.eot?9xptwu#iefix") format("embedded-opentype"), url("../../fonts/icomoon.ttf?9xptwu") format("truetype"), url("../../fonts/icomoon.woff?9xptwu") format("woff"), url("../../fonts/icomoon.svg?9xptwu#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
} */

@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?a02auq');
  src:  url('../../fonts/icomoon.eot?a02auq#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?a02auq') format('truetype'),
    url('../../fonts/icomoon.woff?a02auq') format('woff'),
    url('../../fonts/icomoon.svg?a02auq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"],
.slidestory .react-multiple-carousel__arrow--left,
.slidestory .react-multiple-carousel__arrow--left:before,
.slidestory .react-multiple-carousel__arrow--right,
.slidestory .react-multiple-carousel__arrow--right:before,
.hwoyousee .react-multiple-carousel__arrow--left,
.hwoyousee .react-multiple-carousel__arrow--left:before,
.hwoyousee .react-multiple-carousel__arrow--right,
.hwoyousee .react-multiple-carousel__arrow--right:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-over:before {
    content: "\e913";
}

.icon-Intellectual:before {
    content: "\e915";
}

.icon-Sales:before {
    content: "\e916";
}

.icon-Strategic:before {
    content: "\e917";
}

.icon-back:before {
    content: "\e910";
}

.icon-bootstraping:before {
    content: "\e900";
}

.icon-community:before {
    content: "\e901";
}

.icon-copyright:before {
    content: "\e902";
}

.icon-ecosystems:before {
    content: "\e903";
}

.icon-funding:before {
    content: "\e904";
}

.icon-go_to_market:before {
    content: "\e905";
}

.icon-investor:before {
    content: "\e906";
}

.icon-ip_asset:before {
    content: "\e907";
}

.icon-legal:before {
    content: "\e908";
}

.icon-oem:before {
    content: "\e909";
}

.icon-product_management:before {
    content: "\e90a";
}

.icon-reseller:before {
    content: "\e90b";
}

.icon-sales:before {
    content: "\e90c";
}

.icon-software_security:before {
    content: "\e90d";
}

.icon-starting:before {
    content: "\e90e";
}

.icon-technology:before {
    content: "\e90f";
}

.icon-search:before {
    content: "\e911";
}

.icon-signup:before {
    content: "\e912";
}

.icon-add-software:before {
    content: "\e914";
}

.icon-talk:before {
    content: "\e918";
}

.icon-nextbtn:before,
.slidestory .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:before,
.hwoyousee .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:before {
    content: "\e919";
}

.icon-prevbtn:before,
.slidestory .react-multiple-carousel__arrow--right:before,
.hwoyousee .react-multiple-carousel__arrow--right:before {
    content: "\e91a";
}

.icon-linkedin:before {
    content: "\e91b";
}

.icon-tweet:before {
    content: "\e91c";
}

.icon-home:before {
    content: "\e91d";
}

.icon-message:before {
    content: "\e91e";
}

.icon-notification:before {
    content: "\e91f";
}

.icon-aide:before {
    content: "\e920";
}

.icon-close:before {
    content: "\e921";
}

.icon-done:before {
    content: "\e922";
}

.icon-inprogress:before {
    content: "\e923";
}

.icon-check:before {
    content: "\e924";
}

.icon-createCompany:before {
    content: "\e925";
}

.icon-locked:before {
    content: "\e926";
}

.icon-edit:before {
    content: "\e927";
}

.icon-settings:before {
    content: "\e928";
}

.icon-userIcon:before {
    content: "\e929";
}

.icon-close-pop:before {
    content: "\e92a";
}

.icon-edit-full:before {
    content: "\e92b";
}

.icon-linkedin-square:before {
    content: "\e92c";
}

.icon-conversation:before {
    content: "\e92d";
}

.icon-discussion:before {
    content: "\e92e";
}

.icon-like:before {
    content: "\e92f";
}

.icon-place:before {
    content: "\e931";
}

.icon-clock:before {
    content: "\e930";
}

.icon-file-pdf:before {
    content: "\e93c";
}

.icon-file-word:before {
    content: "\e93d";
}

.icon-building:before {
    content: "\e93e";
}

.icon-bridge-funding:before {
    content: "\e932";
}

.icon-calendar:before {
    content: "\e933";
}

.icon-growth-money:before {
    content: "\e934";
}

.icon-IP-sales:before {
    content: "\e935";
}

.icon-mergers-acquisitions:before {
    content: "\e936";
}

.icon-mini-key:before {
    content: "\e937";
}

.icon-seed-money:before {
    content: "\e938";
}

.icon-series-money:before {
    content: "\e939";
}

.icon-stock-buybacks:before {
    content: "\e93a";
}

.icon-strategic-alliance:before {
    content: "\e93b";
}

.icon-empty-file:before {
    content: "\e93f";
}

.icon-sparow:before {
    content: "\e940";
}

.icon-funding-rounds:before {
    content: "\e941";
}

.icon-business-model:before {
    content: "\e942";
}

.icon-discussion-semi:before {
    content: "\e943";
}

.icon-like-full:before {
    content: "\e944";
}

.icon-refresh:before {
    content: "\e945";
}

.icon-tri:before {
    content: "\e946";
}

.icon-icon-li-open:before {
    content: "\e947";
}

.icon-icon-li:before {
    content: "\e948";
}

.icon-article:before {
    content: "\e949";
}

.icon-press:before {
    content: "\e94a";
}

.icon-partner:before {
    content: "\e94b";
}

.icon-program:before {
    content: "\e94c";
}

.icon-check-article:before {
    content: "\e94d";
}

.icon-forum:before {
    content: "\e94e";
}

.icon-program-menu:before {
    content: "\e94f";
}

.icon-search-oem:before {
    content: "\e950";
}

.icon-folder:before {
    content: "\e951";
}

.icon-locked-open:before {
    content: "\e954";
}

.icon-star-off:before {
    content: "\e952";
}

.icon-star-on:before {
    content: "\e953";
}

.icon-phone:before {
    content: "\e955";
}

.icon-warning:before {
    content: "\ea07";
}

.icon-emoticon:before {
    content: "\e956";
}

.icon-media:before {
    content: "\e957";
}

.icon-pj:before {
    content: "\e958";
}

.icon-return:before {
    content: "\e959";
}

.icon-up:before {
    content: "\e95a";
}

.icon-channel:before {
    content: "\e95b";
}

.icon-corporate:before {
    content: "\e95c";
}

.icon-fav-full:before {
    content: "\e95d";
}

.icon-fav-line:before {
    content: "\e95e";
}

.icon-recruitment:before {
    content: "\e95f";
}

.icon-technology-partner:before {
    content: "\e965";
}

.icon-msg-up-list:before {
    content: "\e960";
}

.icon-msg-down:before {
  content: "\e983";
  color: #4f4f4f;
}

.icon-msg-up:before {
    content: "\e961";
    color: #4f4f4f;
}

.icon-msg-select-category:before {
    content: "\e962";
    color: #4f4f4f;
}

.icon-msg-message:before {
    content: "\e963";
    color: #4f4f4f;
}

.icon-msg-find-programs:before {
    content: "\e964";
    color: #4f4f4f;
}

.icon-msg-find-investors:before {
    content: "\e966";
    color: #4f4f4f;
}

.icon-msg-community:before {
    content: "\e967";
    color: #4f4f4f;
}

.icon-msg-classified-ads:before {
    content: "\e968";
    color: #4f4f4f;
}

.icon-physical-event:before {
    content: "\e969";
}

.icon-virtual-event:before {
    content: "\e96a";
}

.icon-arrow-long-down:before {
    content: "\e96b";
}

.icon-tri-reverse:before {
    content: "\e96c";
}

.icon-info:before {
    content: "\e96d";
}

.icon-bubble:before {
    content: "\e96e";
}

.icon-user:before {
    content: "\e96f";
}

.icon-play2:before {
    content: "\ea15";
}

.icon-user-plus:before {
    content: "\e973";
}

.icon-radio-unchecked:before {
    content: "\ea56";
}

.icon-spinner6:before {
    content: "\e97f";
}

.icon-image:before {
    content: "\e96f";
}

.icon-file-picture:before {
    content: "\e970";
}

.icon-pushpin:before {
    content: "\e971";
}

.icon-help-circle:before {
    content: "\e974";
}

.icon-exclamation-circle:before {
    content: "\e972";
}

.icon-investor-2:before {
    content: "\e975";
}

.icon-partner-program:before {
    content: "\e976";
}

.icon-community-2:before {
    content: "\e977";
}

.icon-bin:before {
    content: "\e9ac";
}

.icon-tchat:before {
    content: "\e979";
}

.icon-email:before {
    content: "\e978";
}

.icon-Alternative-financing:before {
    content: "\e97e";
}

.icon-incognito:before {
    content: "\e980";
}

.icon-featured_article:before {
    content: "\e981";
}

.icon-premium:before {
    content: "\e982";
}