@import "../../../style.scss";
.allNotif {
    width: 45rem;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    flex-direction: column;
    .ico {
        font-size: ptr(20);
        width: ptr(30);
    }
    .one {
        min-height: 60px;
        padding: 10px 0;
        a {
            color: inherit;
            div {
                display: flex;
                align-items: center;
            }
        }
        .user {
            font-weight: bold;
            margin-right: ptr(5);
        }
    }
    /*.yourComment:before {
        //content: open-quote;
    }
    .yourComment:after {
        content: close-quote;
    }*/
    .date {
        font-size: ptr(14);
        color: $orange;
        font-weight: 600;
        padding-left: ptr(30);
    }
    .all_notif {
        padding: 30px;
        transition: all 0.5s;
    }
    .unreadAll {
        font-weight: bold;
        //background-color: #e7e7e7;
        &::after {
            content: "•";
            position: absolute;
            right: ptr(15);
            margin-top: ptr(20);
            color: $orange;
            font-size: ptr(40);
        }
    }
}