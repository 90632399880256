@import "../../../style.scss";
.forDesactivate {
    h2.title {
        font-size: ptr(18);
        font-weight: bold;
        margin: 0;
        padding: ptr(20) 0 ptr(20) ptr(30);
        text-align: left;
    }
    .headerpermission {
        background: #4f4f4f;
        color: white;
        border-bottom: 2px solid $orange;
        width: 100%;
        .close {
            position: absolute;
            border-radius: 100%;
            width: 1.25rem;
            height: 1.25rem;
            border: 1px solid white;
            padding: 1px 1.5px;
            margin: 0;
            top: 1.3rem;
            right: 0.625rem;
            opacity: 1;
            color: white;
            font-weight: 100 !important;
            font-size: 15px;
            span {
                display: block;
            }
        }
    }
    .icon-info {
        color: #FFCC00;
    }
    .modal-dialog {
        width: 25rem !important;
        max-width: 90%;
        position: relative;
    }
    .modal-header {
        padding: 0;
        margin: 0;
        position: relative;
    }
    .modal-body {
        p {
            text-align: center;
        }
    }
}