@import "../../../style.scss";
.one-isv,
.one-program {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 1rem;
  .photo {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: #efedee;
    margin-right: 0.5rem;
    position: relative;
    overflow: hidden;
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }
  .isv-info,
  .program-info {
    span {
      display: block;
      font-size: 14px;
    }
    .name {
      color: #1897c8;
      font-weight: bold;
    }
    .company {
      font-weight: 500;
    }
  }
}

.filesMiniature {
  position: initial;
  top: -50px;
  left: 0;
  border-radius: 5px;
  width: 100%;
  @extend .d-flex;
  .play {
    .icon-play2 {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: ptr(25);
      border-radius: 50%;
      position: absolute;
      left: ptr(13);
      bottom: ptr(12);
    }
  }
  li {
    margin-right: ptr(25);
  }
  .cl {
    color: inherit;
  }
  .ic {
    position: absolute;
    background: #fff;
    border-radius: 50%;
    padding: 5px;
    top: ptr(-5);
    right: ptr(-10);
  }
  .fileImages {
    position: relative;
    img {
      border-radius: 7px;
      object-fit: cover;
      height: ptr(50);
      top: ptr(-35);
    }
  }
  .fileOthers {
    position: relative;
    @extend .d-flex;
    align-items: center;
    background: #f5f6fa;
    border-radius: 5px;
    padding: ptr(5) ptr(15);
    flex: none;
    .icon-empty-file {
      font-size: ptr(20);
      margin-right: 5px;
    }
    .nameFile {
      font-weight: 600;
    }
  }
}

.discussion-tchat {
  background: #efedee;
  height: 100vh;
  overflow: hidden;
  .d-none {
    display: none !important;
  }
  .react-select__option:hover {
    background: #f5f6fa !important;
    cursor: pointer;
  }
    #dropdown-basic {
      border-color: transparent;
      &:focus {
        background: none !important;
        color: #000;
        box-shadow: none;
        border-color: transparent
      }
    }
}

.head-tchat {
  background: #efedee;
  height: 47px;
  position: relative;
  @extend .d-flex;
  .container {
    max-width: 100%;
    .row {
      justify-content: flex-start;
      align-items: flex-start;
    }
    ul {
      @extend .d-flex;
      position: relative;
      align-items: center;
      height: 47px;
      flex-direction: column;
      li {
        /* min-width: 197px;*/
        text-align: center;
        height: 100%;
        @extend .d-flex;
        align-items: center;
        justify-content: center;
        a {
          color: $gris-claire;
          font-weight: bold;
        }
      }
    }
    .left-tab {
      width: ptr(400);
      ul {
        position: relative;
        width: 100%;
        li.nav-item {
          width: 50%;
          position: relative;
          &.selectCat {
            display: block !important;
            margin: 5px auto;
            min-width: ptr(215);
            position: relative;
            left: ptr(-30);
            .react-select__control,
            &:hover,
            &:focus {
              background: none !important;
              border: none !important;
              box-shadow: none !important;
            }
            .react-select__indicator-separator {
              display: none;
            }
            .react-select__placeholder,
            .react-select__indicator {
              color: #212529;
            }
            .react-select__menu-list {
              text-align: left;
            }
            /*.react-select__placeholder {
                            text-align: left;
                            background: url(../../../resources/images/selectCategory.svg) center left 10px no-repeat;
                            background-size: ptr(15) auto;
                            padding-left: ptr(32);
                            width: ptr(150);
                        }*/
            .react-select__menu {
              .react-select__option--is-selected {
                background: #f5f6fa !important;
                color: #000 !important;
              }
              .react-select__option {
                background-color: transparent;
              }
            }
          }
          a {
            /* width: 100%;*/
            transition: all 100ms;
            &.active/*,
            &:hover */ {
              text-decoration: underline;
            }
          }
        }
      }
      .react-select__control--menu-is-open {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .right-tab {
      margin-left: ptr(-15);
      ul {
        position: relative;
        width: 30%;
        align-items: flex-start;
        li.nav-item {
          position: relative;
          width: 100%;
          min-width: ptr(170);
          a {
            width: 100%;
            position: relative;
            border: 0px !important;
            padding: 10px 0 0 0;
            height: ptr(46);
            font-weight: 600;
            @extend .d-flex, .align-items-stretch, .justify-content-center;
            span {
              border-bottom: 4px solid transparent;
            }
            &.active,
            &:hover {
              border: none !important;
              span {
                border-color: #495057;
              }
            }
            .notification {
              display: flex;
              width: 14px;
              height: 14px;
              align-items: center;
              justify-content: center;
              background-color: $bleu;
              color: white;
              border-radius: 14px;
              position: absolute;
              right: 15px;
              bottom: 20px;
              font-size: 10px;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}

.tchat-title,
.search-box {
  height: 86px;
  @extend .d-flex;
  padding: 0 2rem;
  flex-direction: column;
  justify-content: center;
  position: relative;
  span {
    color: #4f4f4f !important;
  }
  input {
    border: none;
  }
  span.icon-return {
    position: absolute;
    right: 25px;
    transition: all 500ms;
    &.active {
      transform: rotate(180deg);
    }
  }
}

.tchat-title-2 {
  flex-direction: row;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 16px;
  font-size: 14px;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  position: relative;
  padding-bottom: ptr(16);
  border-bottom: 1px solid #bbb;
  .titleRepH {
    width: calc(100% + 32px);
    background: #f5f6fa;
    font-weight: 600;
    color: $gris;
    margin: ptr(-16) ptr(-16) ptr(16) ptr(-16);
    padding: ptr(16);
    padding-left: ptr(26);
    + .d-flex {
      max-width: 85%;
    }
  }
  .icon-settings {
    font-size: 20px;
    line-height: 0;
  }
  .photo-title {
    width: 60px;
    height: 60px;
    min-width: 60px;
    background: #ffffff;
    margin-right: 1rem;
    border: 1px solid #707070;
    position: relative;
    overflow: hidden;
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
    }
  }
  .actorAndParam {
    width: 100%;
    padding: ptr(20) 0;
    flex-direction: column;
    padding-top: 0;
    .infoActor {
      width: 80%;
      margin: ptr(10);
      .info-title {
        width: calc(100% - 76px);
        .company-title,
        .company-type {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    ~ .parameter {
      position: absolute;
      right: 15px;
      top: ptr(90);
    }
  }

  .info-title {
    min-width: 86%;
  }
  .company-title a{
    max-width: 80%;
    display: inline-block;
  }
}

.body-tchat-container {
  height: calc(100vh - 125px);
  /*overflow-y: auto;*/
  position: relative;
  .pm-col {
    .bodyTchat-col {
      width: 38.5%;
      margin: 0 ptr(10);
      .d-flex {
        &.mr-5 {
          margin-right: 0 !important;
        }
      }
      .lef {
        margin-left: ptr(35);
      }
    }
    .info-col {
      width: ptr(400);
      display: block;
      opacity: 1;
      height: calc(100vh - 94px) !important;
      overflow: auto;
      padding-right: ptr(20);
    }
    .taping-box {
      width: 98%;
      margin: 0 auto;
      margin-bottom: 3rem;
    }
    .action-zone {
      width: 97% !important;
    }
    .filesMiniature {
      width: 100%;
    }
  }
  box-sizing: border-box;
  .row {
    justify-content: space-between;
    align-items: flex-start;
  }
  .user-col,
  .bodyTchat-col {
    background: white;
  }
  .tchat-title,
  .search-box {
    height: 86px;
    @extend .d-flex;
    padding: 0 2rem;
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }
  .tchat-title {
    border-bottom: 1px solid #b3b3b3;
  }
  .user-col {
    width: ptr(400);
    font-size: ptr(14);
    height: calc(100vh - 94px) !important;
    overflow: auto;
    padding-left: ptr(15);
    position: relative;
    min-width: 0;
    max-width: 100%;
    flex-direction: column;
    .one-user {
      height: 80px;
      padding: 0 1rem;
      @extend .d-flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #f1f1f3;
      position: relative;
      &:hover:not(.disabled),
      &.active {
        background-color: #f5f6fa;
        cursor: pointer;
        &:before {
          content: "";
          width: 3px;
          height: 100%;
          background-color: $orange;
          position: absolute;
          left: 0;
        }
      }
      &.unread {
        .message-user {
          font-weight: bold;
        }
        .message-preview:after {
          content: "•";
          position: absolute;
          right: ptr(17);
          top: 18px;
          color: $orange;
          font-size: ptr(40);
        }
      }
      &.disabled {
        cursor: default;
        opacity: 0.5;
      }
      .photo-user {
        width: 40px;
        height: 40px;
        overflow: hidden;
        position: relative;
        border: 1px solid #707070;
        &.round {
          border-radius: 40px;
        }
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
        }
      }
      .message-user {
        width: 260px;
        height: 40px;
        overflow: hidden;
        padding-left: ptr(7);
        .name-user {
          display: block;
          font-weight: bold;
        }
      }
      .date-message {
        height: 40px;
        min-width: 50px;
      }
    }
  }
  .info-col {
    width: 0;
    background: white;
    /*transition: all 200ms;*/
    opacity: 0;
    display: none;
    .search-box {
      height: 26px;
      padding: 0 0.5rem;
      margin-top: 2rem;
      flex-direction: column;
      justify-content: center;
      padding: 0.5rem 1rem;
    }
    .program-representativ {
      /*div:first-child {
            display: none !important
            }*/
      .addMember {
        a {
          @extend .d-flex;
          align-items: center;
        }
        .icon-user-plus {
          margin-right: ptr(10);
        }
      }
    }
    .isv-member,
    .program-representativ,
    .shared-files {
      h3 {
        font-size: 0.8rem;
        text-transform: uppercase;
        padding: 1rem;
        font-weight: bold;
      }
      span.ico {
        padding: 1rem;
      }
      .shared {
        flex-direction: column;
        .fileOthers,
        .fileImages {
          width: 100%;
          background: transparent;
          padding: 3px 0;
        }
      }
    }
  }
  .bodyTchat-col {
    @extend .d-flex;
    font-size: 14px;
    height: calc(100vh - 94px);
    align-items: stretch;
    flex-direction: column;
    transition: none;
    box-sizing: border-box;
    flex-basis: 0px;
    position: relative;
    min-width: 0;
    flex-shrink: 1;
    max-width: 100%;
    flex-grow: 1;
    margin-left: ptr(10);
    .tchat-title {
      @extend .d-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      line-height: 16px;
      padding-top: ptr(15);
      padding-bottom: ptr(15);
      padding-left: ptr(26);
      .photo-title {
        width: 60px;
        height: 60px;
        position: relative;
        overflow: hidden;
        background: #ffffff;
        margin-right: 1rem;
        border: 1px solid #707070;
        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
        }
      }
      .company-location {
        font-weight: 600;
      }
    }
    .tchat-message {
      padding: 0 2rem;
      height: calc(100% - 210px);
      overflow-y: auto;
      .tchat-date {
        height: 80px;
        text-align: center;
        @extend .d-flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
      }
      .one-message {
        @extend .d-flex;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 2rem;
        .user-photo-profil {
          width: 40px;
          height: 40px;
          position: relative;
          overflow: auto;
          border-radius: 40px;
          background: #efedee;
          img {
            width: 40px;
            height: 40px;
            object-fit: cover;
          }
        }
        .message-content {
          width: calc(100% - 60px);
          .profil {
            @extend .d-flex;
            margin-bottom: 0.5rem;
            align-items: center;
            .name-user {
              font-weight: 600;
              padding-right: 0.8rem;
              position: relative;
              &:after {
                content: "•";
                position: absolute;
                right: 0;
              }
            }
            .time {
              color: #b3b3b3;
              font-size: 12px;
              padding-left: 0.5rem;
            }
          }
        }
      }
      .scrollToBottom {
        height: 1px;
      }
    }
    .textForMessage {
      border: none;
      height: ptr(80);
      background: transparent;
      width: 95%;
      resize: none;
      font-size: ptr(14);
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    .false {
      &.taping-box {
        transition: all 500ms;
      }
    }
    .messageOpen {
      &.taping-box {
        height: 50vh;
        transition: all 500ms;
        .textForMessage {
          height: 29vh;
        }
      }
      .icon-msg-up {
        transform: rotate(180deg);
      }
    }
    .newTchat {
      text-align: center;
      margin: ptr(20) auto;
      .user-photo-profil {
        border: 1px solid #efedee;
        width: ptr(60);
        height: ptr(60);
        border-radius: 50%;
        margin: auto;
        img {
          border: 1px solid #efedee;
          object-fit: cover;
          width: ptr(60);
          height: ptr(60);
          border-radius: 50%;
        }
      }
      .name-user {
        font-size: ptr(16);
        font-weight: 600;
        margin: ptr(5) auto;
      }
      .info-user {
        color: $gris-claire;
      }
      .date {
        span {
          color: gray;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
    }
  }
  .taping-box {
    /*margin-bottom: 0 !important;
        bottom: 0;
        width: 97.5%;*/
    padding: 1rem;
    margin: 0 0.5rem;
    border-radius: 4px;
    background: #efedee;
    height: ptr(182);
    position: relative;
    margin-bottom: 3rem;
    margin-right: 1.5rem;
    .icon-msg-up {
      position: absolute;
      right: ptr(15);
      top: ptr(28);
    }
    input[type="text"] {
      font-size: ptr(14);
      background: #efedee;
      border: 1px solid #efedee !important;
      padding-left: 0 !important;
      padding-right: 0;
      &:focus,
      &:active {
        border-style: none;
        box-shadow: none !important;
      }
    }
    .action-zone {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      position: absolute;
      width: 97.5%;
      bottom: ptr(16);
      min-width: 99%;
      .icons-action {
        width: 200px;
        a {
          color: inherit;
        }
        .ico-reaction {
          margin-right: 0.5rem;
        }
      }
      .send {
        .send-button {
          border: none;
          background-color: $orange;
          text-transform: uppercase;
          color: white;
          border-radius: 20px;
          padding: 0 20px;
          transition: all 500ms;
          &:hover {
            background-color: white;
            color: $orange;
          }
        }
      }
    }
  }
  .shared {
    @extend .d-flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    li {
      text-align: left;
      margin-bottom: ptr(10);
      position: relative;
      img {
        width: 120px;
        height: 80px;
        object-fit: cover;
        border-radius: 5px;
        cursor: pointer;
      }
    }
    .fileOthers,
    .fileImages {
      @extend .d-flex;
      align-items: center;
      line-height: 1;
      /*background: #f5f6fa;*/
      padding: ptr(10) 0 0;
      border-radius: 5px;
      min-width: ptr(120);
      width: ptr(250);
      .icon-empty-file,
      .icon-file-picture {
        margin-right: ptr(5);
        font-weight: 600;
      }
      .nameFile {
        font-weight: 600;
        position: absolute;
        left: ptr(30);
      }
      a {
        color: inherit;
      }
      .wrap {
        margin: 5px;
      }
    }
    .play {
      .icon-play2 {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: ptr(35);
        border-radius: 50%;
        position: absolute;
        left: ptr(40);
        bottom: ptr(20);
      }
    }
  }
}

.fileSend {
  .shared {
    justify-content: left;
    width: ptr(270);
    position: relative;
    li {
      margin-right: ptr(10);
    }
    .fileOthers {
      min-width: ptr(80);
      width: ptr(80);
      height: ptr(80);
    }
    .fileImages {
      img {
        width: ptr(80);
      }
    }
    .icon-play2 {
      bottom: ptr(21) !important;
      left: ptr(23) !important;
    }
  }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background: none !important;
  border-color: #dee2e6 #dee2e6 #fff;
  border: none !important;
  &:hover {
    color: $orange;
  }
}

.open-info {
  cursor: pointer;
}

.nav-item.messages,
.cat {
  position: relative;
  @extend .d-flex;
  align-items: center;
  .ico-container {
    width: 30px;
    height: 30px;
    min-width: 30px;
    border-radius: 30px;
    background-color: #d8dadf;
    @extend .d-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .non-lu {
      position: absolute;
      top: 0;
      right: -5px;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      background-color: $orange;
      border: 1px solid white;
      color: white;
      font-size: 11px;
      @extend .d-flex;
      align-items: center;
      justify-content: center;
    }
    span {
      &:before {
        font-size: 12px;
      }
    }
  }
  &.placeHolder {
    .ico-container {
      margin: 0;
      height: ptr(29.31);
      .non-lu {
        font-size: ptr(10);
      }
    }
    .txt {
      margin-left: ptr(15);
    }
  }
}

.cat {
  .ico-container {
    margin-right: ptr(10);
  }
}

.title-info {
  padding: 0.5rem 1rem;
  background: #f5f6fa;
  h3 {
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.dropdown {
  border: none !important;
  outline: none !important;
  .ico-container {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #d8dadf;
    @extend .d-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 0.5rem;
  }
  button#dropdown-basic {
    display: flex;
    align-items: center;
    &:active,
    &:focus {
      border-style: none;
      box-shadow: none !important;
    }
  }
  .ico-container span:before {
    font-size: 12px;
  }
  .dropdown-item {
    display: flex;
  }
}

.search-msg {
  &:active,
  &:focus {
    border-style: outset;
    box-shadow: none !important;
  }
}

/* .icon-msg-up {
    transition: all 500ms;
    cursor: pointer;
    transform: rotate(180deg);
    &.active {
        transform: rotate(0deg);
    }
}
 */

.icon-msg-down,
.icon-msg-up {
  transition: all 500ms;
  cursor: pointer;
  /*  &.active {
        transform: rotate(180deg);
    } */
}
.shared-files ul {
  padding: 15px;
}
.shared-files {
  .shared {
    .fileImages {
      .nameFile {
        text-overflow: ellipsis;
        width: 285px;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
.btn-settings-msgs::after {
  display: none !important;
}

.btn-settings-msgs {
  padding: 0 !important;
}

.icon-msg-select-category::before {
  top: 5px;
}

.category-cont {
  padding-bottom: 2px;
}

.forAddMember {
  .headerAddMember {
    .close {
      top: ptr(20) !important;
    }
  }
  .searchWord {
    border: 1px solid #c4c8cc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 0;
    button {
      top: 0;
    }
    .keyword {
      border: none;
    }
  }
  .listMember {
    min-height: ptr(300);
    height: ptr(300);
    overflow-y: auto;
    padding-top: ptr(30);
    margin-top: ptr(10);
    .orange {
      border-bottom: 1px solid #e7e7e7;
      padding: ptr(10);
    }
    .orange,
    .one-program {
      padding-left: 0;
      margin: 0;
      .photo {
        img {
          border: 1px solid $gris-claire;
          border-radius: 50%;
        }
      }
      .name {
        color: inherit;
        font-weight: 600;
      }
    }
    label {
      width: 100%;
    }
    .custom-control-input:checked ~ .custom-control-label:before,
    .custom-control-input:checked ~ .custom-control-label:after,
    .custom-control-label:before {
      border-radius: 50% !important;
      right: 0;
      left: auto;
      top: ptr(16);
      width: ptr(20);
      height: ptr(20);
    }
    .custom-control:hover {
      background: #e7e7e7;
      transition: all 500ms;
      cursor: pointer;
    }
  }
  .termine {
    display: block;
    margin: auto;
  }
}

.false {
  transition: all 200ms;
  .close {
    display: none;
  }
  .apercu{
    .close {
      display: block !important;
    }
  }
}

.apercu {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  z-index: 3;
  padding: ptr(150);
  transition: all 300ms;
  .close {
    float: none;
    position: absolute;
    right: ptr(150);
    top: ptr(120);
    z-index: 2;
    background: #000;
    text-shadow: none;
    padding: ptr(4);
    opacity: 1;
    span {
      color: #fff;
    }
  }
  .nameFile,
  .icon-empty-file {
    font-weight: 600;
    color: #fff;
  }
}

.swiper {
  width: 100%;
  height: 100%;
  .swiper-button-prev,
  .swiper-button-next {
    color: $orange;
  }
  .swiper-pagination-progressbar-fill {
    background: $orange !important;
  }
}

.swiper-slide {
  @extend .d-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  background: #000;
}

.swiper-slide img {
  display: block;
  width: 500px;
  height: 80%;
  object-fit: cover;
}

.emojiTchat {
  position: absolute;
  bottom: ptr(34);
  .emoji-mart-preview {
    display: none;
  }
  .emoji-mart-anchor-selected {
    color: $orange;
    svg {
      fill: $orange;
    }
    .emoji-mart-anchor-bar {
      background-color: $orange !important;
    }
  }
}

.search-message {
  position: relative;
  input {
    border: 1px solid #e7e7e7;
    border-radius: 20px;
  }
  hr {
    margin: 0;
  }
  .control {
    position: absolute;
    right: ptr(160);
    font-size: ptr(8);
    bottom: ptr(35);
    .down {
      position: absolute;
      transform: rotate(180deg);
      margin: 1px ptr(10);
    }
  }
  .closed {
    position: absolute;
    right: ptr(55);
    a {
      color: #4f4f4f;
    }
    &:hover {
      font-weight: 600;
    }
  }
}

.input-cursor {
  .icon-search-oem {
    &::before {
      visibility: hidden;
    }
  }
  .icon-close {
    position: absolute;
    left: 35px;
    top: 32px;
    font-size: 20px;
    border: none;
    background: none;
  }
}

.message-content {
  .shared {
    .nameFile {
      left: ptr(20) !important;
    }
  }
}

.searchConversation {
  @extend .d-flex;
  align-items: center;
  justify-content: start;
  margin: 60px 15px 30px 15px;
  .icon-search {
    font-weight: bold;
    font-size: ptr(26);
    margin-right: 5px;
  }
}

.titleRight {
  background: #f5f6fa;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 600;
}

/*.css-g1d714-ValueContainer{

  overflow: visible !important;
}
*/

.react-select__value-container {
  overflow: visible !important;
}

#bodytchat .send-more {
  position: relative;
  top: -4px;
  font-weight: bold;
  letter-spacing: 1.5px;
  font-size: 21px;
}

span.icon-return.open-info {
  position: absolute;
  right: 1rem;
  top: 16px;
  transform: rotate(180deg);
}

span.icon-return.open-info.active {
  transform: rotate(360deg);
}

/*Add member notification */

.message-content-parent-invitation {
  text-align: center;
  color: #999;
  margin-bottom: 3rem;
  .message-content-invitation {
    margin-top: 0.5rem;
  }
  .one-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
  .name-user,
  .user-photo-profil {
    display: none;
  }
  .time {
    color: #ccc;
    font-size: 13px;
  }

  .newTchat {
    margin: 0 auto !important;
  }
}

/* .shared-files span.icon-msg-up.ico {

    transform: rotate(0deg);
}

.shared-files span.icon-msg-up.ico.active {

    transform: rotate(180deg);
} */

.taping-box.withApercu .icon-msg-up {
  transform: rotate(0deg);
}
.messageOpen.taping-box.withApercu .icon-msg-up {
  transform: rotate(180deg);
}

.addMember.container {
  border-bottom: 1px solid #bbb;
  padding-bottom: ptr(24);
}

/* .program-representativ .icon-msg-up.ico.active
{
    transform: rotate(0deg)!important
}

.program-representativ .icon-msg-up.ico.active{transform: rotate(360deg)!important;}

.representative .icon-msg-up.ico{
    transform: rotate(0deg) !important
}

.representative .icon-msg-up.ico.active{
        transform: rotate(180deg) !important
}
 */

.gear {
  button {
    background: transparent;
    color: #000;
    border: none;
    &:hover {
      background: transparent;
      color: #000;
    }
  }
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

.discussion-tchat {
  .show>.btn-primary.dropdown-toggle {
    background: none;
    color: #222
  }

  .dropdown-menu.show{
    top: 11px !important;
  }
}

/*last chat hidden */
#community-tab {
  padding-bottom: 2rem;
}