* {
    box-sizing: border-box;
  }
  
  .selector {
    display:flex;
    flex-direction: column-reverse;
    width: 50px;
    position: relative;
  }
  
  .input-number {
    padding: 0 12px;
    vertical-align: top;
    text-align: center;
    outline: none;
  }
  
  .input-number,
  .input-number-decrement,
  .input-number-increment {
    border: 1px solid #ccc;
    height: 40px;
    user-select: none;
  }
  
  .input-number-decrement,
  .input-number-increment {
    display: inline-block;
    line-height: 20px;
    background: #f1f1f1;
    color: #444;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  .input-number-decrement:not(.disabled):active,
  .input-number-increment:not(.disabled):active {
    background: #ddd;
  }
  
  .input-number-decrement {
    border-top: none;
    height: 20px;
      border-radius: 0 0 4px 4px ;
  }

  .input-number-decrement.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .input-number-increment {
    height: 20px;
    border-bottom:none;
    border-radius: 4px 4px 0 0 ;
  }

  .input-number-increment.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }