@import "../../../../style.scss";
.breadcrumbPerso {
    @extend .d-flex;
    width: 100%;
    padding: ptr(20) 0;
    li {
        padding: 0 ptr(10) 0 0;
        color: $bleu;
        font-weight: 600;
        a {
            font-weight: normal;
            &:hover {
                text-decoration: underline;
            }
        }
        &:after {
            content: "/";
            display: inline-block;
            margin-left: ptr(10);
        }
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.searchTopic {
    margin: 0;
    padding: ptr(20) 0;
    form {
        @extend .d-flex;
        border: 1px solid #c4c8cc;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.16);
        height: ptr(45);
        select {
            border: none;
            appearance: none;
            width: ptr(160);
            padding: 0 ptr(20);
            /* background:url(../../../../resources/images/selectbg.svg) center right 10px no-repeat;
            background-size: 10px auto;*/
            text-overflow: ellipsis;
            white-space: nowrap;
            &:focus-visible {
                outline: none;
            }
        }
        .inputAndbtn {
            position: relative;
            width: calc(100% - 160px);
            @extend .d-flex,
            .align-items-stretch;
            border-left: 1px solid #c4c8cc;
            button {
                width: ptr(45);
                height: ptr(45);
                border: 0;
                color: $orange;
                background-color: transparent;
                font-size: ptr(30);
            }
            input[type="text"] {
                width: calc(100% - 45px);
                border: 0;
            }
        }
    }
}

.rightCommunityForum.allWidth {
    width: 100%;
    padding: 0;
    margin: ptr(30) 0;
    .block {
        margin: 0;
        padding: ptr(30) 0;
        h2.title {
            margin-bottom: ptr(25);
        }
    }
    .listBlockright {
        li {
            .itemBlock {
                width: ptr(230);
                text-align: center;
                &:first-child {
                    width: calc(100% - 460px);
                    text-align: left;
                }
                &.otherViewTopic {
                    flex-direction: row !important;
                    justify-content: start !important;
                    h3 {
                        width: 50%;
                    }
                    .listOfTopic {
                        width: 50%;
                    }
                    .topic {
                        background-color: #e8e8e8;
                        color: #444444;
                        cursor: pointer;
                    }
                }
            }
        }
        .centerContribution {
            font-weight: bold;
        }
        &.parent {
            li:last-child {
                border-bottom: 1px solid #969696;
            }
        }
    }
}

.leftCommunityForum.allWidth {
    width: 100%;
    @extend .d-flex,
    .align-items-stretch;
    margin: 0 0 ptr(30);
    background-color: transparent;
    padding: 0;
    .block {
        width: 32%;
        padding: ptr(20) ptr(16);
        background-color: #eeeeee;
        border: none;
        margin: 0;
        &:nth-child(2n + 2) {
            margin: 0 1.95%;
        }
        h2.title {
            padding-top: 0;
        }
        li {
            min-height: ptr(84);
            &.linkForLi {
                min-height: auto;
            }
        }
        h3.title {
            font-size: ptr(14);
        }
    }
}

@media (max-width: 1024px) {
    .rightCommunityForum.allWidth {
        li {
            .itemBlock.otherViewTopic {
                .listOfTopic {
                    display: none !important;
                }
                h3.title {
                    width: 100%;
                }
            }
        }
    }
    .leftCommunityForum.allWidth {
        flex-direction: column !important;
        .block {
            width: 100%;
            margin: 0 0 ptr(20) 0 !important;
        }
    }
}

@media (max-width: 600px) {
    .rightCommunityForum.allWidth .listBlockright li .itemBlock {
        justify-content: start !important;
        text-align: left;
    }
    .searchTopic {
        form {
            flex-direction: column;
            border: none;
            box-shadow: none;
            height: auto;
            select {
                border: 1px solid #c4c8cc;
                height: ptr(45);
                width: 100%;
                margin: 0 0 ptr(16) 0;
                border-radius: 5px;
            }
            .inputAndbtn {
                width: 100%;
                height: ptr(45);
                border: 1px solid #c4c8cc;
                border-radius: 5px;
            }
        }
    }
}