$gris: #4f4f4f;
$orange: #ff6600;
$bleu: #1897c8;
$gris-disabled: #eeeeee;
$gris-claire: #7f7f7f;
@function ptr($value) {
  $result: ($value / 16) * 1rem;
  @return $result;
}

@mixin display-desktop-only($display) {
  @extend .d-xl-#{$display};
  @extend .d-lg-none;
  @extend .d-md-none;
  @extend .d-sm-none;
  @extend .d-none;
}
@mixin display-tablet-mobile($display) {
  @extend .d-xl-none;
  @extend .d-lg-#{$display};
  @extend .d-md-#{$display};
  @extend .d-sm-#{$display};
  @extend .d-#{$display};
}
