@import "../../../../style.scss";
.rightCommunityForum {
    &.oneCateg {
        display: flex;
        align-items: flex-start;
        .labelLeft {
            width: ptr(300);
            background-color: #eeeeee;
            padding: ptr(16);
            h2.title {
                border-color: #c4c4c4;
                color: #444444;
            }
            .block {
                padding: 0;
                border-top: none;
            }
            &.activityProfil {
                .block {
                    border-top: 1px solid #c4c4c4;
                }
            }
        }
        .containerCategory {
            width: calc(100% - 300px);
            padding: 0 0 0 ptr(40);
        }
    }
    .leftCommunityForum {
        @extend .justify-content-between;
        .block,
        .block:nth-child(2n + 2) {
            width: 48%;
            margin: 0;
            h2.title {
                border: none;
                margin: 0;
            }
            h3.title {
                span.article {
                    font-weight: ptr(13);
                    padding-right: ptr(10);
                    &:before {
                        right: ptr(4);
                    }
                }
            }
        }
        @media (max-width: 1024px) {
            flex-direction: row !important;
        }
        @media (max-width: 600px) {
            flex-direction: column !important;
            .block,
            .block:nth-child(2n + 2) {
                width: 100%;
            }
        }
    }
}

.labelList {
    margin: 0 0 ptr(20);
    li {
        @extend .d-flex,
        .justify-content-between,
        .align-items-center;
        height: ptr(38);
        margin: 0 0 ptr(10) 0;
        padding: 0 0 0 ptr(12);
        font-size: ptr(16);
        font-weight: 600;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #dcdada;
        background: #dcdada;
        cursor: pointer;
        color: $gris;
        line-height: 1;
        &.active {
            border: 1px solid $gris;
            background: $gris;
            color: #fff;
            span.nameOfCategory {
                color: #fff;
            }
        }
        svg {
            circle {
                stroke: #4f4f4f;
            }
        }
        span:last-child {
            width: ptr(38);
            height: ptr(38);
            @extend .d-flex,
            .align-items-center,
            .justify-content-center;
            background: #fff;
            color: $gris;
            border-radius: 8px;
            &.nameOfCategory {
                color: $gris;
                max-width: 90%;
                width: auto;
                display: inline-block!important;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                background-color: transparent;
                height: auto;
            }
        }
        &.reset {
            display: block;
            background: transparent;
            border: none;
            height: auto;
            width: 100%;
            padding: ptr(20) 0;
            a.resetFilter {
                @extend .d-flex,
                .justify-content-center,
                .align-items-center;
                font-weight: normal;
                font-size: ptr(14);
                span {
                    background-color: transparent;
                    width: auto;
                    height: auto;
                    margin-right: ptr(10);
                }
            }
        }
        &.nextPrev {
            @extend .litest;
        }
        &.liParent {
            .nextPrev.linkForLi {
                @extend .litest;
            }
        }
    }
    &.collapseUl {
        display: block;
        overflow: visible;
        li {
            @extend .d-block;
            height: auto;
            padding: 0;
            background-color: transparent;
            border: none;
        }
        .parentUl {
            .parentTitle {
                @extend .d-flex,
                .align-items-center;
                width: 80%;
                span {
                    background: none;
                    padding: 0;
                    width: auto;
                    height: auto;
                    margin-left: ptr(5);
                    &.icon-icon-li-open {
                        @extend .d-none;
                    }
                    &.nameOfCategory {
                        color: #dcdada;
                        max-width: 90%;
                        width: auto;
                        display: inline-block!important;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
            &.open {
                .parentTitle {
                    span {
                        &[class*="icon-"] {
                            color: #fff;
                            &.icon-icon-li-open {
                                @extend .d-flex;
                                font-size: ptr(2);
                            }
                            &.icon-icon-li {
                                @extend .d-none;
                            }
                        }
                        &.nameOfCategory {
                            color: #fff;
                        }
                    }
                }
            }
            &.active {
                span[class*="icon-"] {
                    color: #fff !important;
                }
            }
        }
        .parentUl,
        ul.child li {
            @extend .d-flex,
            .justify-content-between,
            .align-items-center;
            height: ptr(38);
            margin: 0 0 ptr(10) 0;
            padding: 0 0 0 ptr(12);
            font-size: ptr(16);
            font-weight: 600;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid #dcdada;
            background: #dcdada;
            cursor: pointer;
            color: $gris;
            width: 100%;
            padding: 0 0 0 ptr(10);
            &.active {
                border: 1px solid $gris;
                background: $gris;
                color: #fff;
            }
        }
        ul {
            padding: 0 0 0 ptr(24);
        }
        .parentUl {
            background: #4f4f4f;
            color: $gris;
            &~ul.child {
                display: none;
            }
            &.open~ul.child {
                display: block;
            }
        }
    }
}

.litest {
    @extend .justify-content-end;
    background-color: transparent !important;
    border: none !important;
    font-size: ptr(14) !important;
    font-weight: normal !important;
    color: #444444;
    height: ptr(20);
    padding-right: ptr(10);
    margin: 0 !important;
    span {
        background-color: transparent;
        padding: 0 ptr(10);
        width: auto;
    }
    a {
        color: #444444;
        &.inactive {
            color: #838f9b;
        }
    }
}

.containerCategory {
    .titleCategory {
        height: ptr(60);
        border-bottom: 2px solid $orange;
        @extend .d-flex,
        .justify-content-between,
        .align-items-center;
        h2 {
            font-size: ptr(20);
            font-weight: 600;
            @extend .d-flex,
            .align-items-center;
            span[class*="icon-"] {
                font-size: ptr(40);
                margin: 0 ptr(5) 0 0;
                color: $orange;
            }
            button {
                margin: 0 0 0 ptr(10);
            }
        }
    }
}

.tri {
    @extend .d-flex,
    .align-items-center;
    width: ptr(325);
}

.triNumber {
    background-color: #ebebeb;
}

.triNumber,
.triOreder {
    border: 1px solid #6d6d6d;
    border-radius: 0;
    width: ptr(50);
    height: ptr(40);
    .tri__input {
        input {
            opacity: 0;
        }
    }
    .tri__control {
        @extend .d-flex,
        .align-items-center;
        background: url(../../../../resources/images/selectgris.png) center right 5px no-repeat;
        background-size: ptr(10) auto;
        border: none;
        background-color: transparent;
        &.tri__control--menu-is-open,
        &.tri__control--menu-is-focused,
        &.tri__control--is-focused {
            border: none !important;
            box-shadow: none !important;
        }
        &.tri__control--menu-is-open {
            background-image: url(../../../../resources/images/selectContract.svg);
            background-position: center right 5px;
            background-size: ptr(10) auto;
        }
    }
    .tri__option--is-selected {
        background-color: #ebebeb !important;
    }
    .tri__value-container {
        width: ptr(50);
        background-color: transparent;
        padding: 0;
    }
    .tri__indicator,
    .tri__indicators {
        display: none;
    }
    .tri__menu {
        margin: 0;
        padding: 0;
        border-radius: 0;
        background: #fff;
        z-index: 2;
    }
    .tri__menu-list {
        border: 1px solid #6d6d6d;
        padding: 0;
        border: 0;
        .tri__option {
            border: 1px solid #6d6d6d;
            &.tri__option--is-focused,
            &.tri__option--is-selected {
                background-color: transparent;
                font-weight: 600;
                color: $gris;
            }
        }
    }
}

.triOreder {
    width: ptr(120);
    margin: 0;
    border-color: transparent;
    &.favoriteSelect {
        .tri__single-value {
            color: #6d6d6d;
            font-weight: 600;
            font-size: ptr(16);
        }
    }
}

.sortedby {
    @extend .d-flex,
    .align-items-center;
    padding: 0 0 0 ptr(20);
    .tri__menu-list {
        border: 1px solid #6d6d6d;
        padding: 0;
        border: 0;
        .tri__option {
            background: url(../../../../resources/images/tri.svg) center left 10px no-repeat;
            background-size: ptr(20) auto;
            padding: ptr(5) ptr(5) ptr(5) ptr(40);
            &.reverse {
                background-image: url(../../../../resources/images/tri-reverse.svg);
            }
        }
    }
    .tri__menu {
        width: ptr(170);
    }
}

.resetFilter {
    margin: 0 auto;
    color: $gris;
    width: 100%;
}

.feedList {
    &.categoryList {
        .infoAbout {
            @extend .d-flex;
            width: 60%;
            .infoFeed {
                width: calc(100% - 60px);
                .titlePost {
                    padding-bottom: ptr(5);
                    font-size: ptr(16);
                    cursor: pointer;
                }
                .postBy {
                    padding-bottom: 0;
                }
            }
        }
        .viewInfo,
        .replyInfo {
            width: 20%;
            @extend .d-flex,
            .flex-column,
            .align-items-center,
            .justify-content-center;
            font-size: ptr(14);
            text-transform: uppercase;
            color: $gris;
            b {
                font-size: ptr(16);
            }
        }
        .subCategory {
            @extend .d-flex,
            .flex-wrap;
            padding: ptr(10) 0;
            span {
                font-size: ptr(13);
                font-weight: 600;
                color: #444444;
                background-color: #e8e8e8;
                border-radius: 10px;
                display: block;
                padding: 0 ptr(5);
                margin: 0 ptr(5) ptr(5) 0;
                cursor: pointer;
            }
        }
        li {
            border-bottom: 1.5px solid #e8e8e8;
            position: relative;
            .rankPub {
                position: absolute;
                left: 0;
                top: 0;
            }
            .solved {
                position: absolute;
                overflow: hidden;
                top: 0;
                right: 0;
                width: ptr(50);
                height: ptr(50);
                .contentSolved {
                    background-color: #838383;
                    width: ptr(50);
                    height: ptr(50);
                    transform: rotate(45deg);
                    position: absolute;
                    color: #fff;
                    top: ptr(-26);
                    right: ptr(-25);
                    span {
                        display: block;
                        margin: ptr(24) 0 0 ptr(14);
                        transform: rotate(-45deg);
                        font-size: ptr(14);
                    }
                }
            }
        }
        &.pressRelease {}
    }
    &.favoriteList {
        .custom-control-label {
            &:before {
                border-radius: 0;
                border: 1px solid $gris;
            }
        }
        .custom-control-input {
            &:checked {
                &~.custom-control-label {
                    &:before {
                        background: #fff;
                        border-color: $gris;
                        color: $gris;
                    }
                    &:after {
                        background-image: url(../../../../resources/images/check.svg);
                    }
                }
            }
        }
    }
}

.labelSelect {
    @extend .d-flex,
    .align-items-center;
    label {
        padding-right: ptr(10);
        margin: 0;
    }
}

span.article {
    color: #ff6600;
    padding: 0 1.25rem 0 0;
    position: relative;
    text-transform: uppercase;
    &:before {
        content: "";
        height: 0.1875rem;
        width: 0.1875rem;
        background: #4f4f4f;
        border-radius: 20px;
        position: absolute;
        top: 50%;
        right: 0.625rem;
    }
}

.pagginationCategory {
    position: relative;
    z-index: 1;
    @extend .d-flex,
    .align-items-center,
    .justify-content-end;
    padding: ptr(20) 0;
    .labelSelect {
        transform: scale(0.75);
    }
}

.pagination {
    @extend .d-flex,
    .align-items-center,
    .justify-content-center;
    li.active {
        background: #ebebeb;
    }
    a {
        @extend .d-flex,
        .align-items-center,
        .justify-content-center;
        border: 0.5px solid #6d6d6d;
        width: ptr(30);
        height: ptr(30);
        font-size: ptr(14);
        color: #6d6d6d;
        &:hover,
        &.active {
            background: #ebebeb;
        }
    }
    span {
        &.space {
            padding: 0 ptr(10);
        }
        &[class*="icon-"] {
            font-size: ptr(12);
        }
    }
}

@media (max-width: 1024px) {}

@media (max-width: 768px) {
    .rightCommunityForum.oneCateg {
        flex-direction: column;
        .labelLeft {
            width: 100%;
        }
        .containerCategory {
            width: 100%;
            padding: 0;
            margin: ptr(30) 0;
        }
    }
}

@media (max-width: 600px) {
    .breadcrumbPerso {
        display: none !important;
    }
    .containerCategory .titleCategory {
        flex-direction: column;
        align-items: flex-start !important;
        height: auto;
        .tri {
            width: 100%;
            padding: ptr(20) 0;
        }
    }
    .feedList.categoryList .infoAbout {
        width: 100%;
    }
    .feedList.categoryList .viewInfo,
    .feedList.categoryList .replyInfo {
        display: none !important;
    }
}

.featArt {
    background: #f2dbce !important;
    .titlePost {
        .article {
            background: #ff6600;
            color: #fff !important;
            padding: 0 5px !important;
            border-radius: 1px;
            &:before {
                display: none;
            }
            &:after {
                content: "";
                height: 0.1875rem;
                width: 0.1875rem;
                background: #4f4f4f;
                border-radius: 20px;
                position: absolute;
                top: 50%;
                right: ptr(-12);
            }
        }
        .title {
            margin-left: ptr(20);
        }
    }
}

/* .labelList.collapseUl .parentUl .parentTitle span.nameOfCategory{

    color: #fff
}
 */

 
.parentUl.grayBackground {
  background: #dcdada !important;
  border: 1px solid #dcdada !important;
  color: transparent !important;

    .nameOfCategory {
        color: $gris !important;
    }
    .icon-icon-li-open::before {
        color: $gris !important;
    }
}