@import "../../../style.scss";

.profilInvestor {
    nav .container .leftMenulink .dropdown.show button.btn:after {
        display: none;
    }
}

.InvestorPage {
    .container {
        width: ptr(1100);
        padding: 0;

        &.cont1 {
            padding: ptr(35) 0 0 0;

            @media (max-width: 1024px) {
                margin-bottom: ptr(50);
            }
        }

        &.cont2 {
            @extend .d-flex,
            .flex-wrap,
            .justify-content-between,
            .align-items-start;

            @media (max-width: 1024px) {
                flex-direction: column-reverse;
            }
        }

        &.cont3 {
            padding: ptr(50) 0;

            h3.title {
                color: #373737;
                font-weight: 600;
                font-size: ptr(28);
                text-align: center;
                padding: 0 0 ptr(20);
            }
        }
    }

    .fixedSecondHeader {
        background-color: #EFEDEE;
        top: 76px;
        box-shadow: none;
        height: auto;

        .menuInvestorTab {
            margin: 0;
        }
    }

    .secondHeader {
        height: auto;
    }
}


/*.invest{
    .fixedSecondHeader {
        top: 0;
    }
}*/

#about::before,
#expertise::before,
#funding::before,
#strategy::before,
#portfolio::before,
#overview::before,
#solutions::before,
#key::before,
#started::before,
#resource::before {
    content: '';
    display: block;
    height: 0;
    margin-top: 0;
    visibility: hidden;
    @media(max-width: 1024px) {
        height: auto;
    }
}

#portfolio {
    &::before {
        height: ptr(90);
    }
    .investorArticle {
        .contentLeftInvestor {
            top: ptr(-60);
        }
    }
}

/*#started {
    &::before {
        height: ptr(190);
    }
    .investorArticle {
        .contentLeftInvestor {
            top: ptr(-160);
        }
    }
}*/
.profilInvestor {
    footer {
        margin-top: 10rem;
    }
}
footer {
    &.connected {
        margin-top: 12rem;
    }
}
#resource {
    min-height: ptr(250);
    &::before {
        height: ptr(100);
    }
}
.editMode {
    #portfolio {
        .investorArticle {
            .contentLeftInvestor {
                top: 0;
            }
        }
    }
    #started {
        &::before {
            height: inherit;
        }
        .investorArticle {
            .contentLeftInvestor {
                top: 0;
            }
        }
    }
}
div.photoMur {
    width: 100%;
    height: ptr(340);
    position: relative;
    /*height: 100%;*/
    background: url(../../../resources/images/backgroundHeader.png) center no-repeat;
    background-size: cover;

    .Image {
        width: 100%;
        height: ptr(340);
        object-fit: cover;
    }

    .profilInvestorImg {
        position: absolute;
        width: ptr(138);
        height: ptr(138);
        border: 1px solid #C1C1C1;
        bottom: ptr(-69);
        right: ptr(65);
        background-color: #fff;
        @extend .d-flex,
        .align-items-center,
        .justify-content-center;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .rad {
        border-radius: ptr(100);

        img {
            border-radius: ptr(100);
        }
    }
}

.contentLeftInvestor {
    width: ptr(800);
    margin: 0;
    padding: 0;

    .fauxText {
        div[class *="l"] {
            background: #DEDBDB;
        }
    }

    .companyInvestorbtnctt {
        position: initial;
    }
}

.forResources,
.forStep5custom {
    .removeImg {
        position: absolute;
        width: ptr(30);
        height: ptr(30) !important;
        top: ptr(5);
        right: ptr(5);
        background-color: $gris !important;
        border-radius: ptr(30);
        color: #fff !important;
        z-index: 1;
        min-height: auto !important;
        padding: 0 !important;
        @extend .d-flex,
        .align-items-center,
        .justify-content-center;
    }

    .listSquareItem {
        [type="file"] {
            position: absolute;
            width: 100%;
        }

        li {
            position: relative;
        }

        .icon-close-pop {
            font-size: 1rem;
        }
    }
}

.titleContent {
    margin: 0;
    padding: ptr(33) ptr(200) ptr(33) 0;
    min-height: ptr(100);
    width: 100%;
    @extend .d-flex,
    .flex-wrap,
    .align-items-baseline;

    .title {
        font-size: ptr(24);
        font-weight: 600;
    }

    .linkedinLink {
        margin: 0 0 0 ptr(10);
        font-size: ptr(20);
    }

    @media (max-width: 1024px) {
        width: 100%;
        padding: 0;
        min-height: auto !important;
        text-align: center;
    }
}

.menuInvestorTab {
    @extend .d-flex,
    .align-items-center;
    background: #EFEDEE;
    height: ptr(40);

    a {
        color: #888888;
        font-size: ptr(17);
        font-weight: regular;
        margin: 0 ptr(42.7) 0 0;
        padding: ptr(5) ptr(5);
        border-bottom: 2px solid transparent;
        @extend .d-flex,
        .align-items-center,
        .justify-content-center;

        &:hover {
            border-color: $orange;
            color: #373737;
        }
    }

    a.active {
        background-color: transparent;
        border-color: $orange;
    }

    .ant-anchor {
        @extend .d-flex,
        .align-items-center;
    }

    .ant-anchor-link-active>.ant-anchor-link-title {
        border-bottom: 2px solid $orange;
    }
}

.generalInfo {
    padding: ptr(20) 0;
    width: 100%;
    @extend .d-flex;
}

.investorInfoList {
    margin: 0;
    padding: ptr(20) 0;
    @extend .d-flex,
    .flex-wrap,
    .justify-content-between;

    .seeall {
        color: $orange;
    }

    li {
        width: 100%;
        margin: 0;
        padding: 0 0 ptr(30) 0;
        font-size: ptr(16);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        label {
            width: 30%;
            min-width: 25%;
            font-weight: 600;
            white-space: break-spaces;
            /*vertical-align: middle;*/
            margin-right: ptr(10);
        }
    }

    li:last-child {
        padding-bottom: 0;
    }

    &.moreInfo {
        li {
            width: 100%;
            white-space: normal;
            @extend .d-flex;

            label {
                width: ptr(180);
            }

            .listOf {
                width: calc(100% - 180px);
            }

            p {
                background: #E8E8E8;
                border-radius: ptr(10);
                padding: 0 ptr(5);
                display: inline-block;
                margin: 0 ptr(10) ptr(10) 0;
            }
        }
    }

    &.half {
        width: 49%;
        @extend .flex-column,
        .justify-content-start;
        position: relative;
        padding: 0 ptr(16) 0 0;

        &:first-child {
            border-right: 1px dashed #c1c1c1;
            margin-right: ptr(20);
        }
    }

    @media (max-width: 1024px) {
        li {
            flex-direction: column !important;
        }
    }
}

.contentRightInvestor {
    border: 1px solid #C1C1C1;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: .16);
    padding: ptr(20) ptr(15);
    width: ptr(270);
}

.btnSetting {
    height: ptr(28);
    width: 100%;
    background: #E7E7E7;
    border-radius: ptr(3);
    border: 1px solid #CECECE;
    color: #787878;
    font-size: ptr(16);
    margin: 0 0 ptr(16) 0;
}

span.separator {
    display: block;
    height: 1px;
    width: 100%;
    background: #C1C1C1;
    clear: both;
    margin: 0 0 ptr(16) 0;
}

.infoItem {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: ptr(14);

    label {
        padding: 0 ptr(5) 0 ptr(1);
    }

    b {
        font-weight: 600;
    }

    span {
        margin-right: ptr(5);
    }
}

.cttbtnUpdate {
    @extend .d-flex,
    .justify-content-end;
    padding: ptr(10) 0 0 0;

    button {
        background: #fff;
        color: $orange;
        border: 1px solid $orange;
        border-radius: 2px;
        height: ptr(35);
        padding: 0 ptr(20);
        font-weight: 600;
    }
}

article.investorArticle {

    /*padding: ptr(60) 0;*/
    &.bgGris {
        background: #F2F2F2;
    }
}
.editMode {
    /*.bgGris {
        .cont3 {
            padding: 0;
            margin-top: 4rem;
        }
    }*/
    #started,
    #portfolio {
        height: inherit;
    }
}

#funding {
    .listSquareItem {
        a {
            background-color: #F2F2F2;
        }
    }
}

.listSquareItem {
    @extend .d-flex,
    .flex-wrap;

    li {
        margin: 0 ptr(34) 0 0;

        /*&:nth-child(4n+4) {
            margin-right: 0;
        }*/
        a {
            transition: all 500ms;
            @extend .d-flex,
            .flex-column,
            .align-items-center,
            .justify-content-start;
            padding: ptr(10) ptr(5);
            text-align: center;
            width: ptr(166);
            height: ptr(166);
            box-shadow: 0 0 10px rgba($color: #000000, $alpha: .16);
            color: #878787;
            font-weight: bold;
            font-size: ptr(16);
            text-transform: uppercase;
            position: relative;
            border-radius: 2px;
            overflow: hidden;
            background-color: #fff;

            span {
                font-size: ptr(80);
                height: ptr(100);
                @extend .d-flex,
                .align-items-center,
                .justify-content-center
            }

            &:before {
                transition: all 500ms;
                bottom: 0;
                left: 0;
                width: 100%;
                height: ptr(10);
                background-color: transparent;
                position: absolute;
                content: "";
            }

            &:hover,
            &.active {
                color: $gris;

                span {
                    color: $bleu;
                }

                &:before {
                    background-color: $bleu;
                }
            }

            &.active {
                &:after {
                    position: absolute;
                    top: ptr(10);
                    right: ptr(10);
                    background: $bleu url(../../../resources/images/checkwhite.svg) center no-repeat;
                    background-size: 50% auto;
                    width: ptr(25);
                    height: ptr(25);
                    content: "";
                    border-radius: ptr(200);
                }
            }
        }
    }

    &.strategic {
        li {
            a {

                &:hover,
                &.active {
                    span {
                        color: $orange;
                    }

                    &:before {
                        background-color: $orange;
                    }

                    &:after {
                        background-color: $orange;
                    }
                }
            }
        }
    }

    &.portfolio {
        &.add {
            margin: ptr(20) 0;
        }

        li:has(a.empty) {
            // display: none;
        }

        li {
            a {
                @extend .d-flex,
                .justify-content-center;
                box-shadow: none;
                border: 1px solid #C1C1C1;
                min-height: ptr(137);
                height: ptr(160);
                padding: ptr(5) ptr(20);

                .logo {
                    @extend .d-flex,
                    .align-items-center,
                    .justify-content-center;
                    width: 100%;
                    height: ptr(90);

                    img {
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        height: 65px;
                        object-fit: contain;
                        object-position: center;
                    }
                }

                .namePortfolio {
                    border-top: 1px solid #c1c1c1;
                    width: 100%;
                    align-self: flex-end;
                    color: $bleu;
                    font-size: ptr(16);
                    font-weight: 600;
                    text-transform: none;
                    padding: ptr(5) 0;
                    height: ptr(37);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                &:hover {
                    &:before {
                        display: none;
                    }

                    box-shadow: 0 0 10px rgba($color: #000000, $alpha: .16);
                }

                &.empty {
                    .logo {
                        background: url(../../../resources/images/defaultCompany.png) center no-repeat;
                        background-size: 50% auto;
                    }

                    .namePortfolio {
                        position: relative;

                        &:before {
                            position: absolute;
                            width: 100%;
                            height: .5px;
                            left: 0;
                            top: 50%;
                            background-color: #c1c1c1;
                            content: "";
                        }
                    }
                }
            }
        }
    }

    &.resource {
        a {
            padding: 0;
            height: ptr(183);
            box-shadow: none;
            border: 1px solid #c1c1c1;
            background: transparent;
            font-size: ptr(16);
            font-weight: normal;
            color: #373737;
            text-transform: none;

            .typeDoc {
                height: 50%;
                width: 100%;
                background-image: linear-gradient(rgba(255, 104, 3, .7), rgb(255, 102, 0));
                @extend .d-flex,
                .justify-content-center,
                .align-items-center;
                color: #fff;
                font-size: ptr(18);

                span {
                    font-size: ptr(36);
                }
            }

            .titleDoc {
                height: 50%;
                width: 100%;
                @extend .d-flex,
                .justify-content-start,
                .align-items-center;
                padding: ptr(10);
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
            }

            &:hover {
                &:before {
                    display: none;
                }

                box-shadow: 0 0 10px rgba($color: #000000, $alpha: .16);

                span {
                    color: #fff;
                }
            }

            &.empty {
                .titleDoc {
                    position: relative;

                    &:before,
                    &:after {
                        position: absolute;
                        content: "";
                        height: ptr(20);
                        width: calc(100% - 20px);
                        background-color: #C1C1C1;
                        left: ptr(10);
                        top: ptr(20);
                    }

                    &:after {
                        width: 50%;
                        top: ptr(50);
                    }
                }
            }
        }
    }
}

.dvRelative {
    position: relative;
    width: 100%;
    height: 100%;

    .top-border {
        position: absolute;
        top: ptr(55);
    }
}

.affiliatedPopup select.large-select {
    width: 100%;
}

.affiliatedPopup .form-group {
    width: 100%;
}

.linkEditLeftCompany {
    display: none;
}

.editShow {
    display: none;
}

.editShow.expertise {
    .imageCtt {
        img {
            background: url(../../../resources/images/photo.svg) center 10px no-repeat;
            background-size: 60px !important;
        }
    }
}

.editMode {
    .justForProfil {
        @extend .d-none
    }

    .editShow {
        display: block;
    }

    .linkEditLeftCompany {
        display: block;
        top: ptr(3);
        right: 0;
    }

    .titleContent * {
        display: none;
    }

    .investorInfoList {
        border: 1px solid #c1c1c1 !important;
        position: relative;
        padding: ptr(30) ptr(15);
        margin: 0 0 ptr(20) 0;
    }

    .generalInfo {
        align-items: stretch !important;

        .half {
            width: calc(50% - 10px);

            &:first-child {
                margin-right: ptr(20);
            }
        }
    }

    .contentLeftInvestor {
        position: relative;
    }
}

button.btnforEdit {
    margin: 0 0 ptr(16) 0;
    border-radius: ptr(2);
    text-align: center;
    height: ptr(28);
    width: 100%;

    &:hover {
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: .16);
    }

    &.doneEdit {
        background-color: $bleu;
        color: #fff;
        border: none;
    }

    &.preview {
        background-color: transparent;
        border: 1px solid $orange;
        color: $orange;
        margin: 0 0 ptr(16) 0;
    }
}

.rdw-option-wrapper {
    img {
        width: 15px;
        height: auto;
    }
}

.rdw-dropdown-selectedtext {
    color: $gris;
    font-size: ptr(14);
}

.rdw-editor-toolbar {
    margin: 0 !important;
}

.rdw-editor-main {
    background-color: #fff;
}

.DraftEditor-root {
    padding: ptr(10);
    min-height: ptr(200);
}

.itemSubscription {
    width: 20%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
        justify-content: start;
    }

    &:last-child {
        justify-content: end;
    }

    label {
        margin: 0 ptr(5) 0 0;
    }
}

.purcahseSubscription {
    @extend .d-flex,
    .align-items-center;
    padding: ptr(5);
    color: $gris;
    border: 1px solid $orange;
    border-radius: ptr(5);

    span {
        display: inline-block;
        margin: 0 ptr(10);
        font-size: ptr(10);
        color: $orange;
    }

    &:hover {
        color: $orange;
    }
}

.subscriptionRow {
    .itemCol {
        @extend .d-flex,
        .align-items-center,
        .justify-content-center,
        .flex-wrap;
        padding: ptr(16);
        min-height: ptr(30);
        width: 25%;

        span.icon-locked {
            align-self: flex-end;
            margin: 0 0 ptr(5) ptr(10);

            &.locked {
                color: #DEDCDC;
            }
        }

        a {
            &.linkDate {
                margin: 0 ptr(10);
                color: $gris;
                display: block;

                &.prev {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .title {
        font-weight: 600;
        text-align: center;

        .itemCol {
            &:first-child {
                justify-content: start !important;
            }
        }
    }
}

.linkedintext {
    white-space: nowrap;
}

.settingPageAccordion {
    &.accordionForSetting {
        .btnAccordion {
            @extend .d-flex,
            .align-items-center;
            &:before{
                top: 2rem;
            }

            .explication {
                padding: 0 0 0 ptr(10);
            }
        }
    }
}

.imageAffiliatedcompany {
    width: ptr(47);
    height: ptr(47);
    border-radius: ptr(60);
    background-size: auto 40px;
    margin: 0 ptr(15) 0 0;
    overflow: hidden;

    &.empty {
        background: #d1d3d4 url(../../../resources/images/emptyProfil.svg) center bottom -5px no-repeat;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

ul.separateElement,
.containerRange {
    margin: 0;
    padding: 0;

    li,
    .itemContainerRange {
        @extend .d-flex,
        .justify-content-between,
        .align-items-center;
        padding: 0 0 ptr(30) 0;

        .leftItem {
            width: 70%;

            h3.title {
                font-size: ptr(15);
            }

            .post {
                font-weight: 300;
                color: #6d6d6d;
                font-size: ptr(15);
            }

            &.relatedAndMore {
                width: 100%;

                h3.title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .rightItem {
            width: 30%;
            text-align: right;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

ul.separateElement {
    .itemAuthor {
        .infoAffiliated {
            width: calc(100% - 62px);

            h3.title,
            h4.title {
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}

.txtLabel {
    h3.title {
        width: 100%;
        margin: 0;
        padding: 0 0 ptr(5) 0;
        font-weight: 600;
        font-size: ptr(15);
    }

    span.topic {
        display: inline-block;
        font-size: ptr(12);
        font-weight: 600;
        color: #fff;
        margin: 0 ptr(5) ptr(5) 0;
        background: #8a8a8d;
        padding: 0 ptr(5);
        border-radius: ptr(10);
        cursor: pointer;
    }
}

.likeContent {
    font-size: ptr(15);
    font-weight: 600;

    span[class *="icon-"] {
        color: $orange;
        display: inline-block;
        margin: 0 ptr(5) 0 0;
    }
}

.solutionContent {
    font-weight: 600;
    font-size: ptr(15);
    color: $gris;

    b {
        font-weight: 600;
        color: $orange;
        margin: 0 ptr(2) 0 0;
    }

    &.unanswered {
        font-weight: normal;
        color: $gris;

        b {
            font-weight: 600;
            color: $gris;
        }
    }
}

.viewAll {
    margin: ptr(15) auto;
    display: block;
    min-width: ptr(114);
    border: 1.5px solid $orange;
    border-radius: 5px;
    color: $orange !important;
    font-weight: 600;
    font-size: ptr(16);
    text-align: center;
    padding: 0 ptr(10);

    &:hover {
        background-color: $orange;
        color: #fff !important;
    }
}

.modalInvestor {
    .listSquareItem {
        justify-content: center;

        &.portfolio {
            justify-content: flex-start;

            li {
                margin: ptr(15) ptr(30);
            }
        }

        .listPort {
            li {
                margin: 0 ptr(42) ptr(25) 0;
            }
        }
    }
}

.listPort {
    li {
        margin: ptr(15);
    }
}

.rdw-image-imagewrapper img {
    max-width: 100% !important;
    height: auto;
}

.headMobile {
    .titleContent {
        display: none !important;
    }
}

@media (max-width: 1024px) {
    .InvestorPage {

        .container,
        .contentLeftInvestor {
            width: 100%;
        }

        .container {
            padding: ptr(20) !important;
        }

        .listSquareItem {
            justify-content: center;

            li {
                margin: ptr(5) auto;
            }
        }

        .photoMur {
            .profilInvestorImg {
                right: 0;
                left: 0;
                margin: 0 auto
            }
        }
    }

    .profilInvestor {
        .dynamicMenu {
            margin-top: inherit;
        }

        .menuInvestorTab {
            overflow: auto;
            height: auto;

            a {
                white-space: nowrap;
            }
        }

        .contentRightInvestor {
            padding: ptr(20) 25%;
        }

        .fixedSecondHeader {
            position: static;
        }

        .investorInfoList {
            &.half {
                margin: 0;
                padding: 0;
                width: 49%;

                &:first-child {
                    border: none;
                }
            }

            li {
                label {
                    display: block;
                    width: 100%;
                }
            }

            .listOf {
                width: 100% !important;
            }
        }

        .generalInfo {
            justify-content: space-between;
            padding: ptr(16) 0;

            ul {
                padding: ptr(16) 0;
            }
        }

        .moreInfo {
            li {
                padding: ptr(30);
            }
        }
    }
}

@media (max-width: 500px) {
    .profilInvestor {
        .contentRightInvestor {
            padding: ptr(10);
        }

        .generalInfo {
            flex-direction: column;
            height: auto !important;

            ul.half {
                width: 100%;
            }
        }

        .moreInfo {
            padding: 0;

            li {
                padding: 0;
            }
        }
    }
}

.forPortfolio {
    .upload-photo {
        background: url(../../../../src/resources/images/defaultCompany.png);
        background-position: center center;
        background-size: contain;
    }

    input {
        cursor: pointer;
    }

    .icon-close-pop {
        transform: rotate(45deg);
        color: #1897c8;
        border-radius: 50%;
        position: absolute;
        z-index: 2;
        background: #fff;
        top: 6px;
        left: 25px;
    }
    .photoOk {
        margin: 0;
        object-fit: cover;
        height: 100px;
        width: 100px;
        border-radius: 50%;
    }

    .selectSol .listOf {
        height: auto;
    }
}

.removePhoto {
    position: absolute;
    bottom: 0;
    background: red;
    color: #fff;
    font-size: 10px;
    display: flex;
    align-items: center;
    left: 0;
    padding: 2px;
    opacity: 0.8;
    width: 100%;
    justify-content: center;
    z-index: 9;

    &.bg {
        width: 12%;
        left: 0;
    }

    &.ov {
        width: 12%;
        right: 0;
        left: inherit;
    }

    &.isvv {
        left: inherit;
        right: 0;
    }

    &.logoComp {
        bottom: inherit;
        top: ptr(120);
    }

    a {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }

    .icon-bin {
        margin-right: ptr(2);
    }
}

.orangeLinks {
    color: $orange;
    cursor: no-drop;
    margin-top: ptr(-20);
}

.btnShowPortfolio {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: 0;
    top: ptr(62);
    color: $bleu;

    &.mod {
        position: initial;
    }
}

.btnAdd {
    text-align: right;
    padding: 0 ptr(35);
}

.custom-ui {
    width: 70%;
    margin: auto;
    background-color: #fff;
}

.selectSol {
    .btn {
        width: ptr(350);
        line-height: 1.9;
    }
    input {
        height: ptr(45);
        margin: 0 10px;
    }
    .listOf {
        @extend .d-flex;
        align-items: center;
        border: 1px solid #e7e7e7;
        width: 100%;
        padding: ptr(10);
        margin: 0 ptr(10);
        flex-wrap: wrap;
        min-height: ptr(45);
        height: ptr(45);
        p {
            margin-bottom: 0;
            margin-right: ptr(15);
            font-size: ptr(14);
        }
        &.more {
            .topic {
                cursor: inherit;
            }
        }
        .topicAds {
            height: ptr(32);
            overflow: hidden;
        }
    }
}

.logoDetails {
    @extend .d-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #d7d7d7;
    img {
        width: ptr(117);
        height: ptr(117);
        border: 1px solid #e7e7e7;
        margin-bottom: ptr(20);
    }
}

.more {
    li {
        padding-bottom: ptr(15);
    }
    .topic {
        display: inline-block;
        font-size: 0.75rem;
        font-weight: 600;
        margin: 0 0.3125rem 0.3125rem 0;
        background: #e7e7e7;
        color: #333;
        padding: 0 0.3125rem;
        border-radius: 0.625rem;
        cursor: pointer;
    }
    .sit {
        color: $bleu;
        cursor: pointer;
    }
    // .wsite {
    //     width: 15%;
    //     margin-left: 75px;
    // }
}

.portfolioLocked {
    background: url(../../../../src/resources/images/portfolioLocked.PNG);
}

.resourcesLocked {
    background: url(../../../../src/resources/images/resourcesLocked.PNG);
}

.portfolioLocked,
.resourcesLocked {
    width: ptr(166);
    height: ptr(166);
    background-position: center center;
    background-size: cover;
    border: 1px solid #e7e7e7;
}

.expertiseLocked {
    background: url(../../../../src/resources/images/expertiseLocked.PNG);
    height: ptr(250);
    background-size: cover;
    border: 1px solid #e7e7e7;
}

.findOut {
    li {
        white-space: normal;
    }
    .ind {
        div {
            width: ptr(600);
        }
    }
}

.pagePublished {
    padding: 30px;
    .icon-check {
        color: green;
        margin-right: 5px;
    }
    p {
        display: flex;
        align-items: center;
        margin: 0;
    }
}