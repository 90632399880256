@import "../../../../style.scss";
.HomeGetStarted {
    &.findPro {
        background: url("../../../../resources/images/homeFindProgram/Home.png") right center no-repeat;
        .moveyoursoftware {
            h1 {
                font-size: ptr(45);
                &:before {
                    position: absolute;
                    top: ptr(110);
                    left: ptr(20);
                    content: "";
                    background-color: $orange;
                    width: ptr(75);
                    height: ptr(12);
                    border-radius: 7px;
                }
            }
        }
        &:after {
            display: none;
        }
        .getStartedLink {
            background-color: #fff !important;
            color: $orange !important;
            border: 1px solid;
        }
    }
}

.findProgram {
    .getStartedLink {
        width: ptr(200);
        height: ptr(50);
    }
    .hubSoftwareVendor {
        background-color: transparent;
        min-height: auto;
        margin-top: 0;
        transform: none;
        .container {
            transform: none;
        }
        .listsoftwarevendor {
            padding-bottom: 0;
            li {
                border-radius: 0;
                background-color: #ffe3d1;
                width: 50%;
                .textforitems {
                    width: 50%;
                    display: block;
                    margin: auto;
                }
                img {
                    width: ptr(100);
                    height: ptr(100);
                    object-fit: contain;
                }
                .title {
                    width: 28%;
                    display: block;
                    margin: auto;
                }
            }
            li:nth-child(2n+2) {
                background-color: #ebf9ff;
            }
        }
    }
    .listofpartnership {
        @extend .d-flex;
        align-items: center;
        justify-content: start;
        flex-wrap: wrap;
        min-height: ptr(150);
        background-color: #f2f2f2;
        li {
            img {
                width: ptr(100);
                height: auto;
                margin: ptr(40);
            }
        }
    }
    .access {
        .listofaccess {
            @extend .d-flex;
            align-items: center;
            line-height: 3;
            li {
                width: 50%;
                .title {
                    font-weight: 600;
                }
                p {
                    margin: ptr(40) 0;
                }
                img {
                    width: 100%;
                    height: auto;
                }
                .getStartedLink {
                    color: #fff;
                    background-color: $orange;
                    &:hover {
                        background-color: rgba(255, 102, 0, 0.65);
                    }
                }
            }
        }
        &.apply {
            ul {
                padding: ptr(30);
                padding-bottom: ptr(150);
            }
            .container {
                background-color: #f2f2f2;
                margin: ptr(40) 0;
            }
            li {
                img {
                    width: ptr(400);
                    display: block;
                    margin: auto;
                }
            }
        }
        &.browse {
            margin-top: ptr(50);
            .container {
                background-color: transparent;
            }
        }
    }
    .getStartedHome {
        margin-top: ptr(-150);
    }
}