
body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

hr{

  border: 1px solid #bbb!important;
  border-bottom: transparent!important;
}

hr.tchat-border{

border: 3px solid #efedee!important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.scrollContainer .block {
  position: absolute;
  transition: all 0.3s ease-in;
  padding: 1rem;
  bottom: 1rem;
  max-width: 30%;
  border: none;
  background: #fff;
 box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 6px;
  z-index: 1000;
  right: 1rem;
  font-weight: 600;
}

.scrollContainer .block span{
    font-family: sans-serif;
  float: right;
  cursor: pointer;
  font-weight: 600;
}

.scrollContainer .block span:hover{
    color: #f60;
}

.scrollContainer .block .warn-icon{
    background:  #f60;
  margin-right: 1rem;
  float: none;
  display: inline-flex;
  color: #fff;
  width: 24px;
  height: 24px;
  align-content: center;
  justify-content: center;
  border-radius: 50%
}


.imageCtt input[type="file"]{cursor: pointer;}