@import "../../../../style.scss";
.backToCommunity {
  background: url(../../../../resources/images/back.svg) center left 10px
    no-repeat;
  background-size: 8px auto;
  margin: 0 0 ptr(30) ptr(-30);
  padding-left: ptr(30);
}
.categFeed {
  @extend .d-flex, .justify-content-between, .align-items-center;
  padding: 0 ptr(45) 0 0;
  .title {
    @extend .d-flex, .align-items-center;
    span[class*="icon-"] {
      font-size: ptr(50);
      margin-right: ptr(10);
    }
    .info {
      font-size: ptr(15);
      color: #666666;
      font-weight: normal;
      b {
        font-weight: 600;
        color: #4f4f4f;
        font-size: ptr(18);
        display: block;
        padding: 0 0 ptr(5);
      }
    }
  }
}
.followFeed {
  font-size: ptr(16);
  padding: 0 ptr(5);
  height: ptr(35);
  font-weight: normal;
  border-radius: 3px;
  border: 1px solid #cecece;
  background-color: #e7e7e7;
  color: #787878;
  min-width: ptr(84);
  &.active {
    background-color: $orange;
    border-color: $orange;
    color: #fff;
  }
}
.listFollow {
  margin: 0;
  padding: 0 0 0 ptr(60);
  li {
    @extend .d-flex, .justify-content-between, .align-items-center;
    padding: ptr(20) ptr(45) ptr(20) 0;
    border-top: 1.5px dashed #c1c1c1;
  }
}
.itemAuthor {
  @extend .d-flex, .align-items-center;
}
.listAffiliatedleft {
  &.feedAffiliated {
    li {
      width: 100%;
      @extend .d-flex, .align-items-center, .justify-content-between;
      border-top: 1px solid #c1c1c1;
      padding: ptr(16) 0;
      &:first-child {
        border-top: 0;
      }
    }
  }
}
.manageFeedTab {
  a.nav-item :first-child {
    @extend .d-xl-block, .d-lg-block, .d-md-none, .d-none;
  }
}
@media (max-width: 768px) {
  .listFollow {
    padding-left: ptr(10);
  }
}
