@import "../../../../../style.scss";

.overviewcontainer {
    .linkEditLeftCompany {
        top: 0.22rem;
        right: 0;
    }

    font-size: ptr(15);

    .title {
        font-size: ptr(15);
    }

    .company-summary,
    .upload,
    .teams {
        margin-bottom: 2rem;
        position: relative;
    }

    h5.nomargin {
        margin-bottom: 0;
    }

    h5 {
        font-weight: 600;
        font-size: 1rem;
    }

    .upload {
        margin-bottom: 2rem;
        /*min-height: ptr(430);*/

        .up {
            border: 1px dashed rgb(193, 193, 193);
            color: black;
            padding: 20px;
            height: 18.75rem;
        }

        p {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 18.75rem;
            color: #6D6D6D;
            border: 1px dashed #c1c1c1;
        }

        input {
            display: block !important;
        }

        video {
            height: ptr(400) !important;
        }

        /*.bgVideo {
            min-height: ptr(422);
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            width: 100%;
            z-index: 2;

            img {
                visibility: hidden;
            }
        }*/
        .previewVideo {
            img {
                width: 100%;
                min-height: ptr(400);
            }
        }
    }

    /* Teams */
    .teams {
        .item {
            margin-bottom: 2rem;
        }

        .photo img {
            padding: 0;
            height: 4.823rem;
            width: 4.823rem;
            border: 1px solid #707070;
            border-radius: 50rem;
            object-fit: cover;
        }

        .photo {
            display: inline-block;
            width: 20%;
            text-align: center;

            p {
                color: #0084B1;
                font-weight: 600;
            }
        }

        .photo a {
            font-weight: bold;
        }

        .photo p {
            margin: 0
        }

        .desc {
            display: inline-block;
            width: 80%;
            vertical-align: top;
        }
    }

    .textTop {
        width: 35rem;
        margin: 0.655rem auto 2rem auto;
    }

    .textTop ul {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8F8F8F;
        text-align: center;
    }

    .textTop li {
        margin-right: 0.5rem;
        font-size: 0.845rem;
        text-align: left;
    }

    .textTop span {
        font-size: 1.8rem;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 767px) {

        .desc,
        .photo {
            display: block !important;
            width: 100% !important;
            margin: 0 auto;
        }

        .desc {
            margin-top: 1rem;
        }

        .textTop {
            width: 100%;
        }
    }
}

.favorite.styled {
    background-image: linear-gradient(-180deg, #fff 0%, #e7e7e7 100%);
    border-color: #e7e7e7;
    border-radius: 0.3rem;
    border-width: 1px;
    font-size: ptr(15);
}

ul.saveFile {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

ul.saveFile li {
    margin: 0 1rem 0 0;
}

.headTeam {
    font-weight: 600;
    display: flex;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: ptr(20);
    margin-top: ptr(25);
    padding: 0 !important;

    .dropdown-menu {
        border-radius: 0;
        top: -2px !important;
        padding: 0;
    }

    .dropdown {
        padding-right: ptr(15);
    }

    .dropdown-item {
        padding-left: ptr(10);
        padding-right: ptr(10);
    }

    .dropdown-item:hover:after {
        content: "\2713";
        font-weight: bold;
    }
}

.headTeam li {
    margin: 0.5rem;
}

.addMember {
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.member.contentBorder.Over {
    label.favorite.styled {
        appearance: auto;
        -webkit-writing-mode: horizontal-tb !important;
        text-rendering: auto;
        color: -internal-light-dark(black, white);
        letter-spacing: normal;
        word-spacing: normal;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        display: inline-block;
        text-align: center;
        align-items: flex-start;
        cursor: pointer;
        background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
        box-sizing: border-box;
        margin: 0em;
        padding: 1px 6px;
        border-width: 1px !important;
        border-style: outset !important;
        border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
        border-image: initial;
    }
}

.contentBorder.Over {

    .name,
    .job,
    .in {
        margin-bottom: 1rem;
    }

    .photo {
        margin-bottom: 1rem;
    }

    .photo img {
        padding: 0;
        height: 75px;
        width: 75px;
        border: 1px solid #707070;
        border-radius: 50rem;
        object-fit: cover;
        margin-right: 1.5rem;
    }

    textarea {
        height: 15.425rem;
        border-color: #ced4da;
        resize: vertical;
    }

    .teamMember,
    .teamMember:not(:disabled):not(.disabled):active,
    .teamMember:not(:disabled):not(.disabled).active,
    .show>.teamMember.dropdown-toggle,
    .teamMember:focus,
    .teamMember.focus {
        background: none;
        border: none;
        color: #444;
        font-weight: 600;
        box-shadow: none;
    }

    .teamMember {
        padding: 0;
    }
}

.contentBorder.lead {
    width: 57rem !important;
}

.modal-content #getFile {
    display: none;
}

.contentBorder.forOverview {
    font-size: ptr(15);
    width: ptr(700);
    margin: 0 auto;
    padding-right: 0 !important;

    .formForEmail,
    .px-5 {
        margin-right: 0 !important;
    }

    .descCompany {
        padding-right: 0;
    }

    .button_select {
        margin-bottom: 1rem;
        font-size: ptr(15);
    }

    @media (max-width : 1024px) {
        width: 100%;
    }
}

/*#showMember{
    display: none;
}
#showMember:target{
    display: block;
}*/
.chk-link {
    display: none;
}

.content.add {
    display: none;
    margin: 0;
    width: 100%;
    padding-top: 1rem;
}

.chk-link:checked+.content {
    display: block;
}

.link {
    color: #1897c8;
    cursor: pointer;
    font-weight: 600;
    padding-left: 1rem;
}

.forTeam {
    .addTeam {
        color: $bleu;
        font-weight: 600;
        margin-top: ptr(30);
        cursor: pointer;
    }

    .removeMember {
        color: $bleu;

        label {
            cursor: pointer;
            margin: 0;
            font-weight: normal;
        }
    }

    .left {
        padding-left: 0;
    }

    .right {
        padding-right: 0;
    }
}

.lockedFile {
    img {
        width: 100%;
        height: auto;
    }
}