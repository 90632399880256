/*.container-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 0px 3% 0px;
}

.titles {
  font-size: x-large;
}

.hide-error {
  display: none;
}

.show-error {
  display: flex;
}

.colored-border {
  border: 2px solid #dc3545 !important;
  outline: none;
}

label {
  padding: 5px 0px;
}
.container-center {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr 1fr;
  grid-template-areas:
    ". divider-container-top divider-container-top ."
    ". first-name last-name ."
    ". businesse-email password ."
    ". divider-container divider-container ."
    ". checkBoxLabel . ."
    ". captchat continue .";
}

.divider-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 0px;
}

.divider-container-top {
  grid-area: divider-container-top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 0px;
}

.divider {
  width: 100%;
  align-self: center;
}

.input-text {
  height: calc(1.5em + 0.75rem + 2px);
  max-width: 768px;
  width: 90%;
  border: 1.6px solid #dbdbdb;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 10px;
  position: relative;
}

.warning-info {
  position: absolute;
  right: 3rem;
  top: 4rem;
}

.warning-info-check {
  position: absolute;
  right: 3rem;
  top: 2.5rem;
}

.warning-info-check-check {
  position: absolute;
  right: 3rem;
  top: 4rem;
}
input[type="text"]:focus {
  border-color: #f16627;
}

input[type="password"]:focus {
  border-color: #f16627;
}

.container-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-name {
  grid-area: first-name;
  width: 100%;
}
.last-name {
  grid-area: last-name;
  width: 100%;
}
.businesse-email {
  grid-area: businesse-email;
  width: 100%;
}

.password {
  grid-area: password;
  width: 100%;
}

.divider-container {
  grid-area: divider-container;
}
.checkBoxLabel {
  display: block;
  padding: 0px;
  margin: 0px;
  grid-area: checkBoxLabel;
}

.continue {
  grid-area: continue;
  width: 100%;
  height: 3.5rem;
  display: flex;
  background-color: #f16627;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.captchat {
  grid-area: captchat;
}
*/
.continue-text {
  text-align: center;
  cursor: pointer;
}
.container-error {
  margin-bottom: 5px;
}

.error-red {
  background-color: #dc3545;
  text-transform: uppercase;
  border-radius: 5px;
  text-align: center;
  color: white;
  margin-right: 5px;
  font-size: small;
  font-family: "Source Sans Pro";
}

.txt-error {
  color: #dc3545;
  font-weight: 500;
}

.span-agree {
  color: #dc3545;
  font-family: "Source Sans Pro";
}
span {
  font-family: "Source Sans Pro";
}
@media screen and (max-width: 768px) {
  .container-center {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1.5fr 1fr;
    grid-template-areas:
      ". first-name first-name ."
      ". last-name last-name ."
      ". businesse-email businesse-email ."
      ". password password ."
      ". divider-container divider-container ."
      ". checkBoxLabel checkBoxLabel ."
      ". continue continue .";
  }

  .divider {
    width: 90%;
    align-self: center;
  }
}

