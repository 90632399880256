nav {}

.logo {
    flex: 1.5;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
}

.logo img {
    height: 2rem;
    vertical-align: top;
    border-style: none;
    margin-left: 15rem;
    object-fit: contain;
}

.menu {
    display: flex;
    flex: 2;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.text {
    text-transform: uppercase;
    color: #cccccc;
    font-family: "Source Sans Pro";
    font-size: 1.0625rem;
    font-weight: 600;
    cursor: pointer;
}

.btn-click {
    flex: 1.5;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

.signin {
    background-color: transparent;
    padding: 0.33rem 1rem;
    color: #f16627;
    border-radius: 2px;
    text-transform: uppercase;
    padding: 1rem 2rem;
    border: 1px solid #f16627;
    line-height: 0;
    font-size: 1rem;
    transition: all 500ms;
}

.bg-body {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 97%, 0 90%);
    clip-path: polygon(0 0, 100% 0, 100% 97%, 0 90%);
    background-image: url("../../../../resources/images//bg-header-old2.jpg");
    border: 1px solid white;
    height: 550px;
}

.signin:hover {
    color: white;
    border: 1px solid white;
}

.get-started {
    background-color: #fff;
    border: none;
    color: #f16627;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 0;
    font-size: 1rem;
    padding: 1rem 2rem;
    text-transform: uppercase;
    font-family: "Source Sans Pro";
    outline: none;
    cursor: pointer;
    font-family: "Source Sans Pro";
    outline: none;
    cursor: pointer;
}


/*h1,
h2 {
  font-size: 3.5rem;
  padding: 2rem 5rem 2rem 10rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  margin-left: 5rem;
}*/

.title-welcome {
    color: #ff6600;
}

.small-title {
    font-size: 1.5em;
    line-height: 1.2em;
    margin: 0;
    padding: 0 10rem;
    font-weight: 500;
    margin-left: 5rem;
    color: #ff6600;
}

.started {
    background-image: linear-gradient( rgba(255, 102, 0, 0.9), rgba(255, 102, 0, 1));
    color: white;
    outline: none;
    border-radius: 5px;
    margin: 4rem 0rem 0rem 0rem;
    padding: 1rem 0rem;
    border: none;
    font-size: 1.5rem;
    font-family: "Source Sans Pro";
    font-weight: 500;
    text-transform: none;
    width: 180px;
    text-transform: uppercase;
    font-family: "Source Sans Pro";
    outline: none;
    cursor: pointer;
}

.btn-list {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    padding: 0rem 4rem;
    margin-left: 11rem;
}

.label-free {
    font-size: 2rem;
    color: #ff6600;
    padding: 0 0rem;
}

.banner {
    background: #ff6600;
    color: #fff;
}

.content {
    border: "5px solid";
    display: flex;
    width: 95%;
    height: 100%;
    margin-left: 50px;
    justify-content: space-between;
    color: #4f4f4f;
    height: auto;
}

.blocContent {
    width: 45%;
    height: 650px;
    border: 1px solid;
    background-color: #f9fdff;
}

.layoutpartner {
    background-color: #e8e3e9;
}

.warning-info-partener {
    position: absolute;
    right: 1.5rem;
    top: 0.5rem;
}

.warning-info-partener-lastname {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
}

.hide-error-partener {
    display: none;
}

.show-error-partener {
    display: flex;
}