@import "../../../style.scss";

.softwareContainer {
    .role {
        display: inline-block;
        font-weight: 600;
        color: $orange;
        padding: 0 0 0 ptr(15);
        background: url(../../../resources/images/role.svg) left 5px center no-repeat;
        background-size: auto 12px;
    }
    .listOfIsvMatch {
        margin-top: ptr(20);
        &.soft {
            li {
                background: #fff;
                position: relative;
                flex-direction: column;
                padding: 15px;
                border-radius: 10px;
                cursor: pointer;
                .ico {
                    color: #54585A;
                    position: absolute;
                    right: ptr(15);
                    top: ptr(15);
                }
            }
            .title {
                font-weight: 600;
            }
            .name,
            .country,
            .date {
                color: #57585A;
                font-size: ptr(14);
            }
            .desc {
                color: #7A8288;
                font-size: ptr(14);
                text-align: justify;
                margin: ptr(20) 0;
            }
            .isvLogo {
                margin-bottom: 10px;
            }
        }
    }
    &.listings {
        .listOfIsv {
            width: 100%;
        }
        .listOfIsvMatch {
            margin: 0;
            li {
                width: ptr(273);
                &:nth-child(3n + 3) {
                    margin-right: 15px;
                }
                &:nth-child(3n + 4) {
                    margin-right: 0;
                }
            }
        }
        .shadow {
            border-radius: 10px;
        }
    }
    .scrollContainer {
        &.fav {
            height: calc(100vh - 175px);
        }
    }
}