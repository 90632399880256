@import "../../../style.scss";

.maContainer {
    .descAds {
            position: absolute;
            font-size: ptr(14);
            width: ptr(150);
            /*ellipsis on alternative financing country*/
            display: block;
            display: -webkit-box;
            max-width: 400px;
            margin: 0 auto;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        h6 {
            color: $bleu;
            font-weight: 600;
            font-size: ptr(14);
            margin-top: ptr(15);
            margin-bottom: ptr(5);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
        }
    }

    h6 {
      color: $bleu;
      font-weight: 600;
      font-size: ptr(14);
      margin-top: ptr(15);
      margin-bottom: ptr(5);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
    .listOfIsvMatch {
      li {
        width: calc(100% - 35px);
        margin: ptr(20) auto !important;
        min-height: ptr(215);
        &.active,
        &:hover {
          width: calc(100% - 15px);
          box-shadow: 1px 1px 5px 3px rgba(128, 128, 128, 0.5);
          transition: 0.4s;
          cursor: pointer;
  
          .topic {
            background: $orange;
            color: #fff;
          }
        }
        .isvInfo {
          margin-left: ptr(40);
          width: calc(100% - 180px);
        }
  
        .isvLogo {
          width: ptr(100);
          height: ptr(100);
        }
      }
    }
}

  .infoProgram {
    height: ptr(97);
    overflow: hidden;
    text-overflow: ellipsis;
    .detailsAds {
      padding: ptr(10) 0;
    }
    /*&.ma {
            width: ptr(650);
        }*/
  }


.colorInherit {
  color: inherit;
}

.colorInitial {
  color: initial;
}

.item {
  &.ma {
    position: relative;
  }
}
.mergers {
  .imageAffiliatedcompany {
    width: ptr(85);
    height: ptr(85);
  }

  .incognito {
    .icon-incognito {
      color: #707070;
    }
  }
  .btn {
    li {
      color: #222;
      &:hover {
        background: #333 !important;
        color: #fff;
        transition: 0.5s;
      }
    }
  }
}

.block {
  &.solInd {
    font-size: ptr(14);
    line-height: 2;
  }
}

.rightSection {
  &.ads {
    box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.06);

    .btn {
      @extend .d-flex;
      position: relative;
      align-items: center;
      padding: 0;
      margin-top: ptr(20);
      margin-bottom: ptr(20);
      &:not(:disabled):not(.disabled) {
        cursor: inherit;
      }

      li {
        background: #e4e6eb;
        border-radius: ptr(5);
        padding: ptr(5) ptr(25);
        margin-right: ptr(8);
        min-width: ptr(122);
        position: relative;
        cursor: pointer;

        .icon-inprogress {
          font-size: ptr(10);
        }
      }

      a {
        color: inherit;
      }
    }
  }

  .mini-title {
    font-size: ptr(14);
    text-decoration: underline;
  }

  .editListing {
    position: absolute;
    text-align: center;
    // left: ptr(175);
    // top: ptr(45);
    text-align: left;
    box-shadow: 1px 2px 3px #ccc;
    background: #fff;
    top: 2.3rem;
    left: 0;
    li {
      background: none !important;
      padding: 8px 35px 8px 20px !important;
      border-bottom: 1px solid #e7e7e7;
      margin: 0 !important;
      font-size: ptr(14);
    }
  }
}

.title {
  a {
    &:hover {
      color: inherit;
    }
  }
}

.collpasible {
  position: relative;
  z-index: 6;

  ul li {
    cursor: pointer;
  }

  .editListing {
    display: none;
    z-index: 3;
    animation: all 3s ease-in-out;
  }

  &:hover .editListing {
    display: block;
  }
}

.collpasible::after {
  content: "";
  width: 9rem;
  display: block;
  height: 3rem;
  position: absolute;
  top: 0;
  left: -1rem;
  z-index: 1;
  margin: 0 auto;
  cursor: pointer;
}
