@import "../../../../../style.scss";
.pitchcontainer {
    font-size: ptr(15);
    h5.title {
        margin-top: 1.5rem;
        font-weight: 600;
    }
    .title {
        font-size: ptr(15);
    }
    a {
        //color:$orange;
        color: #ff6600;
    }
}

.pitch {
    margin-bottom: 2rem;
    .fauxText {
        padding: 0;
    }
}

.upload {
    margin-bottom: 2rem;
    width: 100%;
    .up {
        border: 1px dashed rgb(193, 193, 193);
        color: black;
        padding: 20px;
        height: 18.75rem;
    }
    p {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18.75rem;
        color: #6D6D6D;
        border: 1px dashed #c1c1c1;
    }
    input {
        display: block !important;
    }
}

#getFile {
    display: none;
}

.editPageCompany {
    .logodev,
    .development,
    .strategy,
    .upload {
        position: relative;
    }
}

.contentBorder.forPitch {
    font-size: ptr(15);
    width: ptr(700);
    margin: 0 auto;
    .form-group {
        padding-right: 0;
    }
    .formForEmail,
    .px-5 {
        margin-right: 0 !important;
    }
    .content.add {
        padding-left: 1rem;
    }
    a {
        font-weight: 600;
    }
    textarea {
        border-color: #ced4da;
        resize: vertical;
    }
    @media (max-width: 1024px) {
        width: 100%;
    }
}

.logodev {
    padding: 10px;
    margin-bottom: 2rem;
    border: 1px solid $orange;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    a {
      text-align: left;
      white-space: pre-line;
    }
    img {
        padding: 0;
        width: 56.5px;
        height: 56.5px;
        margin-top: 0.5rem;
        margin-bottom: ptr(15);
        background-color: #fff;
        border-radius: 50%;
        margin: 10px auto;
    }
    &:hover {
        background: $orange;
        color: #fff;
        transition: 0.2s;
        cursor: pointer;
    }
}