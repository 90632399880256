@import "../../../../style.scss";
.filterContribution {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0 0 ptr(30) 0;
    @extend .d-flex;
    .item {
        @extend .d-flex,
        .flex-column,
        .align-items-start;
        padding: 0 ptr(10);
        width: 20%;
        label {
            display: block;
            padding: 0 0 ptr(5) 0;
            margin: 0;
            font-size: ptr(14);
            font-weight: 600;
        }
        div[class *="-container"] {
            width: 100%;
        }
        .categories__multi-value__label {
            font-size: ptr(12)
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        .item {
            width: 100%;
            margin-bottom: ptr(20);
        }
    }
}

.authorresult.allList {
    .withPost,
    .imageAffiliatedcompany,
    .nameOfPost {
        width: ptr(60)!important;
        height: ptr(75);
        margin: 0
    }
    .withPost {
        margin: 0 ptr(10) 0 0
    }
    .imageAffiliatedcompany {
        height: ptr(60)!important;
    }
    .rankAll {
        width: 32.3%;
        margin: 0 1.5% ptr(16) 0;
        &:nth-child(3n+3) {
            margin-right: 0;
        }
    }
    .itemRank {
        height: auto;
        .rang {
            color: $gris;
            background: transparent;
            border-right: 1px solid #c4c4c4;
        }
    }
    .withPost {
        .imageAffiliatedcompany {
            width: ptr(56);
            height: ptr(56);
            border-radius: 100%;
        }
    }
}