@import "../../../../../style.scss";
$grey-subscription: #a8a8a8;
$silver-subscription: #a8a8a8;
$green-subscription: #00ca72;
$custom-subscription: #1897c8;
$gris-subscription: #eaeaea;
$gold-subscription: #c99d3e;
$gris-purchase: #818181;
.subscription {
  h2 {
    text-align: center;
    font-weight: 600;
    padding-bottom: 2rem;
  }
  .subscription-content {
    max-width: 950px;
    align-items: stretch;
    .contact-subscription {
      position: absolute;
      bottom: ptr(20);
      width: 80%;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      a {
        text-transform: uppercase;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border: 1px $bleu solid;
        color: $bleu;
        @extend .radius;
        padding: 0.3rem 0;
        span {
          padding-right: 0.5rem;
        }
        &:hover {
          color: white;
          background: $bleu;
        }
      }
      .disabled-btn-purchase {
        border: 1px $gris-purchase solid !important;
        color: $gris-purchase !important;
        &:hover {
          color: $gris-purchase;
          background: white !important;
        }
      }
    }
  }
}

.free,
.silver,
.gold,
.custom {
  @extend .mb-3;
  padding: 1rem;
  height: 100%;
  padding-bottom: 3rem;
  box-shadow: -2px -1px 24px 0px rgba(0, 0, 0, 0.52);
  -webkit-box-shadow: -2px -1px 24px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: -2px -1px 24px 0px rgba(0, 0, 0, 0.52);
  border-radius: ptr(10);
  position: relative;
  overflow: hidden;
  margin-bottom: 3rem !important;
  h3 {
    font-weight: normal;
    font-size: ptr(24);
    padding: 1rem 0;
    padding-bottom: 0;
  }
}

.free {
  &::before {
    content: "";
    position: absolute;
    height: ptr(15);
    background: $grey-subscription;
    top: 0;
    left: 0;
    width: 100%;
  }
  .price-container .price {
    color: $grey-subscription;
  }
}

.silver {
  &::before {
    content: "";
    position: absolute;
    height: ptr(15);
    background: $silver-subscription;
    top: 0;
    left: 0;
    width: 100%;
  }
  .price-container .price {
    color: $silver-subscription;
  }
}

.gold {
  &::before {
    content: "";
    position: absolute;
    height: ptr(15);
    background: $green-subscription;
    top: 0;
    left: 0;
    width: 100%;
  }
  .price-container .price {
    color: $green-subscription;
  }
}

.custom {
  &::before {
    content: "";
    position: absolute;
    height: ptr(15);
    background: $gold-subscription;
    top: 0;
    left: 0;
    width: 100%;
  }
  .price-container .price {
    color: $gold-subscription;
  }
}

.price-container {
  @extend .d-flex;
  justify-content: space-between;
  align-items: center;
  min-height: ptr(60);
  .price {
    font-size: ptr(32);
  }
  .price-info {
    width: 3rem;
    span {
      display: block;
      line-height: ptr(15);
    }
  }
}

.subscription-details {
  margin-top: 1.5rem;
  li {
    padding: 0.8rem 0.5rem;
    line-height: ptr(25);
    &:nth-child(odd) {
      background: $gris-subscription;
    }
  }
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.medium {
  &.forPremium {
    .modal-dialog {
      width: ptr(800) !important;
    }
    .required {
      label {
        &:after {
          color: transparent;
        }
      }
    }
    .custom-control-input:checked ~ .custom-control-label {
      &.test {
        &:before {
          background-color: #4fbe79 !important;
          border-color: #4fbe79 !important;
        }
      }
    }
    .btn {
      &.save {
        width: 90% !important;
      }
    }
  }
}

.custom-control-input:checked ~ .custom-control-label {
  &.test {
    &:before {
      background-color: #4fbe79 !important;
      border-color: #4fbe79 !important;
    }
  }
}

.payement_success {
  .ico {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 10px solid #4caf50;
    width: ptr(100);
    height: ptr(100);
    border-radius: 50%;
    margin: 20px auto;
    span {
      color: #4caf50;
      font-size: ptr(40);
    }
  }
  .txt {
    font-size: ptr(17);
    font-weight: bold;
    color: #4caf50;
  }
  .invoke {
    display: block;
  }
}

.payement_failed {
  img {
    width: ptr(100);
    height: ptr(100);
    margin: ptr(20) auto;
  }
  .txt {
    font-size: ptr(17);
    font-weight: bold;
    color: #d55b69;
  }
}

.requi {
  &:after {
    position: absolute;
    top: 20px;
    left: 198px;
    content: "*";
    color: #ff6600;
    font-weight: bold;
  }
}
