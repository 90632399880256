.containerCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.email-error {
  background-color: #f8d7da;
  text-align: center;
  font-family: "Source Sans Pro";
  outline: none;
  border: none;
  height: 4vh;
}

.input-email-forgot {
  display: block;
  width: auto;
  height: calc(1em + 0.75rem);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 2rem;
}
