@import "../../../../style.scss";
.findInvestor {
    .b-radius {
        border-radius: ptr(100);
    }
    .isvInfo {
        width: 100% !important;
        text-align: center;
        position: relative;
        .matchValue {
            margin: 0 auto !important;
        }
    }
    .isvLogo {
        width: ptr(110) !important;
        height: ptr(110) !important;
        margin: 0 auto !important;
    }
    .listOfIsvMatch {
        .unlocked {
            .containerAction {
                border: none;
            }
        }
        li {
            @extend .d-block;
            min-height: ptr(363);
            padding: 0;
            width: 30%;
        }
        .head {
            padding: ptr(35);
            .title {
                margin: ptr(10);
            }
            .matchValue {
                position: absolute;
                left: ptr(45);
                bottom: ptr(-57);
                &.locked.m95{
                    background: #00ab4a;
                    border-color: #00ab4a;
                    color: #ffffff!important;
                }

                &.locked.m90{
                    background: #58c255;
                    border-color: #58c255;
                    color: #ffffff!important;
                }

                &.locked.m80{
                    background: #6cc76a;
                    border-color: #6cc76a;
                    color: #ffffff!important;
                }

                &.locked.m60{
                    background: #7a8288;
                    border-color: #7a8288;
                    color: #ffffff!important;
                }


                &.m95 {
                    background: #ffffff;
                    border-color: #00ab4a;
                    color: #00ab4a!important;
                }

                &.m90 {
                    background: #ffffff;
                    border-color: #58c255;
                    color: #58c255!important;
                }
                &.m80 {
                    background: #ffffff;
                    border-color: #6cc76a;
                    color: #6cc76a!important;
                }
                &.m60 {
                    background: #7a8288;
                    border-color: #7a8288;
                    color: #ffffff!important;
                }
                .icon-nextbtn {
                    font-size: ptr(10);
                    margin-right: ptr(5);
                    margin-top: 1px;
                }
            }
            .isvLogo.locked{
                img {
                    filter: blur(3px);
                }
            }
        }
        .foot {
            text-align: center;
            font-size: ptr(14);
            border-top: 1px solid #dadada;
            div {
                @extend .d-inline-block;
                border-left: 1px solid #dadada;
                padding: ptr(15) ptr(15) 0;
                width: calc(100% / 2);
                vertical-align: top;
                min-height: ptr(90);
            }
            .title {
                color: #939393;
                margin-bottom: 5px;
            }
            &.unlock {
                background-color: #e7e7e7;
                div {
                    min-height: ptr(50);
                    vertical-align: middle;
                }
                a {
                    color: #777777;
                }
            }
        }
        .containerAction {
            font-weight: bold;
            font-size: 1rem;
            color: #777777;
            text-align: center;
            padding: ptr(10);
            border-top: 3px solid #dadada;
            min-height: ptr(62);
            span {
                margin-right: ptr(5);
            }
            button.btn {
                height: ptr(35);
                min-height: ptr(35);
                min-width: ptr(75);
                font-size: 0.875rem;
                font-weight: bold;
                color: #777777;
            }
        }
    }
}

.pageCompleted {
    min-height: 75vh;
}
