.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    ". entete entete ."
    ". lines lines ."
    ". items-one items-two ."
    ". items-tree items-four ."
    ". lines-two lines-two ."
    ". btn-next btn-next .";
  row-gap: 4%;
  column-gap: 1%;
}

.entete {
  grid-area: entete;
  padding-top: 2rem;
  align-content: center;
  align-items: center;
}
.entete-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.icon {
  position: absolute;
}
.center-title {
  flex: 2;
  align-self: center;
  justify-content: center;
}
.btn-back {
  color: gray;
}
.lines {
  grid-area: lines;
  background-color: #dbdbdb;
  height: 0.05rem;
  margin: 2rem 0px 1rem 0px;
}
.items-one {
  grid-area: items-one;
  width: 100%;
  height: 60px;
}

.items-two {
  grid-area: items-two;
  width: 100%;
  height: 60px;
}

.items-tree {
  grid-area: items-tree;
  width: 100%;
  height: 70px;
  margin-top: 1rem;
}
.items-four {
  grid-area: items-four;
  width: 100%;
  height: 60px;
  margin-top: 1rem;
}

.container-form {
  position: relative;
  display: flex;
  flex-direction: column;
}

.text-input-region {
  height: 35px;
  border-radius: 5px;
  outline: none;
  border: 0.05rem solid #dbdbdb;
  transition: all 0.5s;
  padding: 0px 0px 0px 5px;
}

.text-input-region:focus {
  border: 0.05rem solid #f16627;
}
.PhoneInputInput {
  border-width: 0px !important;
  outline: none !important;
}
.lines-two {
  grid-area: lines-two;
  background-color: #dbdbdb;
  height: 0.05rem;
  margin: 4rem 0px 1rem 0px;
}
.btn-next {
  grid-area: btn-next;
  outline: none;
  background-color: #f16627;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0.5rem 0px;
  border-radius: 2px;
  cursor: pointer;
}

.btn-next label {
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.warning-info-region {
  position: absolute;
  right: 0.8rem;
  top: 3.7rem;
}

.hide-error-region {
  display: none;
}

.show-error-region {
  display: flex;
}

.colored-border-region {
  border: 2px solid red !important;
  outline: none;
}

.container-error-address {
  margin-bottom: 5px;
}

.error-red-address {
  background-color: #dc3545;
  text-transform: uppercase;
  border-radius: 5px;
  text-align: center;
  color: white;
  margin-right: 5px;
  font-size: small;
  font-family: "Source Sans Pro";
}

.txt-error-address {
  color: #dc3545;
  font-weight: 500;
}

@media screen and (max-width: 1024px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr 5fr 5fr 1fr;
    grid-template-areas:
      ". entete entete ."
      ". lines lines ."
      ". items-one items-one ."
      ". items-two items-two ."
      ". items-tree items-tree ."
      ". items-four items-four ."
      ". lines-two lines-two ."
      ". btn-next btn-next .";
    row-gap: 4%;
  }

  .text-input-region {
    height: 35px;
    border-radius: 5px;
    outline: none;
    border: 0.05rem solid #dbdbdb;
    transition: all 0.5s;
  }
}
