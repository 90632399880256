footer.connected{

	.container ul.linkfooter{

		display: flex;
		width: 50%;

		li a{

			padding-right: 2rem;
		}
	}
}