@import "../../../style.scss";
.contactPage {
    .formGetInTouch {
        justify-content: center;
    }
    h1.title {
        font-size: ptr(40);
        font-weight: 600;
        text-align: center;
        padding: 0 0 ptr(20) ptr(20);
    }
    .topQuestion {
        h4 {
            font-weight: 600;
        }
        .form-group {
            margin: auto;
            position: relative;
        }
        .ifSelected {
            padding: 0 3rem 0;
        }
        .optionChoose {
            padding: ptr(35) ptr(60) ptr(10);
        }
        .selectQuestion {
            width: 50.5%;
            display: block;
            margin: auto;
            .selectQuestion__indicator {
                background: url(../../../resources/images/down-arrow.svg) center no-repeat;
                background-size: ptr(25);
                height: 37px;
                width: ptr(40);
                background-color: $orange;
                right: ptr(290);
                border-radius: 3px;
                z-index: 2;
            }
            .selectQuestion__indicator-separator,
            .selectQuestion__dropdown-indicator svg {
                display: none;
            }
            .selectQuestion__menu {
                margin: 0;
            }
            .selectQuestion__menu-list {
                text-align: left;
            }
            .selectQuestion__option--is-selected {
                background-color: $orange;
            }
            .selectQuestion__control--menu-is-open,
            .selectQuestion__control--is-focused {
                border-color: #e7e7e7;
                box-shadow: none;
                &:hover {
                    border: none;
                    box-shadow: none;
                }
            }
        }
    }
    .listsoftwarevendor {
        margin: 0;
        padding: ptr(30) 0;
        @extend .d-flex;
        @extend .justify-content-between;
        li {
            text-align: center;
            background-color: #ffffff;
            width: 30%;
            padding: ptr(32);
            transition: all 500ms;
            border: 1px solid #dadada;
            box-shadow: 1px 1px 5px #dadada;
            margin: auto;
            .textforitems {
                min-height: ptr(112);
            }
            span[class*="icon-"] {
                color: $orange;
                font-size: ptr(50);
            }
            h2.title {
                font-size: ptr(18);
                font-weight: bold;
                margin: 0;
                padding: ptr(20) 0 ptr(20) 0;
            }
            &:hover {
                transform: scale(1.075);
            }
            .learnMore {
                width: ptr(180);
                background: #ffffff;
                color: $orange;
                border: 1px solid $orange;
                &:hover {
                    background: $orange;
                    color: #ffffff;
                }
            }
        }
        @media (max-width: 768px) {
            flex-wrap: wrap;
            li {
                width: 49%;
                margin: 0 0 ptr(15) 0;
                border-radius: 0 !important;
                background-color: #fff;
                &:nth-child(2n+2) {
                    background-color: #fff;
                }
            }
        }
        @media (max-width: 600px) {
            li {
                width: 100%;
            }
        }
    }
}

.email {
    .moreinfo {
        textarea {
            min-height: ptr(200);
        }
    }
}

.notBold {
    font-weight: normal !important;
}