/*.containerbtn-next- {
  outline: none;
  background-color: #f16627;
  border: 1px solid #f16627;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0.5rem 0px;
  border-radius: 2px;
  cursor: pointer;
}*/

.label-text-next {
  cursor: pointer;
}
