body {
  margin: 0;
  word-break: break-all;
  word-break: break-word;

  @media (max-width: 1024px) {
    padding: 0;
  }
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tabletMobil {
  @include display-tablet-mobile("flex");
}

.logoHomeText {
  font-size: ptr(35);
}

header {
  min-height: ptr(47);
  /*position: fixed;*/
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  .line-1 {
    background: $gris;
    border-bottom: 3px solid $orange;
    height: ptr(47);
    @extend .d-flex;
    @extend .align-items-stretch;
  }

  .line-2 {
    background-color: #efedee;
    height: ptr(58);
    @extend .d-flex;
    @extend .align-items-stretch;

    .container {
      @extend .d-flex;
      @extend .align-items-stretch;
    }

    nav {
      width: 100%;
      height: auto;
      padding: 0;
      background-color: transparent;
      @extend .align-items-stretch;

      .navbar-collapse {
        .navbar-nav {
          width: 100%;

          li {
            padding-right: ptr(60);

            &.divisionLink {
              padding: 0;
            }

            &.startPost {
              position: absolute;
              padding: 0;
              right: 0;
              height: 100%;
              @extend .d-flex;
              @extend .align-items-center;
            }

            .dropdown-toggle {
              &:after {
                border: 0;
                background: url(../../images/down.svg) center right no-repeat;
                height: ptr(10);
                width: ptr(10);
              }
            }
          }
        }

        @media (max-width: 1024px) {
          .navbar-nav {
            position: relative;
            min-height: calc(100vh - 47px);
            background-color: #1f1f1f;

            li {
              padding: 0;
              position: static;

              &.startPost {
                position: static;
              }

              .dropdown-toggle {
                &:after {
                  position: absolute;
                  top: ptr(20);
                  right: ptr(15);
                }
              }
            }

            .dropdown-menu {
              border-radius: 0;
              padding: 0;
            }
          }
        }
      }
    }

    @media (max-width: 1024px) {
      height: 0;

      .container {
        padding: 0;
      }
    }
  }

  .firstMenu {
    height: ptr(47);
    @include display-desktop-only("flex");

    li {
      padding: 0 ptr(51) 0 0;
      @extend .d-flex;
      @extend .align-items-center;
    }
  }

  .linkConnection {
    @include display-desktop-only("flex");
    @extend .align-items-center;
    @extend .justify-content-end;
  }

  .alreadyHeader {
    @extend .justify-content-between, .align-items-center;

    .navbar-brand {
      padding-top: 0;
    }

    .rightMenulink {
      @extend .align-items-center;
      color: #fff;
      font-weight: 600;

      .signin {
        margin-left: ptr(20);
        height: ptr(30);
        width: ptr(100);
        color: #fff;
        background-color: $orange;
        text-transform: uppercase;
        font-size: ptr(16);
        padding: ptr(16) 0;
        text-align: center;
      }

      span {
        @media (max-width: 1024px) {
          display: none;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    position: static;
  }
}

.logoHeader {
  @extend .d-flex;
  @extend .align-items-center;

  img {
    width: ptr(140);
  }
}

nav {
  min-height: ptr(76);
  padding: 0 !important;
  background-color: #163a64;
  //border-bottom: 3px solid $orange;

  .container {
    &.HeaderCenter {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
    }

    padding: 0 ptr(15) !important;
    min-height: ptr(76);
    @extend .d-flex;
    @extend .align-items-start;

    .Btnmenuheader {
      border: none;
      padding: 0;
      width: ptr(48);
      height: ptr(48);
      position: relative;

      span,
      &:before,
      &:after {
        background-image: none;
        border-radius: 2px;
        background-color: #ffffff;
        position: absolute;
        content: "";
        left: 0;
        transition: all 500ms;
        width: 100%;
        height: 2px;
        background: #fff;
      }

      span {
        top: calc(50% - 1px);
        left: 0;
      }

      &:before {
        top: ptr(10);
      }

      &:after {
        bottom: ptr(10);
        transform: rotate(0deg);
      }

      /*
            span{
                width: 90%;
                height: 90%;
                border-radius: 100%;
                top: 5%;
                z-index: 1;


            }
            &:before{
                background-color: $gris;
                width: 50%;
                height: 5px;
                z-index:10 ;
                left: 20%;
                top: calc(50% - 2.5px);
                border-radius: 0;
            }
            &:after{
                background-color: $gris;
                width: 50%;
                height: 5px;
                z-index:10 ;
                left: 20%;
                transform: rotate(90deg);
                top: calc(50% - 2.5px);
                border-radius:0;
                color: $gris;
                
            }
*/
    }

    &.getstartedNav {
      @extend .justify-content-center;
    }

    .navmenuDevinsider {
      @extend .d-flex;
      @extend .justify-content-between;
      width: 100%;

      @media (max-width: 1024px) {
        margin: 0 ptr(-15);
        width: calc(100% + 30px);
        padding: ptr(15) 0 0;
      }
    }

    .logodevinsider {
      width: auto;
      height: ptr(24);
      padding: 0;
    }

    .leftMenulink {
      padding-left: ptr(50);
    }

    .rightMenulink,
    .leftMenulink {
      @extend .d-flex;

      @media (max-width: 1024px) {
        flex-direction: column;
        padding: 0;
      }

      .nav-link,
      button.btn {
        border: 0;
        padding: 0;
        margin: 0 ptr(50) 0 0;
        color: #cccccc;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0;
        position: relative;
        background-color: transparent;
        border-radius: 0;
        @extend .d-flex;
        @extend .align-items-center;
        height: ptr(48);

        span {
          border-bottom: 2px solid transparent;
          position: relative;

          p {
            position: absolute;
            top: ptr(-5);
            right: ptr(-21);
            color: #20b03b;
            padding: 0 ptr(3);
            font-size: ptr(10);
            font-weight: bold;
            text-transform: none;
            border: 1px solid #20b03b;
            border-radius: 3px;
            line-height: 1;
          }
        }

        &:hover {
          color: #fff;
        }

        &.signinHeader {
          color: $orange;
          margin-right: ptr(20);
        }

        &.getstartedHeader {
          margin: ptr(5) 0;
          height: ptr(36);
          background: #fff;
          border-radius: ptr(3);
          padding: 0 ptr(30);
          color: $orange;

          &:hover {
            color: #fff;
            background-color: $orange;
          }
        }

        @media (max-width: 1024px) {
          margin: 0;
          padding: ptr(10) ptr(16);
          height: auto;
          border-top: 1px solid #3d3d3d;
          width: 100%;

          &.signinHeader {
            margin: 0;
          }

          &.getstartedHeader {
            height: auto;
            margin: 0;
            padding: ptr(10) ptr(16);
            background: transparent;
            color: #fff;
          }
        }
      }
    }

    .leftMenulink {
      .dropdown.show {
        span {
          border-color: #fff;
        }

        button.btn {
          &:after {
            position: absolute;
            content: "";
            bottom: -3px;
            left: calc(50% - 10px);
            width: 0;
            height: 0;
            border: 0 solid transparent;
            border-right-width: 10px;
            border-left-width: 10px;
            border-bottom: 10px solid #fff;
          }
        }

        @media (max-width: 1024px) {
          span {
            border-color: transparent;
          }

          button.btn {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  &.menuPrototype {
    height: ptr(47);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;

    .menuNav1 {
      @extend .d-flex;

      .subMenu {
        position: absolute;
        background-color: #eeee;
        padding: ptr(20);
        width: 100%;
        display: none;
        top: ptr(46);
        left: 0;
        z-index: 9999;
      }

      li.menuParent {
        min-height: ptr(47);

        &:hover {
          .subMenu {
            display: block;
          }
        }
      }
    }
  }
}

/*home*/

.HomeGetStarted {
  width: 100%;
  min-height: ptr(775);
  background: url("../../images/bg-header-4.jpg") center center no-repeat;
  background-size: cover;
  text-align: center;
  /*clip-path: polygon(0 0, 100% 0, 100% 100%, 0 87%);*/
  position: relative;
  overflow: hidden;

  &:after {
    position: absolute;
    content: "";
    background-color: #163a64;
    height: 100%;
    width: 100%;
    z-index: 20;
    opacity: 0.8;
  }

  &:before {
    /*width: 60%;
        height: 100%;
        content: "";
        background: linear-gradient( 90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;*/
  }

  .container {
    position: relative;
    z-index: 30;
  }

  .moveyoursoftware {
    color: #ffffff;
    font-size: ptr(24);
    text-align: center;
    margin: ptr(50) 0;

    .title {
      font-size: ptr(55);
      font-weight: bold;
      margin-bottom: ptr(16);
    }

    .getStartedLink {
      @extend .d-block;
      color: #fff;
      background-color: $orange;
      /* border:2px solid $orange;*/
      text-align: center;
      border-radius: ptr(50);
      margin: 0 auto;
      font-size: ptr(26);
      width: ptr(255);
      height: ptr(71);
      padding-top: 0;

      &:hover {
        background-color: rgba($color: $orange, $alpha: 0.65);
      }
    }

    .itsfree {
      font-size: ptr(18) !important;
      display: block;
    }

    @media (max-width: 1024px) {
      .title {
        font-size: ptr(35);
      }

      p {
        font-size: ptr(18);
      }
    }
  }

  @media (max-width: 1024px) {
    background-size: cover;
  }

  @media (max-width: 768px) {
    .moveyoursoftware {
      width: 100%;
      padding-bottom: 5rem;
    }
  }

  @media (max-width: 600px) {
    &:before {
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
    }
  }
}

.hubSoftwareVendor {
  min-height: ptr(350);
  padding: ptr(56) 0;
  position: relative;
  z-index: 30;
  /*transform: skewY(2deg);*/
  font-size: ptr(16);
  color: $gris;
  text-align: center;

  /*.container {
        transform: skewY(-2deg);
    }*/
  h1.title {
    font-size: ptr(48);
    font-weight: 900;
    text-align: center;
    padding: 0 0 ptr(20) 0;
  }

  .explicationvendor {
    width: ptr(900);
    margin: 0 auto;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .listsoftwarevendor {
    margin: 0;
    //padding: ptr(30) 0;
    /* position: absolute;
        top: ptr(-200); */
    @extend .d-flex;
    @extend .justify-content-between;

    li {
      /*border-radius: ptr(30) 0;*/
      background-color: #ffffff;
      width: ptr(270);
      padding: ptr(32);
      transition: all 500ms;
      border: 1px solid #d9d9d9;
      border-radius: ptr(10);
      margin: 0 ptr(12);
      color: #163a64;
      /*&:nth-child(2n+2) {
                background-color: #caf3ff;
            }*/
      .textforitems {
        min-height: ptr(112);
      }

      span[class*="icon-"] {
        color: #707070;
        font-size: ptr(63);
        border: 4px solid $orange;
        padding: 15px;
        border-radius: 50%;
      }

      h2.title {
        font-size: ptr(18);
        font-weight: bold;
        margin: 0;
        padding: ptr(20) 0 ptr(20) 0;
      }

      /*&:nth-child(3),
            &:nth-child(4) {
                border-radius: ptr(0) ptr(30);
            }*/
      &:hover {
        transform: scale(1.075);
      }
    }

    @media (max-width: 1024px) {
      flex-wrap: wrap;
      position: initial;
      //margin-top: ptr(-200);

      li {
        width: 49%;
        margin: 0 0 ptr(15) 0;
        background-color: #fff;

        &:nth-child(2n + 2) {
          background-color: #fff;
        }
      }
    }

    @media (max-width: 600px) {
      li {
        width: 100%;
      }
    }

    .bord {
      border: 4px solid $orange;
      width: 94px;
      height: 94px;
      border-radius: 50px;
      display: block;
      margin: auto;
    }
  }
  @media (max-width: 768px) {
    padding: 0;
  }
}

.howItWorks {
  /*background-image: linear-gradient(90deg, #1897c8 50%, #fff 50%);
    transform: skewY(-2deg);*/
  background-image: url(../../images/access-matching.png) !important;
  background-position: left;
  background-repeat: no-repeat !important;
  background-size: 50% !important;
  background: #fff;
  width: 100%;
  height: ptr(728);
  padding: ptr(50) 0 ptr(30) 0;
  position: relative;
  z-index: 30;
  @media (min-width: 1537px) and (max-width: 1920px) {
    height: ptr(900);
  }
  @media (max-width: 768px) {
    background-size: cover !important;
    background-position: center center !important;
    max-height: ptr(650);
    padding: 0;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: ptr(-100);
    width: 100%;
    height: 100%;
    background-image: url("../../images/how-it-work.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 38%;
    /* @media (max-width:1920px) {
            background-size: 25%;
        } */
    @media (max-width: 768px) {
      display: none;
    }
  }
  .container {
    /*transform: skewY(2deg);*/
    max-width: 100%;
    height: 650px;
    @extend .d-flex;
    @extend .align-items-stretch;
    @extend .justify-content-between;
    .title {
      @extend .d-block;
      margin: 0 auto;
    }
    @media (min-width: 1537px) and (max-width: 1920px) {
      height: 850px;
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center !important;
      padding: 0;
    }
  }

  .title {
    font-weight: bold;
    font-size: ptr(48);
    padding: 0 0 ptr(20) 0;
    text-align: center;
  }

  .textHow {
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-center;
    //border: 1px solid $orange;
    border-radius: 20px;
    width: 50%;
    height: auto;
    padding: 20px;
    text-align: center;
    color: #fff;
    margin-top: ptr(25);
    align-items: center;
    .getStartedLink {
      color: #fff;
      background-color: $orange;
      /* border:2px solid $orange;*/
      text-align: center;
      border-radius: ptr(50);
      margin: 0 auto;
      font-size: 17px;
      font-weight: 600;
      width: ptr(172);
      height: ptr(40);

      &:hover {
        background-color: rgba($color: $orange, $alpha: 0.65);
      }
    }

    p {
      font-size: ptr(20);
      font-weight: 600;
      padding: 0 0 ptr(30) 0;

      span[class*="icon-"] {
        margin: 0 ptr(20) 0 0;
        font-size: ptr(40);
        color: $orange;
      }
    }
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      background-color: #ff5500;
      height: 100%;
      width: 50%;
      z-index: -1;
      opacity: 0.8;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    h2 {
      font-weight: bold;
      text-align: left;
      font-size: ptr(43);
      @media (max-width: 768px) {
        font-size: ptr(34);
      }
    }
  }

  .imagehow {
    @media (max-width: 768px) {
      background: url(../../images/how-it-work.svg);
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
    }
    img {
      display: none;
      @media (max-width: 768px) {
        display: block;
        visibility: hidden;
        min-width: 300px;
        height: auto;
        object-fit: contain;
        margin: auto;
      }
    }
  }

  @media (max-width: 768px) {
    background-image: none;

    .textHow {
      width: 100%;

      p {
        text-align: center;
      }
    }

    .imagehow {
      //display: none;
    }
  }
}

.mainSpons {
  min-height: ptr(100);
  .container {
    @extend .d-flex;
    align-items: center;
    justify-content: center;
    height: ptr(300);
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  .title {
    color: #163a64;
    font-weight: bold;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .imgSpons {
    width: ptr(350);
    height: auto;
    object-fit: cover;
    display: block;
    margin-left: ptr(40);
    @media (max-width: 768px) {
      margin-left: inherit;
      max-width: 250px;
      display: block;
      margin: auto;
    }
  }

  /* .getStartedLink {
        color: #fff;
        background-color: #ff6600;
        text-align: center;
        border-radius: 3.125rem;
        margin: 0 auto;
        font-size: 1rem;
        width: 10.75rem;
        height: 2.5rem;
    } */
}

.customerStory {
  background: #eaeaea;
  width: 100%;
  min-height: ptr(650);
  margin: ptr(-50) 0 ptr(-128) 0;
  padding: ptr(90) 0 ptr(144) 0;

  .container {
    h1.title {
      text-align: center;
      font-weight: bold;
      font-size: ptr(48);
    }

    .slidestory {
      padding: ptr(40) 0 0 0;
      width: 100%;
      margin: 0 a uto;
      min-height: ptr(500);

      .itemSlide {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
        width: 90%;
        margin: 0 auto;
        height: ptr(500);

        img {
          width: ptr(350);
          height: auto;
          padding: 0;
        }

        .textstory {
          width: 60%;
          padding: 0 0 0 ptr(50);
        }
      }

      .carousel-item {
        background-color: #eaeaea;
        height: ptr(500);
      }

      @media (max-width: 768px) {
        .itemSlide {
          height: auto;
          flex-direction: column;

          img {
            width: ptr(200);
            height: auto;
            margin: 0 auto ptr(24) auto;
          }

          .textstory {
            width: 90%;
            padding: 0;
            text-align: justify;
          }
        }
      }
    }
  }
}

.getStartedHome {
  background-color: $orange;
  width: 100%;
  clip-path: polygon(0 0, 50% 20%, 100% 0, 100% 100%, 50% 100%, 0 100%);
  min-height: ptr(480);
  padding: ptr(136) 0 0 0;
  font-size: ptr(18);
  color: #fff;

  &.matchInv {
    clip-path: polygon(0 0, 50% 20%, 100% 0, 100% 85%, 50% 100%, 0 85%);

    .getStartedLink {
      font-size: 17px;
      font-weight: 600;
      height: ptr(44) !important;
      border-radius: ptr(56) !important;
    }
  }

  .title {
    font-weight: bold;
    font-size: ptr(48);
    text-align: center;
    padding: 0 0 ptr(20) 0;
  }

  p {
    width: 60%;
    margin: 0 auto;
    text-align: center;
  }

  .getStartedLink {
    background-color: #fff;
    color: $orange;
    margin: ptr(50) auto 0;
    font-weight: 600;
    /*border:2px solid #fff;*/
    width: ptr(172);
    height: ptr(40);
    border-radius: ptr(20);
    font-size: 17px;

    &:hover {
      background-color: rgba($color: #fff, $alpha: 0.75);
    }
  }

  &:last-of-type {
    .getStartedLink {
      font-weight: 600;
      font-size: 17px;
    }
  }

  @media (max-width: 768px) {
    padding: ptr(136) 0;
    clip-path: polygon(0 0, 50% 5%, 100% 0, 100% 100%, 50% 100%, 0 100%);
  }
}

.slidestory,
.hwoyousee {
  .react-multiple-carousel__arrow--left {
    left: 0;
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right {
    background: transparent;
    z-index: 800;

    &:before {
      color: #757575;
      font-size: ptr(30);
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.hwoyousee {
  background-color: #f7f7f7;
  min-height: ptr(384);
  margin: ptr(-104) 0 0 0;
  padding: ptr(160) 0 0 0;
  width: 100%;

  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right {
    left: ptr(-16);

    &:before {
      font-size: ptr(20);
    }
  }

  .react-multiple-carousel__arrow--right {
    right: ptr(-16);
    left: auto;
  }

  .container {
    @extend .d-flex;
    @extend .justify-content-between;

    .textforwho {
      width: 45%;
      color: $gris;

      .title {
        font-size: ptr(48);
        font-weight: bold;
        padding: 0 0 ptr(20) 0;
      }
    }

    .partner {
      width: 53%;

      .react-multi-carousel-track {
        li {
          @extend .d-flex;
          @extend .justify-content-center;
          @extend .align-items-center;
        }
      }

      .howItems {
        img {
          width: 100%;
          height: auto;
          padding: 0 ptr(16);
        }
      }
    }
  }

  @media (max-width: 768px) {
    .container {
      flex-direction: column;

      .textforwho,
      .partner {
        width: 100%;
        margin: 0 0 ptr(20) 0;
      }

      .textforwho {
        text-align: center;
      }

      .partner {
        margin: 0 0 ptr(50) 0;
      }
    }
  }
}

/*end home*/

.container {
  max-width: ptr(1200);

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.alredyhaveaccount {
  width: 100%;
  font-size: ptr(16);
  font-weight: 300;

  a {
    font-weight: 600;
  }
}

.getstartedContainer {
  width: ptr(748);
  padding: 1rem;
  margin: 0 auto;

  &.largeContainer {
    width: ptr(920);

    .btn-devinsider.large {
      width: ptr(710);
      margin: 0 auto;
    }
  }

  @media (max-width: 768px) {
    &,
    &.largeContainer {
      width: 100%;
    }
  }

  .row {
    @extend .m-0;
  }

  .head-getstarted {
    text-align: center;
    padding: ptr(47) 0 0 0;
    min-height: ptr(136);
    position: relative;
    z-index: 1;

    h1 {
      font-size: ptr(28);
      font-weight: normal;
      margin: 0;
      padding: 0;
      line-height: 1.5;

      span {
        color: $gris-claire;
        font-size: ptr(18);
        display: block;
      }
    }

    .link-back {
      position: absolute;
      top: calc(50% - 25px);
      left: 0;
      @extend .d-flex, .align-items-center, .justify-content-center;
      width: ptr(50);
      height: ptr(50);
      background-color: transparent;
      border-radius: ptr(100);
      transition: all 500ms;

      &:hover {
        background-color: $gris-disabled;
      }
    }

    .stepInscription {
      position: absolute;
      top: ptr(15);
      left: 0;
      width: 100%;
      @extend .d-flex, .align-items-center, .justify-content-center;

      .stepItem {
        width: ptr(10);
        height: ptr(10);
        border: 1.5px solid $orange;
        border-radius: ptr(20);
        margin: 0 ptr(8) 0 0;

        &.done {
          background: $orange;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    &.mini {
      min-height: auto;
      padding: 0;
      margin: ptr(16) 0 0 0;
    }

    &.medium {
      min-height: 6.5rem;

      .link-back {
        top: ptr(70);
      }
    }

    @media (max-width: 768px) {
      padding: 1rem 0;
      min-height: auto;
      height: auto;
    }
  }

  .linkgetstarted:last-child() {
    margin-bottom: 0;
  }

  .captcha {
    width: 100%;
    min-height: ptr(80);
  }
}

.stepInvestor {
  @extend .d-flex, .justify-content-between;
  width: ptr(470);
  height: auto;
  min-height: ptr(90);
  position: relative;
  margin: ptr(30) auto ptr(20) auto;

  &:after {
    width: 100%;
    height: ptr(10);
    background-color: #e0e1e2;
    position: absolute;
    top: ptr(20);
    left: 0;
    right: 0;
    width: 80%;
    margin: 0 auto;
    content: "";
    z-index: -1;
  }

  .stepInprogress {
    position: relative;
    padding: ptr(60) 0 0 0;

    .cttSpan {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: ptr(50);
      height: ptr(50);
      border-radius: ptr(50);
      border: 5px solid #e0e1e2;
      background-color: #cecece;
      color: #fff;
      margin: 0 auto ptr(14);
      @extend .d-flex, .justify-content-center, .align-items-center;

      span {
        font-size: ptr(10);

        &.icon-done {
          font-size: ptr(16);
        }
      }
    }

    &.done {
      .cttSpan {
        background-color: #68c302;
      }
    }

    &.here {
      .cttSpan {
        width: ptr(70);
        height: ptr(70);
        border-radius: ptr(70);
        top: ptr(-10);

        span {
          font-size: ptr(16);
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;
  }
}

.choiceContent {
  padding: ptr(18) 0 0 0;
  @extend .d-flex, .justify-content-between;
}

.loginScreen {
  height: 100vh;
  width: 100%;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;

  .loginContent {
    width: ptr(350);

    img {
      width: ptr(78);
      height: auto;
      padding: 0;
      margin: 0 auto ptr(16) auto;
      display: block;
    }

    @media (max-width: 500px) {
      width: 100%;
      padding: 0 ptr(15);
    }
  }

  .haveAccount {
    text-align: center;

    a {
      font-weight: 600;
      color: $bleu;
    }
  }
}

.contentBorder {
  padding: ptr(40) 0;
  border-color: #424242;
  width: 100%;

  &.checkListTopic {
    padding: ptr(40) ptr(10);

    label {
      height: ptr(55);
      display: flex;
      align-items: center;
    }
  }

  &.small-container {
    width: 40rem;
    margin: 0 auto !important;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
}

.photo-upload-getstrated {
  border: 1px solid #c4c4c4;
  @extend .d-flex;
  @extend .align-items-center;
  padding: ptr(40) ptr(16);
}

.info-final-getstarted {
  margin: 0;
  padding: 0 0 0 ptr(16);

  .nom {
    color: $bleu;
    font-weight: 600;
    text-transform: capitalize;
  }

  .post {
    b {
      font-weight: 600;
      font-size: ptr(16);
      display: block;
    }
  }

  .membersince {
    padding: ptr(20) 0 0 0;
    font-size: ptr(15);
  }
}

.stepfinal {
  font-size: ptr(18);
  text-align: center;

  .mail {
    font-size: ptr(28);
    margin: 0;
    padding: 0 0 ptr(20);
    color: $orange;
    display: block;
  }
}

.bodyDevinsider {
  margin: 0;
  padding: 0;
}

section.search {
  .container {
    margin: 0 auto;
  }
}

section.joinConversation {
  .contentNavConversation {
    background-color: $gris-disabled;

    .nav-tabs.menuConversationchoice {
      border: none;

      .nav-item {
        width: 50%;
        margin: 0;
        padding: 0;

        &.active {
          background-color: #e5e5e5 !important;
        }
      }
    }

    .listLabelLink {
      width: 100%;
      @extend .d-flex;
      @extend .flex-wrap;
      padding: 0;

      li {
        width: 25%;
        margin: 0;
        padding: 0;

        @media (max-width: 900px) {
          width: 33.33%;
        }

        @media (max-width: 500px) {
          width: 50%;
        }
      }
    }
  }
}

section.partener,
section.investor {
  background: #e8e3e9 url(../../images/bgjoin.png) center no-repeat;
  background-size: cover;
  min-height: 50vw;
  @extend .d-flex;
  @extend .align-items-stretch;

  .container {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-stretch;
    padding: ptr(130) 0;

    .textforjoin,
    .getintouch {
      width: ptr(645);
      background: #f9fdff;
      padding: ptr(35) ptr(25);
      margin: 0;
      height: auto;

      p {
        font-size: ptr(18);
        padding: 0 0 ptr(30) 0;
      }

      img {
        width: 100%;
        height: auto;
        padding: 0;
      }
    }

    .getintouch {
      width: ptr(500);
    }

    @media (max-width: 1024px) {
      padding: ptr(28) 0;
      flex-direction: column;
      align-items: center !important;

      .textforjoin,
      .getintouch {
        width: 70%;
        margin-bottom: ptr(20);

        @media (max-width: 768px) {
          width: calc(100% - 30px);
          padding: ptr(28) ptr(16);
          background-color: rgba($color: #fff, $alpha: 0.75);
          border-radius: ptr(3);

          img {
            display: none;
          }
        }
      }
    }
  }

  &.investorPage {
    @extend .align-items-center;
  }

  &.contactUs,
  &.email {
    background-color: #f2f2f2;
    background-image: none;

    textarea {
      min-height: ptr(200);
    }

    .formGetInTouch {
      .btn {
        border-radius: ptr(50);
      }
    }
  }

  &.forProgram {
    background-color: #ffffff;
    background-image: none;

    .formGetInTouch {
      .btn {
        border-radius: ptr(50);
      }
    }

    .textforjoin,
    .getintouch {
      border: 1px solid $orange;
      border-radius: 5px;
    }
  }
}

section.investor {
  align-items: center !important;
  background-image: url(../../images/investor.png);
  min-height: calc(100vh - 3.125rem);

  .formInvestor {
    width: ptr(510);
    background-color: #fff;
    height: auto;
    margin: ptr(30) auto;
    padding: ptr(64) ptr(80);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    .formInfo {
      border: 1px solid #ced4da;
      border-left: none;
      border-right: none;
      margin: ptr(27) 0 ptr(16) 0;
      padding: ptr(22) 0;
    }
  }

  @media (max-width: 800px) {
    .formInvestor {
      padding: ptr(20) ptr(16);
    }
  }

  @media (max-width: 600px) {
    .formInvestor {
      width: 90%;
    }
  }
}

.discussionCategory {
  width: 100%;
  @extend .d-flex;
  @extend .justify-content-around;
  @extend .flex-wrap;

  li {
    width: 12.6%;
    margin: 0;
    padding: 0;
    position: relative;
    min-height: ptr(180);
    height: auto;
    cursor: pointer;

    @media (max-width: 768px) {
      min-height: ptr(120);
    }

    .itemDiscussion {
      min-height: ptr(180);
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      border-bottom: 5px solid transparent;
      background-color: transparent;

      .linkDiscussion {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .flex-column;
        width: 100%;
        height: ptr(140);
        font-weight: bold;
        text-transform: uppercase;
        font-size: ptr(14);
        padding: ptr(32) 0 0 0;
        text-align: center;

        .iconSpan {
          width: 100%;
          height: ptr(66);
          @extend .d-flex;
          @extend .align-items-center;
          @extend .justify-content-center;
        }

        span {
          font-size: ptr(40);
        }

        @media (max-width: 768px) {
          text-transform: none;
        }
      }

      .contract {
        @extend .d-none;
        font-size: ptr(15);
        background-color: #fff;
        position: relative;
        z-index: 1;

        .openSubmenu {
          width: 100%;
          padding: 0 ptr(10);
          text-align: center;

          &:after {
            content: "";
            display: inline-block;
            margin-left: ptr(5);
            top: ptr(2);
            background: url(../../images/down.svg) center right no-repeat;
            height: ptr(10);
            width: ptr(10);
            transition: all 500ms;
          }
        }

        .linkInterne {
          @extend .d-none;
        }

        &.open {
          @extend .flex-column;

          .openSubmenu:after {
            transform: rotate(180deg);
          }

          .linkInterne {
            @extend .d-flex;
            @extend .flex-column;
          }
        }
      }
    }

    &:hover {
      .itemDiscussion {
        background-color: #fff;
        border-color: $orange;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
      }

      .contract {
        @extend .d-flex;
      }
    }
  }

  @media (max-width: 1024px) {
    li {
      width: 33.3%;
    }
  }

  @media (max-width: 500px) {
    li {
      width: 50%;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: ptr(20);
  }
}

/*footer*/

footer {
  background: #163a64;
  color: #fff;
  width: 100%;
  height: auto;
  margin: 0;
  padding: ptr(50) 0 0;

  .container {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .flex-wrap;

    ul.linkfooter {
      width: 30%;

      .title {
        font-size: ptr(17);
        font-weight: normal;
        margin: 0 0 ptr(16) 0;
        border-bottom: 1px solid #fff;
        padding: 0 0 ptr(8) 0;
        text-transform: uppercase;
      }

      li {
        padding: 0 0 ptr(12) 0;

        a {
          color: #fff;
        }
      }
    }

    .longlink {
      width: 100%;
      border-top: 1px solid #6b6b6b;
      padding: ptr(20) 0;
      margin: ptr(16) 0 0 0;
      @extend .d-flex;
      position: relative;

      li {
        padding: 0 ptr(20) 0 0;
        color: #adadad;

        &:last-child {
          padding: 0;
          right: 0;
          position: absolute;
        }

        &:first-child {
          font-weight: 600;
        }

        a {
          color: #adadad;

          span {
            font-size: ptr(26);
          }

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: ptr(16) 0 0;

    .container {
      flex-direction: column;

      ul.linkfooter {
        width: 100%;
      }

      .longlink {
        flex-direction: column;

        li {
          padding: 0 0 ptr(16) 0;

          &:last-child {
            position: static;
          }

          a {
            color: #ffffff;
          }
        }
      }
    }
  }
}

/*end footeer*/

.fauxText {
  width: 100%;
  margin: 0;
  padding: 0 0 ptr(20) 0;

  div[class*="l"] {
    display: block;
    height: ptr(20);
    background-color: #ebebeb;
    margin: 0 0 ptr(10) 0;
  }

  .l100 {
    width: 100%;
  }

  .l50 {
    width: 50%;
  }

  .l75 {
    width: 75%;
  }

  .l25 {
    width: 25%;
  }
}

/*isv part*/

.isv {
  .contentProfil {
    width: ptr(1080);
    height: auto;
    padding: 0;
    margin: ptr(80) auto;
    border: 1px solid #c1c1c1;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);

    .profilHead {
      border-bottom: 1px solid #c1c1c1;
      background-color: #f9f9f9;
      padding: ptr(36);
    }

    @media (max-width: 1024px) {
      width: calc(100% - 32px);
      margin: ptr(16);

      .profilHead {
        padding: ptr(16);

        div[class*="info"] {
          padding: ptr(16) 0 !important;
        }
      }
    }

    @media (max-width: 600px) {
      .profilHead {
        .infoProfil {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .infoMyIsv {
    @extend .d-flex, .align-items-center, .justify-content-center;

    .contentISV {
      border: 1px solid #e3e3e3;
      width: 100%;
      padding-bottom: ptr(10);

      .title {
        padding: ptr(10) ptr(15);
      }

      h3.title {
        padding: ptr(2) 0;
      }
    }
  }

  .role {
    padding: ptr(1) ptr(6);
    border: 1px solid #cecece;
    display: table;
    border-radius: 3px;
    font-size: ptr(14);
    font-weight: 600;
    min-width: 20%;
    text-align: center;
  }

  .svList {
    padding: ptr(15) ptr(20);
    background: #e6e2ff;

    li {
      @extend .d-flex, .align-items-center;
    }
  }

  .imgSv {
    /*background: url(../../images/createcompany.svg) center no-repeat;
    background-size: 60% auto;
    width: ptr(60);
    height: ptr(60);*/
    border: 1px solid #e3e3e3;
    margin: 0 ptr(16) 0 0;

    img {
      object-fit: cover;
    }
  }

  .aboutProfil {
    p {
      margin: 0;
      padding: 0 0 ptr(10) 0;
    }
  }

  .contentButtonEdit {
    @extend .d-flex;
  }

  .verified {
    @extend .d-flex, .justify-content-center, .align-items-center;
    background-color: $orange;
    color: #fff;
    width: ptr(19);
    height: ptr(19);
    font-size: 0.75rem;
    border-radius: 30px;
    margin-left: ptr(10);
  }

  .otherInfoProfil {
    padding: ptr(36);
    background-color: #fff;
    margin: 0;

    .infoInvestoronly {
      text-align: center;
      font-size: ptr(13);
      padding: ptr(20) 0;

      span {
        display: inline-block;
        margin: 0 ptr(10) 0 0;
      }
    }

    .contactInfo {
      border-right: 1px dashed #c1c1c1;
    }

    .contactInfoItem {
      margin: 0;
      padding: 0 0 ptr(20) 0;
    }

    @media (max-width: 1024px) {
      margin: 0;
      padding: ptr(16);
    }

    @media (max-width: 800px) {
      div[class*="Info"],
      div[class*="info"] {
        padding: ptr(16) 0;
        border: none;

        &.infoLabel {
          padding: 0;
        }
      }
    }
  }

  ul.ulListInfo {
    li {
      @extend .d-flex, .align-items-start;
      font-size: ptr(15);
      padding: 0 0 ptr(15) 0;

      .label {
        font-weight: 600;
        min-width: ptr(150);
        padding: 0 ptr(10) 0 0;
      }

      .infoLabel {
        width: calc(100% - 50px);
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          color: #888888;
          display: inline-block;
          margin: 0 0 0 ptr(6);
          font-size: ptr(12);
        }
      }
    }

    &.smallLabel {
      .label {
        min-width: ptr(120);
      }

      .infoLabel {
        width: calc(100% - 120px);
      }
    }
  }

  &.edit {
    .editItem {
      position: absolute;
      top: -1px;
      right: -1px;
      @extend .d-flex, .align-items-center;

      span {
        width: ptr(30);
        height: ptr(30);
        border: 1px solid $bleu;
        @extend .d-flex, .align-items-center, .justify-content-center;
        margin: 0 0 0 ptr(10);
      }
    }

    .imageProfil {
      position: relative;
      color: #fff;

      &.wrap {
        flex-wrap: wrap;
      }

      .removePofilPhoto {
        position: absolute !important;
        bottom: -0;
        background: red;
        color: #fff !important;
        font-size: 10px;
        display: flex;
        align-items: center;
        left: 0;
        padding: 2px;
        opacity: 0.8;
        width: 100%;
        justify-content: center;
        z-index: 9;
        height: ptr(40);
      }

      a {
        width: 120%;
        height: 120%;
        background-color: rgba($color: #000000, $alpha: 0.5);
        color: #fff;
        @extend .d-flex, .align-items-center, .justify-content-center;
        border-radius: 100%;
        position: relative;

        .absphoto {
          position: absolute;
          top: -10px;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #000, $alpha: 0.8);
          @extend .d-flex, .align-items-center, .justify-content-center;
          color: #fff;

          span {
            display: block;
            margin-right: ptr(5);
          }
        }
      }

      &.overnoBg {
        a {
          background-color: transparent;
          color: $bleu;
        }
      }
    }

    .aboutProfil,
    .boxEditProfil {
      border: 1px solid #c1c1c1;
      padding: ptr(15);
      width: calc(100% - 170px);
      position: relative;
      min-height: 100%;
    }

    .boxEditProfil {
      width: 100%;
    }

    .infoMyIsv {
      @extend .align-items-start, .justify-content-end;
    }

    .contactInfo {
      border-right: none;
      max-width: ptr(550);
    }

    .companyInfo,
    .infoMyIsv {
      width: ptr(360);
      /*max-width: ptr(360);*/
      box-sizing: border-box;
    }

    @media (max-width: 1024px) {
    }

    @media (max-width: 800px) {
      .editDoneIsv {
        width: ptr(300);
        margin: 0 auto;
      }
    }

    @media (max-width: 600px) {
      .aboutProfil {
        min-height: auto;
        width: 100%;
        margin: ptr(16) 0;
      }

      .editDoneIsv {
        width: 100%;
      }

      .ulListInfo {
        .label {
          display: none;
        }

        .infoLabel {
          width: 100%;
        }
      }
    }
  }

  &.profilIsv {
    padding: ptr(50) 0;
    min-height: calc(100vh - 50px);
    background-color: #eeeeee;
    padding-bottom: 0;
  }
}

.listAffiliated {
  margin: 0;
  padding: 0 0 ptr(20) 0;

  li {
    @extend .d-flex, .align-items-center;
    padding: 0 0 ptr(24) 0;
    width: 100%;

    &.empty {
      font-size: ptr(15);
    }

    &.item {
      @extend .justify-content-between;

      .infoAffiliated {
        margin: 0;
        padding: 0 0 0 ptr(5);
        width: calc(75% - 60px);

        p,
        b {
          margin: 0;
          padding: 0;
          line-height: 1;
          font-size: ptr(14);
        }
      }

      .role {
        float: left;
        color: gray;
      }
    }
  }
}

.contentProfil {
  font-size: ptr(15);

  .listAffiliated {
    .label {
      font-size: ptr(15);
      font-weight: 600;
    }

    .geographicList {
      @extend .d-flex, .align-items-start;
      border: none;

      .panel-toggle {
        background: none;
      }

      a {
        font-weight: 600;
        color: $orange;
        padding-left: ptr(10);
      }

      .accordion {
        margin-right: 20px;
        margin-left: 10px;
      }
    }
  }
}

/* Modal popup*/

.largePhoto.modalDevinsider {
  .imageProfil {
    border-radius: 0;
    width: 100%;
  }
}

.modalDevinsider {
  &.medium {
    .modal-dialog {
      max-width: ptr(520);
    }
  }

  &.large {
    .modal-dialog {
      max-width: ptr(1000);
    }
  }

  .modal-dialog {
    border-radius: ptr(4);

    .modal-header {
      background-color: #e5e5e5;
      height: ptr(60);
      text-align: center;

      .modal-title {
        font-size: ptr(17);
        font-weight: bold;
        text-align: center;
        width: 100%;
        position: relative;
      }

      .close {
        background: url(../../images/close.svg) center no-repeat;
        background-size: 70% auto;
        position: absolute;
        top: ptr(28);
        right: ptr(28);
        opacity: 1;

        span {
          display: none;
        }
      }
    }

    .modal-body {
      padding: ptr(40);

      &.m-negatif {
        padding: ptr(40) ptr(10);
      }
    }

    .modal-footer {
      padding: ptr(20) ptr(40);
      border: none;

      button {
        border-radius: ptr(2);
        font-size: ptr(16);
        font-weight: 600;
        color: #787878;

        &.cancelbtn {
          background: #e7e7e7;
          border: 1px solid #cecece;
          min-width: ptr(95);
        }

        &.validate {
          background: $orange;
          color: #fff;
          border: none;
          min-width: ptr(80);
        }
      }

      &.sameBtn {
        @extend .d-flex, .justify-content-between;

        button {
          width: 31% !important;
          margin: 0;

          &.selectphoto {
            background-color: $bleu;
            color: #fff;
          }
        }
      }
    }
  }
}

ul.audience {
  li {
    @extend .d-flex, .align-items-center;
    margin: 0 0 ptr(16);
    padding: ptr(10);
    border-radius: 2px;
    background: #f2f2f2;

    span[class*="icon-"] {
      width: ptr(60);
      height: ptr(60);
      border-radius: ptr(800);
      background: #e4e6eb;
      color: $gris;
      display: block;
      @extend .d-flex, .align-items-center, .justify-content-center;
      font-size: ptr(20);
    }

    .textforAudience {
      width: calc(100% - 70px);
      padding: 0 ptr(16);
      font-weight: 600;
      font-size: ptr(16);
    }

    .custom-control-label:before,
    .custom-control-input:checked ~ .custom-control-label:before {
      border: 2px solid $orange;
      width: ptr(20);
      height: ptr(20);
      background: transparent;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label:after {
      background-image: none;
      width: ptr(10);
      height: ptr(10);
      background-color: $orange;
      border-radius: ptr(10);
      top: 9.125px;
      left: -19.5px;
    }
  }
}

/* settings*/

.settings {
  footer.connected {
    margin-top: 40vh;
  }

  .container {
    padding-top: ptr(40);
  }

  .accountSettings {
    width: 100%;
  }

  .contentTabSettings,
  .contentTransactionTab {
    @extend .d-flex;

    .nav-tabs {
      @extend .d-flex,
        .flex-xl-column,
        .flex-lg-column,
        .flex-md-column,
        .flex-sm-row,
        .flex-row;
      width: ptr(200);
      background: transparent;
      border: 0;

      .nav-link {
        border: 0;
        border-left: 2px solid transparent;
        border-radius: 0;
        font-weight: 600;
        color: $gris;
        padding: 0 ptr(5);
        margin: ptr(7) 0;

        &.active {
          border: 0;
          border-left: 2px solid $bleu;
          color: $bleu;
        }
      }
    }

    .tab-content {
      width: calc(100% - 200px);
      border-left: 1px solid #c4c4c4;
      padding: 0 0 ptr(40) ptr(40);
    }
  }

  .listSettings {
    li {
      border-bottom: 1px solid #e8e8e8;
      margin: 0;
      padding: ptr(20) 0;
    }
  }

  .panel-body {
    ul:last-child {
      li:last-child {
        border-bottom: 0;
      }
    }
  }

  .itemsEditProfil {
    @extend .d-flex, .align-items-center;
    font-size: ptr(15);
    color: #666;

    &.withLink {
      @extend .flex-wrap, .align-items-start;

      ul.linkManageProgram {
        width: 100%;
        @extend .d-flex;
        margin: 0;
        padding: ptr(10) 0;

        li {
          margin: 0;
          padding: 0 ptr(20) 0 0;
          position: relative;
          border: none;

          &:before {
            content: "";
            position: absolute;
            width: ptr(4);
            height: ptr(4);
            border-radius: 100%;
            background: #666;
            right: ptr(8);
            top: ptr(10);
          }

          &:last-child {
            &:before {
              background: none;
            }
          }

          a {
            color: $orange;
          }
        }
      }
    }
  }

  .underh2,
  .underh2Over {
    width: 50%;
    font-size: ptr(15);
  }

  .underh2Over {
    text-align: right;
    min-width: 50%;
  }

  .miniInfoProfil {
    width: ptr(380);
    border: 1px solid #e8e8e8;
    padding: ptr(40) ptr(20);
    @extend .d-flex, .align-items-center;

    .cttImage {
      width: ptr(118);
      height: ptr(118);
      border-radius: 100%;
      overflow: hidden;
      border: 1px solid #e8e8e8;
      @extend .d-flex, .align-items-center, .justify-content-center;

      /*background: url(../../images/profil.svg) center top 20px no-repeat;
      background-size: 80% auto;*/
      img {
        padding: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .reasonOfleave {
    .custom-control {
      padding: ptr(10) 0 ptr(10) ptr(30);
    }

    .otherreason {
      width: 100%;
      height: ptr(100);
      resize: none;
      border-radius: ptr(2);
      border: 1px solid #e8e8e8;
      padding: ptr(10);
      font-size: ptr(14);

      &:focus,
      &:focus-visible {
        border: 1px solid #e8e8e8;
        outline: 0;
      }
    }
  }

  @media (max-width: 800px) {
    .contentTabSettings {
      .tab-content {
        width: 100%;
        padding: ptr(30) 0;
        border: none;

        .listSettings {
          padding: 0 !important;
        }

        .miniInfoProfil {
          margin: ptr(20) auto;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .itemsEditProfil {
      flex-direction: column !important;

      div[class*="under"] {
        width: 100%;

        &.underh2 {
          font-weight: 600;
          padding: 0 0 ptr(10) 0;
        }

        &.underh2Over {
          text-align: left;
        }
      }
    }
  }
}

.modalDevinsider.forDeactivate .modal-dialog .modal-body {
  padding: ptr(10) ptr(32);
}

.desactivepopup {
  h3.title {
    font-weight: 600;
    font-size: ptr(18);
    width: 100%;

    &.withIcon {
      @extend .d-flex, .flex-column, .align-items-center;
      margin: 0;
      padding: 0 0 ptr(50) 0;

      .icon-info {
        transform: rotate(180deg);
        font-size: ptr(40);
        margin-bottom: ptr(10);
      }
    }
  }

  p {
    margin: 0;
    padding: 0 0 ptr(10) 0;
    font-size: ptr(16);
  }

  .programtodeactivate {
    background: #f6f6f6;
    padding: ptr(10);
    width: 100%;
    margin: ptr(20) 0 ptr(40) 0;
    @extend .d-flex, .align-items-center;

    h3.title {
      margin: 0;
      padding: 0 0 ptr(5) 0;
    }

    .imgSv {
      border: 1px solid #e0e0e0;
      width: ptr(70);
      height: ptr(70);
      margin: 0 ptr(10) 0 0;
      background: url(../../images/createcompany.svg) center no-repeat;
      background-size: 50% auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    a {
      color: #1897c8;
      text-decoration: underline;
      font-size: ptr(15);

      &:hover {
        color: #1897c8;
      }
    }

    p {
      font-size: ptr(15);
    }

    &.no-program {
      background: none;
      border: 1px solid #b2b1b0;
    }
  }
}

.accordionForSetting {
  .btnAccordion {
    width: 100%;
    height: ptr(75);
    position: relative;
    font-size: ptr(17);
    font-weight: 600;
    color: $gris;
    text-align: left;
    border: 0;
    padding: 0;
    background-color: transparent;

    .explication {
      font-weight: normal;
      font-size: ptr(15);
    }

    &:before {
      content: "";
      background: url(../../images/accordion.svg) center no-repeat;
      background-size: 100% auto;
      width: ptr(14);
      height: ptr(14);
      right: ptr(10);
      top: calc(50% - 25px);
      position: absolute;
      /*transform: rotate(-90deg);*/
      transition: all 500ms;
    }

    &.active {
      .explication {
        display: none;
      }

      &:before {
        transform: rotate(0deg);
      }
    }
  }

  .lineAccordion {
    border-bottom: 1px solid #e8e8e8;
  }
}

.spen {
  height: ptr(100);
  overflow-x: hidden;
  overflow-y: auto;

  &:has(.collapse:not(.show)) {
    height: auto;
  }
}

.emailEdit {
  .emailName {
    width: ptr(200);
    display: inline-block;
  }

  .zoneEdit {
    margin: 0;
    padding: 0;
    width: ptr(400);
    font-size: ptr(16);
    font-weight: normal;

    .formForEmail {
      display: none;

      .btnctt {
        @extend .d-flex;
      }
    }

    &.editform {
      .formForEmail {
        display: block;
      }

      .emailText {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    .zoneEdit {
      width: 100%;
      padding: ptr(20) 0;
    }
  }
}

.popover {
  border-radius: 0;
  max-width: ptr(355);
}

.popover-body {
  border-radius: 0;
}

.popoveremail {
  .textexplic {
    display: block;
    padding: ptr(10) 0;
    font-size: ptr(14);
  }

  .formPassword {
    margin: 0;
    padding: ptr(10) 0;

    input {
      border-radius: ptr(2);
      border: 1px solid #a59b9b;
      width: 100%;
      height: ptr(40);
      padding: 0 ptr(10);
    }

    button {
      margin: ptr(10) 0 0 0;
      border: 0;
      color: $orange;
      font-weight: normal;
      background-color: transparent;
    }
  }
}

.joinHome {
  width: 100%;
  min-height: ptr(400);
  background-size: 100%;
  position: relative;
  overflow: hidden;

  p {
    font-size: 24px !important;
  }

  .itsfree {
    font-size: 18px;
    display: block;
  }

  &:after {
    position: absolute;
    content: "";
    background-color: #000;
    height: 100%;
    width: 100%;
    z-index: 20;
    opacity: 0.3;
    left: 0;
    top: 0;
  }

  &.reseller,
  &.invest,
  &.about,
  &.findInvestor,
  &.findProgram {
    .container {
      @extend .d-flex;
      flex-direction: column;
      position: relative;
      z-index: 30;
      height: ptr(400);
      justify-content: center;
    }

    .getStartedLink {
      /*@extend .d-block;*/
      color: #000;
      background-color: #fff;
      text-align: center;
      border-radius: ptr(50);
      font-size: ptr(26);
      width: ptr(255);
      height: auto;
      padding: 15px;
      display: flex;
      flex-direction: column;

      &:hover {
        background-color: rgba($color: #fff, $alpha: 0.85);
      }
    }

    .itsfree.small {
      font-size: ptr(18) !important;
      display: block;
    }
  }

  &.invest {
    background: url("../../images/head_investor.jpg") center center no-repeat;
    background-size: auto;
  }

  &.reseller {
    background: url("../../images/head_reseller.png") center center no-repeat;
    background-size: cover;

    &::after {
      opacity: 0.7;
      background: linear-gradient(to right, #000, transparent);
    }
  }

  &.about {
    background: url("../../images/head_about.png") center center no-repeat;
    background-size: auto;
    min-height: ptr(280);

    .container {
      align-items: center;
      height: ptr(280);

      .logoAbout {
        width: ptr(100);
        height: ptr(100);
        margin-bottom: ptr(20);
      }

      .title {
        padding-bottom: 0;
      }
    }

    &::after {
      opacity: 0.7;
    }
  }

  &.findInvestor {
    .container {
      background: url("../../images/findInvestor/header.png") right center
        no-repeat;
      background-size: auto;
      min-height: ptr(640);
      background-size: ptr(520);

      @media (max-width: 768px) {
        background: none;
        text-align: center;
        align-items: center;
      }

      .title {
        font-weight: bold;
        font-size: ptr(48);
        margin: 0;
        padding: 0 0 ptr(90) 0;

        @media (max-width: 768px) {
          color: #ffffff;
          font-size: ptr(35);
        }
      }

      .getStartedLink {
        color: #ffffff;
        background: $orange;
        border-radius: ptr(50);
      }
    }

    &::after {
      display: none;
    }

    @media (max-width: 768px) {
      background: url("../../images/findInvestor/header.png") right center
        no-repeat;

      &::after {
        display: initial;
      }
    }
  }

  &.findProgram {
    .container {
      background: url("../../images/findProgram/header.png") right center
        no-repeat;
      background-size: auto;
      min-height: ptr(640);
      background-size: ptr(520);

      @media (max-width: 768px) {
        background: none;
        text-align: center;
        align-items: center;
      }

      .title {
        font-weight: bold;
        font-size: ptr(48);
        margin: 0;
        padding: 0;
        margin: 0 0 ptr(48);

        @media (max-width: 768px) {
          color: #ffffff;
          font-size: ptr(35);

          br {
            display: none;
          }
        }
      }

      .getStartedLink {
        color: #ffffff;
        background: $orange;
        border-radius: ptr(50);
      }

      .descHead {
        padding: ptr(25) 0;

        @media (max-width: 768px) {
          color: #ffffff;

          br {
            display: none;
          }
        }
      }
    }

    &::after {
      display: none;
    }

    @media (max-width: 768px) {
      background: url("../../images/findProgram/header.png") right center
        no-repeat;

      &::after {
        display: initial;
      }
    }
  }

  &.market {
    background: url("../../images/header_mplace.png") right center no-repeat;
    background-size: 45%;
    min-height: ptr(600);

    &::after {
      display: none;
    }

    .container {
      min-height: ptr(600);
      @extend .d-flex;
      justify-content: center;
      flex-direction: column;

      .title {
        color: #000 !important;

        @media (max-width: 768px) {
          color: #fff !important;
        }
      }

      .getStartedLink {
        color: #ffffff;
        background: $orange;
        border-radius: ptr(50);
      }

      @media (max-width: 768px) {
        color: #fff;
        align-items: center;
        text-align: center;
      }
    }

    @media (max-width: 768px) {
      background-size: contain;
    }
  }

  &.corporate {
    background: url("../../images/header_corporate.png") right center no-repeat;
    background-size: 30%;
    background-position: 50vw;
  }
}

.landing_market {
  .softwareVendors {
    .list {
      @extend .d-flex;
      align-items: center;
      justify-content: center;

      .item {
        width: 33%;
        background: #fff;
        margin: 20px;
        box-shadow: 1px 1px 5px;
        border-radius: 5px;
        min-height: ptr(390);
        padding: 25px;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .title {
      font-weight: bold;
      display: block;
      font-size: 18px;
    }

    .text-center {
      font-size: 20px;
    }

    .smallFont {
      font-size: ptr(14);

      .subtitle {
        margin-top: ptr(25);
        margin-bottom: ptr(25);
        height: ptr(60);
        font-size: 16px;
      }

      ul {
        line-height: 2.5;
        border-top: 1px solid;
        padding-top: 30px;
        padding-bottom: 30px;
        min-height: ptr(166);

        li {
          font-size: 16px;

          .ico {
            background: $orange;
            color: #fff;
            padding: 5px;
            border-radius: 50%;
            font-size: 10px;
            margin-right: 10px;
          }

          &.softLabeling {
            line-height: 1.3;
          }
        }
      }
    }

    .btn-more {
      background: $orange;
      color: #fff;
      padding: 5px 20px;
      border-radius: 20px;
      display: block;
      margin: auto;
      text-align: center;
      font-size: 15px;
      width: ptr(120);

      @media (max-width: 768px) {
        display: initial;
      }
    }
  }
}

.landing_sponsors {
  h1 {
    &.title {
      padding: 40px 0 20px 0;
      font-weight: bold;
      text-align: center;
    }
  }

  .list {
    @extend .d-flex;
    justify-content: start;
    flex-wrap: wrap;

    .item {
      width: ptr(330);
      min-height: ptr(500);
      margin: ptr(20);
      border: 1px solid #d9d9d9;
      border-radius: 5px;

      a {
        color: $orange;
      }

      .desc {
        padding: ptr(30);
        border-top: 1px solid #d9d9d9;

        div {
          margin-top: ptr(45);
        }
      }
    }

    .img {
      width: ptr(328);
      height: ptr(200);

      img {
        width: ptr(200);
        height: ptr(200);
        display: block;
        margin: auto;
      }
    }
  }
}

.aboutDescription {
  padding: ptr(90) 0;

  p {
    font-size: ptr(18);
  }
}

.matchrel {
  flex-direction: column;
  width: ptr(210) !important;
  height: ptr(70) !important;
  border-radius: ptr(100);
}

.invOrange {
  background: $orange;
  text-align: center;
  color: #fff;

  .container {
    @extend .d-flex;
    align-items: center;
    justify-content: center;
    height: ptr(170);
  }
}

.homeDescription {
  width: 100%;
  height: auto;
  background: #f6f6f6;

  &.orange {
    background-color: $orange;
    padding-bottom: ptr(100);
  }

  &.bleu {
    background-color: $bleu;
    padding-bottom: ptr(100);
  }

  .showcase {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    padding: ptr(100) 0;

    li {
      background: #ffffff;
      border: 2px solid #d9d9d9;
      border-radius: ptr(20);
      width: 33.33%;
      min-height: ptr(370);
      text-align: center;
      font-size: ptr(20);
      font-weight: 600;
      padding: ptr(50) 0;
      margin: 10px 10px 0 0;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    img {
      width: ptr(280);
      height: ptr(175);
      object-fit: contain;
    }

    .description {
      padding: ptr(20) ptr(40) 0;
    }

    &.oem {
      li {
        background: #f6f6f6;
      }

      p {
        padding-top: 0;
      }
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
}

.attractInvest {
  width: 100%;
  height: auto;
  background: #f6f6f6;

  &.prog {
    background: #ffffff;
  }

  .container {
    @extend .d-flex;
  }

  @media (max-width: 768px) {
    br {
      display: none;
    }

    .texte {
      text-align: center;
      align-items: center;
    }
  }
}

.review {
  img {
    width: 60% !important;
  }

  @media (max-width: 768px) {
    br {
      display: none;
    }

    .texte {
      text-align: center;
      align-items: center;
    }
  }
}

.bgblanc {
  background: #fff;
}

.inclinereseller {
  margin: 0;
  padding: ptr(80) 0 ptr(80) 0;
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  min-height: ptr(200);

  &.review {
    .getStartedLink {
      font-weight: 600;
      font-size: 17px;
    }
  }

  &.postAndBrowse {
    .post {
      width: 52%;
      padding-left: 0;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &.globalCo {
    .global {
      width: 52%;
      padding-right: 0;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &.learnInv {
    padding-top: ptr(80);

    @media (max-width: 768px) {
      br {
        display: none;
      }

      .texte {
        text-align: center;
        align-items: center;
      }
    }
  }

  &.gris {
    background: #f2f2f2;

    &:after {
      background: #f2f2f2;
      transform: skewY(2deg);
    }
  }

  .container {
    position: relative;
    z-index: 3;
    @extend .d-flex, .align-items-start;

    .getStartedLink {
      border-radius: ptr(50);
      font-size: 17px;
      font-weight: 600;
    }

    img {
      width: 47%;
      height: auto;
      margin: 0;
      padding: 0 ptr(50);

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .texte {
      @extend .d-flex, .flex-column, .justify-content-center;
      padding: ptr(20);
      width: 53%;

      p {
        margin: 0;
        padding: 0;
        line-height: 2;
      }

      &.long {
        width: 100%;

        h1.title {
          text-align: center;
        }
      }

      .listOfMatch {
        margin: 0;
        padding: ptr(20) 0 ptr(60);
        @extend .d-flex, .justify-content-around;

        li {
          background: top center no-repeat;
          background-size: ptr(60) auto;
          padding: ptr(95) 0 0 0;
          font-weight: 600;
          font-size: ptr(16);
          width: 30%;
          text-align: center;

          &.easy {
            background-image: url(../../images/Eaay-To-Use.png);
          }

          &.time {
            background-image: url(../../images/Time-Efficient.png);
          }

          &.dedicated {
            background-image: url(../../images/Dedicated-Support.png);
          }
        }
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
}

.identifyList {
  @extend .d-flex, .justify-content-around;

  .item {
    width: ptr(380);
    padding: ptr(150) 0 0 0;
    background: center top no-repeat;
    background-size: ptr(118);

    h2.title {
      font-size: ptr(22);
      font-weight: bold;
      text-align: center;
    }

    &.find {
      background-image: url(../../images/Find-Software-Vendors.png);
      background-size: ptr(110);
    }

    &.exposure {
      background-image: url(../../images/Exposure.png);
    }

    &.protect {
      background-image: url(../../images/Protect.png);
      ul {
        margin-top: 2rem
      }
    }

    ul {
      margin: 0;
      padding: ptr(30) 0;

      li {
        background: url(../../images/checkli.svg) center left no-repeat;
        background-size: ptr(40) auto;
        padding: 0 0 0 ptr(55);
        margin: 0 0 ptr(16) 0;
      }
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.variousInvestor {
  @extend .d-flex, .justify-content-between;
  padding: ptr(30) 0;

  li {
    width: 30%;
    background: top center no-repeat;
    background-size: ptr(110) auto;
    font-weight: 600;
    font-size: ptr(18);
    text-align: center;
    color: $gris;
    padding: ptr(125) 0 0 0;

    &.businessAngel {
      background-image: url(../../images/Business-Angels.png);
    }

    &.venture {
      background-image: url(../../images/Venture-Capitalist.png);
    }

    &.investement {
      background-image: url(../../images/Investment-Group.png);
    }

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}

/* Page program*/

/*create program*/

.instruction {
  width: ptr(245);
  height: auto;
  border: 1px solid #c1c1c1;
  padding: ptr(130) ptr(16) ptr(16);
  min-height: ptr(420);
  background: #fff url(../../images/ecosystems.svg) center top 30px no-repeat;
  background-size: ptr(83) auto;
}

section.createCompany {
  background-color: #eeeeee;
  padding: ptr(35) 0 ptr(50) 0;
  min-height: 80vh;

  .container {
    width: ptr(1110);
    @extend .d-flex, .justify-content-between;
    padding: 0;
  }

  .listOfProgram {
    width: calc(100% - 263px);

    .notHaveComapny {
      padding: ptr(16) ptr(10);
      border: 1px solid #c1c1c1;
      background: #fff;
      @extend .d-flex, .justify-content-between, .align-items-center;
      margin: 0 0 ptr(16) 0;
    }

    .list {
      .resultListCompany {
        margin: 0;
        padding: 0 0 ptr(10) 0;
        @extend .d-flex, .flex-wrap;

        li {
          border: 1px solid #c1c1c1;
          background-color: #fff;
          padding: ptr(10);
          height: auto;
          position: relative;
          width: 32%;
          margin: 0 1.995% 0 0;

          &:nth-child(3n + 3) {
            margin-right: 0;
          }

          @extend .d-flex, .align-items-start;

          .menudrop {
            position: absolute;
            top: 0;
            right: 0;

            .dropleft {
              button {
                background-color: transparent;
                color: #8a8a8d;
                border: none;
                font-size: ptr(10);
                width: ptr(30);
                height: ptr(40);
                padding: 0;
                @extend .d-flex, .align-items-center, .justify-content-center;

                span {
                  transform: rotate(180deg);
                  display: block;
                }

                &:before {
                  border: none;
                }

                &:focus {
                  outline: none;
                  box-shadow: none;
                }
              }

              .dropdown-menu.show {
                transform: translate3d(-23px, 5px, 0px) !important;
                z-index: 1;
                transform: translate(50px, 27px) !important;
                z-index: 1;
                border: none;
                background: transparent;
                inset: inherit !important;
              }

              .dropdown-item {
                border: 1px solid #6d6d6d;
                width: 50%;
                display: block;
                padding: 4px 19px;
                font-size: 14px;
                border-radius: 2px;
                top: -4rem;
                position: absolute;
                left: -1rem;
                background: #fff !important;

                &:hover {
                  background: #eee !important;
                }
              }
            }
          }
        }

        .imgCompany {
          width: ptr(68);
          height: ptr(68);
          @extend .d-flex, .align-items-center, .justify-content-center;
          background-color: #fff;
          border: 1px solid #e8e8e8;
          overflow: hidden;

          img {
            padding: 0;
            margin: 0;
            width: 100%;
            height: auto;
          }
        }

        .infoCompany {
          width: calc(100% - 68px);
          color: #6d6d6d;
          padding: 0 0 0 ptr(10);

          .info {
            border-bottom: 1px solid #c1c1c1;
            padding: 0 0 ptr(10) 0;
          }

          .title {
            font-weight: 600;
            font-size: ptr(16);
            margin: 0;
            padding: 0;
            line-height: 1;
            color: $gris;
          }

          p {
            margin: 0;
            padding: 0;
          }

          .country {
            font-size: ptr(14);
            margin-top: ptr(15);
            line-height: 1;
          }

          .siteweb {
            color: $bleu;
            word-break: break-all;
            font-size: ptr(14);
          }

          a.request {
            width: 100%;
            max-width: ptr(200);
            margin: 0 auto;
            color: $orange;
            padding: ptr(4);
            border: 1px solid $orange;
            margin: ptr(15) auto 0 auto;
            display: block;
            text-align: center;
            font-size: ptr(13);

            &:hover {
              background-color: $orange;
              color: #fff;
            }
          }
        }

        .icon-inprogress {
          font-size: ptr(6);
          margin-left: ptr(-5);
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .container {
      width: 100%;
      padding: 0 ptr(16);
      flex-wrap: wrap;
    }

    .instruction {
      width: 100%;
      margin: 0 0 ptr(20) 0;
      min-height: auto;
    }

    .listOfProgram {
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    .listOfProgram {
      .list {
        .resultListCompany {
          li {
            &,
            &:nth-child(3n + 3) {
              width: 49%;
              margin: 0 2% ptr(16) 0;
            }

            &:nth-child(2n + 2) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .listOfProgram {
      .notHaveComapny {
        flex-direction: column;

        .createCompanyLink {
          margin-top: ptr(20);
        }
      }

      .list {
        .resultListCompany {
          li {
            &,
            &:nth-child(3n + 3),
            &:nth-child(2n + 2) {
              width: 100%;
              margin: 0 0 ptr(16) 0;
            }
          }
        }
      }
    }
  }
}

.createCompanypopup {
  &.medium {
    .modal-dialog {
      width: ptr(640);
      max-width: 90%;
    }
  }

  &.large {
    .modal-dialog {
      width: ptr(1000);
      max-width: 90%;
    }
  }

  .modal-header {
    padding: 0;
    margin: 0;
    position: relative;

    .close {
      position: absolute;
      border-radius: 100%;
      width: ptr(20);
      height: ptr(20);
      @extend .d-flex, .align-items-center, .justify-content-center;
      border: 1.5px solid $gris;
      padding: 0;
      margin: 0;
      top: ptr(10);
      right: ptr(10);
      opacity: 1;
      color: $gris;

      &.back {
        left: ptr(10);
        right: auto;
        background: url(../../images/backpopup.svg) center no-repeat;
        background-size: 80% auto;
      }
      .icon-close-pop {
        font-size: ptr(16);
      }
    }
  }

  .headerpopupCompany {
    background-color: rgba($color: $gris, $alpha: 0.15);
    min-height: ptr(100);
    width: 100%;
    padding-top: 2rem;

    &.noStep {
      min-height: ptr(60);
      padding: 0;
      .title {
        padding-top: ptr(20);
      }
    }

    ul.step {
      margin: ptr(15) auto ptr(10) auto;
      @extend .d-flex, .align-items-start, .justify-content-center;

      .stepli {
        font-size: ptr(13);
        color: #747474;
        padding: ptr(16) 0 0 0;
        position: relative;
        width: ptr(60);
        text-align: center;

        &:after {
          content: "";
          width: ptr(10);
          height: ptr(10);
          border: 2px solid #b1b1b1;
          background-color: #fff;
          position: absolute;
          top: 0;
          left: calc(50% - 5px);
          border-radius: 10px;
        }

        &.inprogress {
          font-weight: bold;

          &:after {
            border-color: $orange;
          }
        }

        &.done {
          font-weight: bold;

          &:after {
            border-color: $orange;
            background: $orange;
          }
        }
      }

      .inter {
        width: ptr(50);
        height: ptr(1.5);
        background-color: #b1b1b1;
        margin: ptr(5) ptr(-16) ptr(0) ptr(-16);

        &.done {
          background: $orange;
        }
      }
    }
  }

  .modal-body {
    padding: ptr(15) ptr(30);

    .bodyCreateCompany {
      label.mini-font {
        font-size: ptr(14);
      }

      .contentBorder {
        margin: 0;
        padding: ptr(30) 0 ptr(15) 0;
        justify-content: center;

        div[class*="col-"] {
          padding: 0;

          &.idea {
            padding: ptr(20) 0 ptr(40) ptr(60);
            background: url(../../images/idea.svg) top 15px left no-repeat;
            background-size: ptr(50) auto;
          }
        }

        label {
          font-size: ptr(16);

          .container-error {
            .error-red,
            .txt-error {
              font-size: 1rem;
            }
          }
        }

        input,
        select {
          border-radius: ptr(2);
        }
      }
    }

    &.withMinHeight {
      min-height: ptr(500);
    }

    &.holdonpop {
      img {
        width: ptr(100);
        height: auto;
        margin: ptr(20) 0 ptr(30) 0;
        padding: 0;
      }

      .title {
        font-weight: bold;
        font-size: ptr(18);
        margin: 0;
        padding: 0 0 ptr(16) 0;
      }

      p {
        margin: 0;
        padding: 0 0 ptr(30) 0;
      }

      .btn-devinsider {
        padding: ptr(1) ptr(20);
        min-height: ptr(10);
        height: ptr(40);
      }
    }
  }

  .modal-footer {
    border: none;
    padding: ptr(15) ptr(30) ptr(30) ptr(30);

    .contentBorder {
      padding: 0;
      display: flex;
      justify-content: center;
    }
  }

  .listCompanyPagePopup {
    margin: ptr(20) 0;
    padding: 0;
    border: 1px solid #bdbdbd;

    li {
      margin: 0;
      padding: ptr(15);
      @extend .d-flex;

      .imgctt {
        border: 1px solid #bdbdbd;
        width: ptr(60);
        height: ptr(60);
        @extend .d-flex, .align-items-center, .justify-content-center;
        overflow: hidden;

        img {
          margin: 0;
          padding: 0;
          width: 100%;
          height: auto;
        }
      }

      .info {
        padding: 0 0 0 ptr(10);
        font-size: ptr(15);

        * {
          display: block;
          margin: 0;
          padding: 0;
        }

        b {
          font-weight: 600;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    &.smallPopup,
    &.large {
      .modal-dialog {
        margin: ptr(16) auto;
      }
    }

    .modal-body,
    .modal-footer {
      padding: ptr(16);
    }
  }
}

.oemTooltip {
  padding: 0 5px;
  text-decoration: underline;
  cursor: pointer;
}

.forStep5custom {
  border: 1px solid #bdbdbd;
}

.haveQuestion {
  display: block;
  text-align: right;
  padding: ptr(10) ptr(16) 0 0;

  a {
    display: inline-block;
    color: $bleu;
  }
}

/*end create program*/

.photoMurcompany {
  width: 100%;
  height: ptr(230);
  background: center no-repeat;
  background-size: cover;
  position: relative;
  padding: 0;

  .cover img {
    width: 100%;
    height: 14.375rem;
    object-fit: cover;
  }

  &.empty {
    background-image: url(../../images/background-company.png);
  }

  .companyPhotoName {
    position: absolute;
    top: ptr(150);
    left: ptr(131);
    display: flex;
    align-items: center;
    flex-direction: column;

    .photo {
      width: ptr(140);
      height: ptr(140);
      border: 1px solid #c1c1c1;
      @extend .d-flex, .align-items-center, .justify-content-center;
      overflow: hidden;
      background-color: #fff;
      position: relative;

      .editShow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      img {
        width: 100%;
        height: 10rem;
        padding: 0;
        object-fit: cover;
      }
    }
  }

  @media (max-width: 1024px) {
    .companyPhotoName {
      left: ptr(55);
    }
  }

  @media (max-width: 768px) {
    .companyPhotoName {
      left: calc(50% - 70px);
    }
  }
}

.bggris {
  background-color: #eeeeee;
  padding-top: ptr(35);
  padding-bottom: ptr(35);
}

.companypage {
  .favorite {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact,
  .favorites {
    a {
      display: block;
      width: 72%;
      margin: ptr(15) auto;
      padding: ptr(9);
      border: none !important;
      min-height: auto !important;
    }
  }

  .contact {
    a {
      background: #1897c8;
    }
  }

  .container {
    background-color: #fff;
    padding: 0;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0;
    }
  }

  /* edit company page*/
  .editShow,
  .linkEditLeftCompany {
    display: none;
  }

  .onMyprofil {
    @extend .d-flex;
  }

  .doneEditing {
    @extend .d-none;
    position: absolute;
    right: 0;
    justify-content: center;
    align-items: center;

    a {
      color: #fff !important;
      height: ptr(45);
      width: ptr(200);
      background-color: $bleu;
    }
  }

  &.editPageCompany {
    .onMyprofil {
      @extend .d-none;
    }

    .doneEditing {
      @extend .d-flex;
    }

    .editShow,
    .linkEditLeftCompany {
      display: block;
    }

    .progressCompany {
      @extend .d-none;
    }

    .containerLeftItem {
      position: relative;
    }
  }

  /* end edit company page*/
}

.columnOfCompany {
  border: 1px solid #c1c1c1;
  border-top: none;

  .mini-column {
    padding: ptr(100) ptr(15) 0 ptr(15);
    border-right: 1px solid #c1c1c1;

    .containerLeftItem {
      .linkEditLeftCompany {
        top: ptr(15);
      }

      &:nth-child(2) {
        .linkEditLeftCompany {
          top: ptr(-26);
        }
      }
    }
  }

  .maxi-column {
    .nav {
      border-bottom: 1px solid #c1c1c1;

      a.nav-link {
        padding: ptr(8) ptr(20);
        font-size: ptr(18);
        color: #888888;
        text-align: center;
        min-width: ptr(120);
        border-bottom: 4px solid transparent;
        border-radius: 0;

        &.active {
          font-weight: bold;
          background-color: transparent;
          color: $gris;
          border-color: $orange;
        }
      }
    }

    .contentCompanyTab {
      margin: 0;
      padding: ptr(20);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    .maxi-column {
      padding-top: ptr(120) !important;

      .nav {
        flex-wrap: nowrap;
        width: 100%;
        overflow-y: auto;
      }
    }
  }

  @media (max-width: 500px) {
    .maxi-column {
      .nav {
        .nav-link {
          max-width: none;
          padding: ptr(16);
        }
      }
    }
  }
}

.containerLeftItem {
  border-top: 1px solid #c1c1c1;

  .containerGraph {
    width: 35%;
    height: auto;
    cursor: pointer;

    .labelProgressBar {
      @extend .d-flex, .flex-column, .align-items-center;
      font-weight: bold;
      font-size: ptr(12);
      color: #898989;

      b {
        font-size: ptr(26);
        line-height: 1;
      }
    }
  }

  &.progressCompany {
    @extend .d-flex, .align-items-center, .justify-content-center;
    min-height: ptr(180);
  }

  .contentInfobulle {
    width: 50%;

    label {
      font-weight: 600;
    }

    .listOfDone {
      display: none;
      width: 90%;
      margin: ptr(5) ptr(15) ptr(5) 0;
      padding: ptr(15);
      border: 1px solid #cecece;
      background-color: #eeeeee;
      border-radius: ptr(2);
      position: relative;
      font-size: ptr(14);

      &:after {
        font-size: ptr(16);
        content: "►";
        position: absolute;
        top: calc(50% - 8px);
        right: ptr(-15);
        color: #cecece;
        z-index: -1;
      }

      &:before {
        font-size: ptr(16);
        content: "►";
        position: absolute;
        top: calc(50% - 8px);
        right: ptr(-13);
        color: #eeeeee;
      }

      .titleList {
        padding: 0 0 ptr(12) 0;
      }

      .item {
        font-size: ptr(13);
        padding-left: ptr(16);

        &.done {
          font-weight: 600;
          background: url(../../images/checkgreen.svg) center left no-repeat;
          background-size: ptr(12) auto;
        }
      }
    }

    &.showInfobule {
      label {
        display: none;
      }

      .listOfDone {
        display: block;
      }
    }
  }

  @media (max-width: 1024px) {
    &.progressCompany {
      flex-direction: column-reverse;

      .containerGraph {
        width: 70%;
        margin: ptr(10) auto;
      }

      .listOfDone {
        width: 100%;
        margin: ptr(10) 0;

        &:before,
        &:after {
          right: calc(50% - 8px);
          top: ptr(-16);
          transform: rotate(-90deg);
        }

        &:before {
          top: ptr(-15);
        }
      }
    }
  }

  @media (max-width: 768px) {
    &.progressCompany {
      flex-direction: row-reverse;
      justify-content: center !important;

      .containerGraph {
        width: 25%;
        margin: ptr(10) auto;
      }

      .listOfDone {
        width: 70%;
        margin: ptr(10) 0;

        &:before,
        &:after {
          right: auto;
          left: ptr(-13);
          top: calc(50% - 8px);
          transform: rotate(-180deg);
        }

        &:before {
          left: ptr(-11);
        }
      }
    }
  }

  @media (max-width: 600px) {
    &.progressCompany {
      .containerGraph {
        width: 40%;
      }

      .listOfDone {
        width: 50%;
      }
    }
  }
}

.editPageCompany .containerLeftItem {
  border-top: 0px;
  border-bottom: 1px solid #c1c1c1;
}

ul.listnamelabel {
  li {
    @extend .d-flex;
    padding: 0 0 ptr(10) 0;
    font-size: ptr(15);

    .label,
    .name {
      overflow: hidden;
      white-space: break-spaces;
      text-overflow: ellipsis;
    }

    .label {
      min-width: 50%;
      padding: 0 ptr(10) 0 0;
      font-weight: 600;
    }

    .name {
    }

    &.link {
      a {
        color: $orange;
      }
    }
  }
}

.listAffiliatedleft {
  li {
    @extend .d-flex;
    padding: ptr(10) 0;

    .imageAffiliatedcompany {
      width: ptr(60);
      height: ptr(60);
      overflow: hidden;
      border-radius: ptr(60);
      @extend .d-flex, .align-items-center, .justify-content-center;
      border: 1px solid #707070;

      img {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 4rem;
        object-fit: cover;
      }
    }

    .infoAffiliated {
      font-size: ptr(14);
      padding: 0 0 0 ptr(16);

      h4 {
        .title {
          cursor: pointer;
        }
      }
    }
  }
}

/*end page program*/

.affiliatedPopup {
  .btn-devinsider {
    background-color: transparent;
    border: 1px solid $orange;
    color: $orange;
    height: ptr(40);
    min-height: ptr(40);
    font-weight: normal;
  }

  select {
    width: ptr(150);
  }

  .infoAffiliated {
    a {
      color: $bleu;
      font-weight: 600;
      padding: 0;
      display: block;
    }
  }
}

.borderAll {
  border: 1px solid #e5e5e5;

  .row {
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    margin: 0;
    padding: 0;

    &:last-child {
      border-bottom: 0px;
    }
  }
}

.invitationSent {
  margin: ptr(20) 0;
}

.addPartneer {
  .paterneer {
    @extend .d-flex, .align-items-center, .justify-content-start;
    height: 38px;
    border-radius: ptr(3);
    margin: 0 0 ptr(20) 0;

    input[type="text"],
    .css-2b097c-container {
      width: calc(100% - 38px);
      height: 38px;
      border-radius: 2px 0px 0px 2px !important;
      border-right: 0;
    }

    .partneer__control--menu-is-open {
      .partneer__value-container {
        .partneer__placeholder {
          display: none !important;
        }
      }
    }

    .partneer__indicators {
      display: none !important;
    }

    .css-2b097c-container {
      border: 1px solid #ced4da;
      height: ptr(40);
      padding-top: 0;

      .css-1pahdxg-control {
        border: none;
        height: ptr(38);
        box-shadow: none;
      }

      .partneer__value-container {
        height: ptr(38);
        padding: 0;
        cursor: text;
      }

      .css-yk16xz-control {
        height: ptr(38) !important;
        border: none;
        cursor: text;
      }
    }

    .partneer__placeholder,
    .partneer__single-value {
      height: ptr(38);
      @extend .d-flex, .align-items-center;
      transform: translateY(0) !important;
      position: static !important;

      & ~ div[class*="css-"] {
        height: ptr(38);
        padding: 0;
        margin: 0;
      }
    }

    .css-b8ldur-Input {
    }

    a {
      width: ptr(40);
      height: ptr(40);
      @extend .d-flex, .align-items-center, .justify-content-center;
      border-radius: 0 ptr(2) ptr(2) 0;

      &:before {
        content: "";
        height: ptr(34);
        width: ptr(34);
        background: url(../../images/plus.svg) center no-repeat;
        background-size: 100% auto;
      }

      &.add {
        background-color: #5cb85c;
      }

      &.remove {
        background-color: #d9534f;

        &:before {
          transform: rotate(45deg);
        }
      }
    }
  }
}

.infoForCompany {
  color: #888888;

  span {
    font-size: ptr(28);
  }

  p {
    margin: 0;
    padding: 0 0 0 ptr(20);
    font-size: ptr(14);
  }
}

.contentBilling {
  @extend .d-flex, .flex-wrap, .justify-content-between;

  .itemBilling {
    width: 49%;
    height: auto;
    margin: 0 0 ptr(20) 0;
    padding: 0;
    border-radius: ptr(10);
    overflow: hidden;
    box-shadow: 0 0 10px rgba($color: #000, $alpha: 0.2);

    .title {
      background-color: #f2f2f2;
      margin: 0;
      padding: ptr(10) ptr(20);
      font-weight: 600;
      font-size: ptr(16);
    }

    .contentItem {
      margin: 0;
      padding: ptr(10) ptr(20);
    }

    .itemctt {
      min-height: ptr(90);
      font-weight: 600;

      b {
        font-weight: 600;
      }

      small {
        color: #a8a8a8;
      }
    }

    .footerItem {
      border-top: 1px solid #f2f2f2;
      padding: ptr(10) 0 0 0;
      font-size: ptr(12);

      a {
        color: $orange;
      }
    }
  }
}

.radius {
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 1.5rem;
}

.oemAcc {
  position: absolute;
  top: ptr(45);
  right: 0;

  button {
    position: initial !important;
  }
}

.contentBorder.forBillingCenter {
  .forAds input[type="radio"]:checked:after {
    top: 0.8px !important;
  }

  font-size: ptr(15);

  .menuTransactionTab {
    .nav-link {
      border-bottom: 2px solid transparent;
    }

    .nav-link.active {
      font-weight: bold;
      background-color: transparent;
      color: #4f4f4f;
      border-bottom: 2px solid #ff6600;
    }
  }

  .evo {
    background: #f1f1f1;
    padding: ptr(24);
    max-width: 33.3%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "…";
  }

  .contentPlanTab {
    .icon-aide {
      margin-left: ptr(10);
      color: #fff;
      background: #000;
      border-radius: 100%;
    }

    .title {
      font-weight: 600;
    }

    .info p {
      color: #999999;
      margin-bottom: 0;
    }

    small {
      color: #999999;
    }

    .lineAccordion {
      position: relative;

      button {
        position: absolute;
        height: 0;
        bottom: ptr(140);

        &.active {
          top: ptr(-135);
        }
      }
    }

    .plan_details {
      button {
        top: ptr(-106);
      }
    }

    .accordionForSetting .btnAccordion.active:before {
      transform: rotate(180deg);
    }

    .img_receipts {
      img {
        width: ptr(25);
        height: auto;
        margin: ptr(10);
      }
    }
  }

  h5 {
    margin: 0;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
  }

  .menuPlanTab {
    box-shadow: 2px 3px 5px #e7e7e7;

    button {
      border: 1px solid #dee2e6;
      border-radius: 5px;
    }

    .create-company {
      width: 100%;
      max-width: ptr(200);
      margin: 0 auto;
      color: $orange;
      padding: ptr(4);
      border: 1px solid $orange;
      background-color: white;
      margin: ptr(15) auto 0 auto;
      display: block;
      text-align: center;
      font-size: ptr(14);

      &:hover {
        background-color: $orange;
        color: #fff;
      }
    }
  }

  .nav-item,
  .menuPlanTab {
    padding: 0;
  }

  a.nav-link {
    color: #4f4f4f;
    font-size: 1.2rem;
    padding: 1rem;
  }

  a.nav-link.active {
    background: none;
    border-radius: 0;
    font-weight: bold;
    color: #4f4f4f;
  }

  .credit_card,
  .paypal {
    align-items: center;
    padding-left: 1.5rem;
  }

  .credit_card img {
    margin-right: 0.5rem;
    height: 1.7rem;
    border: 1px solid #e5e5e5;
    object-fit: cover;
    padding: 0.5rem;
    box-shadow: inset 1px 1px 6px 0px #e7e7e7;
    border-radius: 0.19rem;
  }

  textarea {
    resize: vertical;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .contact {
    input {
      margin-bottom: ptr(25);
    }
  }

  .forAds {
    input[type="radio"]:checked:after {
      top: -3.2px;
    }
  }

  .transactionHours {
    color: #a1a1a1;

    .icon-clock {
      font-size: 11px;
      margin-right: 3px;
    }
  }
}

.forBecomeVerified {
  min-height: ptr(320) !important;
  margin: auto;

  .center {
    display: table-cell;
    vertical-align: middle;
    height: ptr(320);
    text-align: center;
    padding-left: 0;
    padding-right: 0;

    b {
      font-size: ptr(20);
    }

    img {
      width: ptr(100);
      height: ptr(100);
      border-radius: 50rem;
      border: 6px solid #b2b1b0;
      margin-bottom: ptr(25);
      object-fit: cover;
    }

    .icon-check {
      font-size: ptr(60);
      background: #66dd6e;
      color: #fff;
      padding: ptr(20) ptr(15) ptr(15) ptr(20);
      border-radius: 50rem;
    }
  }

  .bottom {
    margin-bottom: ptr(40);
  }

  .left {
    height: ptr(320);
  }
}

.relative {
  position: relative;
  margin-top: ptr(15);
}

/*99% completed*/

.completedCompany {
  font-size: ptr(15);
  border: 2px dashed transparent;
  border-color: #d0d2d3;
  margin-bottom: ptr(30);

  img {
    width: ptr(75);
    height: auto;
    padding-right: ptr(10);
  }
}

.rdw-dropdown-optionwrapper {
  width: 100%;
}

.loader-result {
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.loader-result:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ff6f00;
  border-color: #ff6f00 transparent #ff6f00 transparent;
  animation: loader-result 1.2s linear infinite;
}

@keyframes loader-result {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 100%;
  display: block;
  position: relative;

  .progress-value {
    font-size: 11px;
    transform: translate(-50%, -50%);
    display: block;
    left: 50%;
    position: absolute;
    top: 43%;
  }
}

.companydetails {
  flex-direction: column;
  line-height: 1.2;

  .label {
    padding-bottom: ptr(15) !important;
  }

  .listnamelabel {
    padding-left: ptr(20);

    li {
      padding-top: 0 !important;
    }
  }
}

.geo {
  .panel-toggle {
    width: 100%;
    border: 0;
    text-align: left;
    color: #7b7b7b;
    padding: 0.3125rem 1.5rem;
    position: relative;
    background: transparent;
  }

  .panel-toggle {
    &:after {
      transition: all 500ms;
      margin-left: 4px;
      vertical-align: 4px;
      content: "";
      border-top: 5px solid;
      border-right: 5px solid transparent;
      border-bottom: 0;
      border-left: 5px solid transparent;
      top: 50%;
      left: 0.3125rem;
      position: absolute;
      transform: rotate(-90deg);
    }
  }
}

.active-panel {
  button.panel-toggle {
    &:after {
      transform: rotate(0deg);
    }
  }
}

.labelSelected {
  ul {
    @extend .d-flex;
    flex-wrap: wrap;

    li {
      border: 1px solid #a9a9a9;
      padding: ptr(5) ptr(10) ptr(5);
      border-radius: 4px;
      box-shadow: 1px 1px 1px 1px #e7e7e7;
      margin-right: ptr(10);
      margin-bottom: ptr(10);
    }
  }
}

.containerMemberActivity {
  border: 1px solid #1897c8;
  padding: 5px;
  border-radius: 4px;

  .editItem {
    background: #ffffff;
    padding: ptr(15) ptr(40);
    border-radius: 4px;
    left: 45% !important;
    top: 30% !important;
  }
}

//Notification
.notif,
.allNotif {
  position: relative;

  .unread {
    font-weight: bold;
    background-color: #e7e7e7;

    &::after {
      content: "•";
      position: absolute;
      right: ptr(17);
      top: ptr(13);
      color: $orange;
      font-size: ptr(40);
    }
  }

  .submenu {
    min-height: ptr(500);
    height: ptr(560) !important;
    overflow: auto;
    width: ptr(350) !important;
    border-radius: ptr(7);

    .type {
      margin-right: ptr(10);
      width: ptr(260);
    }

    div {
      display: contents;

      .ico {
        width: ptr(20);
      }
    }

    a {
      border: none !important;
    }
  }

  .defaultComment {
    font-size: ptr(14);
    font-weight: normal;
  }

  .seeAll {
    font-weight: bold;
  }

  .head_notif {
    @extend .d-flex;
    justify-content: space-between;

    .text_notif {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .all_notif {
    align-items: flex-start !important;
    flex-wrap: wrap;
    position: relative;

    &:hover {
      background: #f5f5f5;
      border-radius: 5px;
      margin: 5px;
    }

    .ico {
      background: #e7e7e7;
      min-width: ptr(35) !important;
      height: ptr(35);
      border-radius: ptr(50);
      position: relative;

      &:before {
        position: absolute;
        top: ptr(7);
        left: ptr(7);
      }
    }

    .user,
    .yourComment {
      color: $bleu;
    }

    .date {
      font-weight: normal;
      font-size: 14px;
      padding-left: 45px;
      padding-top: 4px;
      color: gray;
    }
  }
}

.noNotif {
  .head_notif {
    span {
      padding: 10px;
      cursor: no-drop;
    }
  }

  .txt {
    background-color: #f2f2f2;
    padding: 15px;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: auto;
    border-radius: 5px;
  }
}

.non-lu {
  position: absolute;
  top: 2px;
  right: -5px;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: $orange;
  border: 1px solid white;
  color: white;
  font-size: 11px;
  @extend .d-flex;
  align-items: center;
  justify-content: center;
}

.anchor {
  .tab-content {
    .tab-pane {
      @extend .d-block;
    }

    .fade:not(.show) {
      opacity: 1 !important;
    }
  }
}

.menuAnchor {
  .nav {
    border: none !important;
  }

  .nav-item {
    padding: ptr(10) 0;
    min-width: ptr(120);

    a {
      font-size: ptr(18);
      padding: 0.5rem 1.25rem;
      color: #888888;
      text-align: center;
      border-bottom: 4px solid transparent;
      border-radius: 0;

      &.active {
        font-weight: bold;
        color: #4f4f4f;
        border-color: #ff6600;
      }
    }
  }
}

.matchValue {
  border-radius: ptr(30);
  @extend .d-flex, .align-items-center, .justify-content-center;
  border: 1px solid;
  font-size: ptr(20);
  font-weight: bold;
  width: ptr(140);
  margin: 0 auto;

  &.m95 {
    background: transparent;
    border-color: #00ab4a !important;
    color: #00ab4a !important;

    &.locked {
      background: #00ab4a;
      border-color: #00ab4a !important;
      color: #fff !important;
    }
  }

  &.m90 {
    background: transparent;
    border-color: #58c255 !important;
    color: #58c255 !important;

    &.locked {
      background: #58c255;
      border-color: #58c255 !important;
      color: #fff !important;
    }
  }

  &.m80 {
    background: transparent;
    border-color: #6cc76a !important;
    color: #6cc76a !important;

    &.locked {
      background: #6cc76a;
      border-color: #6cc76a !important;
      color: #fff !important;
    }
  }

  &.m60 {
    background: transparent;
    border-color: #7a8288 !important;
    color: #7a8288 !important;

    &.locked {
      background: #7a8288;
      border-color: #7a8288 !important;
      color: #fff !important;
    }
  }

  &.m60 {
    background: transparent;
    border-color: #7a8288 !important;
    color: #7a8288 !important;

    &.locked {
      background: #7a8288;
      border-color: #7a8288 !important;
      color: #fff !important;
    }
  }

  &.mJoining {
    background: transparent;
    border-color: #e06661 !important;
    color: #e06661 !important;

    &.locked {
      background: #e06661;
      border-color: #e06661 !important;
      color: #fff !important;
    }
  }

  span.icon-nextbtn {
    font-size: ptr(10);
    margin: ptr(2) ptr(5) 0 0;
  }
}

.prof {
  border-bottom: 1px solid #c1c1c1;

  &.secondHeader {
    background-color: transparent;
    height: auto;

    .title {
      display: none;
    }

    &.fixedSecondHeader {
      background-color: #efedee;
      top: ptr(75);

      .container {
        background-color: #efedee;
      }

      .title {
        display: block;

        h5 {
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
}

.forAds {
  .radius {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ptr(20);
    min-height: ptr(100);

    h5 {
      font-weight: 600;
      margin: 0;
      width: 100%;
      // padding: 0 ptr(30);
    }

    small {
      color: gray;
    }

    .hidden {
      visibility: hidden;
    }
  }

  .payC {
    font-size: ptr(18);
    /*line-height: 1;*/
    margin: ptr(30) 0;

    a {
      color: $bleu;
    }
  }

  input[type="radio"] {
    position: relative;
    font-size: 15.5px;
    margin-right: 1rem;
  }

  input[type="radio"]:before {
    width: 16px;
    height: 16px;
    border-radius: 15px;
    top: -0.05rem;
    left: -1px;
    position: absolute;
    background-color: #ff6600;
    content: "";
    display: inline-block;
    visibility: visible;
  }

  input[type="radio"]:after {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: -3px;
    left: -0.5px;
    position: relative;
    background-color: #ffffff;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 0px solid $orange;
  }

  input[type="radio"]:checked:after {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: -3px;
    left: 0;
    position: relative;
    background-color: $orange;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid #fff;
  }
}

.selectMonth {
  .carousel-inner {
    height: 50px;

    h3 {
      font-size: ptr(16);
      font-weight: 600;
      margin: 10px auto;
    }
  }

  .carousel-caption {
    top: 0;
    color: #333;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .carousel-indicators {
    display: none;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-image: none;
  }

  .carousel-control-next-icon::after {
    content: "\2B9E";
    width: 10px;
    height: 10px;
    color: #333;
  }

  .carousel-control-prev-icon::after {
    content: "\2B9C";
    width: 10px;
    height: 10px;
    color: #333;
  }
}

.transaction {
  .sortedby {
    padding: 0;

    .triOreder {
      width: ptr(70);
    }
  }
}

.timeRange {
  margin-top: 30px;

  .date {
    align-items: center;

    input {
      border-color: transparent;
      width: ptr(96);
    }
  }
}

.cont {
  &.oem {
    padding: 0;
  }

  padding: ptr(20);

  .contact,
  .favorites {
    a {
      display: block;
      margin: ptr(15) auto;
      padding: ptr(9);
      border: none !important;
      min-height: auto !important;
    }
  }

  .favorites {
    a {
      padding: 2px 0 !important;
      border: 1px solid lightgray !important;
    }
  }

  .contact {
    a {
      background: #1897c8;
      font-weight: 600;
    }
  }
}

.pin {
  background: #eeeeee;

  .subCategory {
    span {
      background-color: #8a8a8d !important;
      color: #ffffff !important;
    }
  }

  .pinit {
    &:before {
      content: "\A";
      border-style: solid;
      border-width: 0 41px 43px 0;
      border-color: transparent #838383 transparent transparent;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .icon-pushpin {
    color: #fff;
    font-size: ptr(13);
    font-weight: bold;
    padding: 10px;
    position: absolute;
    right: -6px;
    top: -5px;
    transform: rotate(-45deg);
  }
}

.addResource {
  color: #1897c8 !important;
  font-weight: 600 !important;
  height: auto !important;
  border-top: none !important;
  padding: ptr(10) ptr(15) !important;
  text-decoration: underline;
}

.titlePost {
  display: inline-block;
  cursor: pointer;

  .title {
    &:hover {
      text-decoration: underline;

      .article {
        text-decoration: none;
      }
    }
  }
}

.textElement,
.topicSubject,
.textArticle {
  ul {
    padding-left: ptr(35);

    li {
      list-style-type: initial;
    }
  }
}

.errorMessage {
  $alertStyles: (
    warning: (
      theme: #fff9f0,
      icon: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/14179/warning.svg",
      darkTheme: #ffc848,
    ),
  );

  .alert {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    max-width: 100%;
    border-radius: 4px;
    background: #ccc;
    border: 1px solid #777;
    display: flex;
    margin-bottom: 20px;

    &:before {
      content: "";
      width: 80px;
      position: relative;
      z-index: 999;
      background-color: #777;
      background-repeat: no-repeat;
      background-position: center 16px;
      background-size: 30px;
    }
  }

  .alert-content {
    padding: 20px;
  }

  .alert-title {
    margin: 0;
    margin-bottom: 5px;
    font-size: 18px;
    padding: 0;
  }

  .alert-body {
    p {
      margin: 0;
      font-size: 16px;
    }
  }

  // Loop for styling
  @each $state, $property in $alertStyles {
    $theme: map-get($property, theme);
    $darkTheme: map-get($property, darkTheme);
    $icon: map-get($property, icon);

    .alert-#{$state} {
      background-color: $theme;
      border-color: $darkTheme;
      z-index: 0;
      position: relative;

      &:before {
        background-color: $darkTheme;
        background-image: url($icon);
      }

      .alert-title {
        color: $darkTheme;
      }
    }
  }
}

.pointer {
  cursor: pointer !important;
}

.disabled-btn-premium,
.disabled-btn-purchase {
  cursor: not-allowed !important;
}

.noEvent {
  color: #1897c8;
  font-weight: 600;
  padding: 0;
  display: block;
}

.listOfBlacklist {
  .title {
    a {
      .bulle {
        position: absolute;
        margin-top: 23px;
        margin-left: -255px;
        color: #333;
        background: #fff;
        padding: 15px;
        border-radius: 3px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
        transform: scale(0) rotate(-12deg);
        opacity: 0;
      }

      &:hover,
      &:focus {
        .bulle {
          transform: scale(1) rotate(0);
          opacity: 1;
        }
      }
    }
  }
}

.btn-devinsider {
  &.grisee {
    background: #747474;
    border-color: #747474 !important;
    color: #333 !important;
  }
}

.termsLink {
  a {
    color: $bleu !important;
    font-weight: 600;
  }
}

.programType {
  padding-left: ptr(5);
  position: absolute;
  top: ptr(15);
}

.pict {
  position: absolute !important;
  top: ptr(125);
}

.blurText {
  filter: blur(3px);
  margin: 15px 0;
}

.solvebusiness {
  padding-top: ptr(50);

  &.helpInv {
    background: #ffffff;

    ul {
      justify-content: space-between !important;
      padding: ptr(20) ptr(60);
      height: ptr(400) !important;

      @media (max-width: 768px) {
        padding: ptr(10) !important;
        height: auto !important;
      }
    }

    li {
      width: 33%;

      p {
        font-weight: 600;
        margin-top: ptr(15);
      }

      img {
        width: ptr(90);
        height: ptr(90);
      }
    }
  }

  .title {
    /*  font-size: ptr(55); */
    font-size: 48px;
    font-weight: bold;
    margin-bottom: ptr(16);
    text-align: center;
    color: #163a64;
  }

  .listLabelLink {
    width: 100%;
    text-align: center;
    margin-top: ptr(50);
    display: flex;
    /* display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        place-items: center; */
    /*  @extend .d-flex; */
    @extend .flex-wrap;
    @extend .align-items-center;
    @extend .justify-content-center;

    li {
      height: ptr(130);
      width: 25%;
      margin: 0;
      padding: 0;

      @media (max-width: 768px) {
        width: 50% !important;
      }
      p {
        color: #163a64;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }

  .joinConversationLink {
    color: #fff;
    background-color: $orange;
    //border:2px solid $orange;
    text-align: center;
    //border-radius: ptr(50);
    font-size: 17px;
    display: table;
    font-weight: 600;
    margin: 0 auto;
    padding: 10px 50px;

    &:hover {
      background-color: rgba($color: $orange, $alpha: 0.65);
    }
  }

  @media (max-width: 1024px) {
    .listLabelLink {
      flex-wrap: wrap !important;

      li {
        width: 50%;
      }
    }
  }
}

.leaveProgress {
  button {
    background: none;
    color: #000000;
    width: 50%;

    &:hover {
      color: #000;
    }
  }

  a {
    width: 50%;
  }
}

.fontNormal {
  font-weight: normal !important;
}

.hidden {
  /*visibility: hidden;*/
  display: none;
}

.visible {
  visibility: visible;
}

.emailConfirm {
  .box-content {
    font-size: ptr(15);
    display: block;
    margin: ptr(80) auto;
    width: ptr(460);
    height: ptr(340);
    box-shadow: 1px 1px 3px 0px grey;
    padding: ptr(40);

    .btn-devinsider {
      padding: ptr(10) ptr(25) !important;
    }

    .title {
      font-size: ptr(16);
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: ptr(20);
    }

    .custom-checkbox {
      background: #f2f2f2;
      padding: ptr(20) ptr(40);
      border-radius: 7px;
      margin-bottom: ptr(100);
    }
  }

  .textConfirm {
    a {
      color: #1897c8 !important;
    }
  }

  .container {
    max-width: 40rem;
  }
}

.confirmUnsubscribe {
  p {
    background-color: #e6ffdd;
    padding: 20px;
    text-align: center;
    width: ptr(650);
    border-radius: 10px;
    border: solid 1px #b7e7a7;
    display: block;
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {
  .iframe {
    iframe {
      width: 100%;
    }
  }
}

.banner-cookies {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: #163a64;
  max-width: 40%;
  padding: 20px;
  text-align: right;
  color: #fff;

  .btn.btn-devinsider {
    float: right;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.videoforjoin {
  width: 100% !important;
}

.format-wysiwyg {
  ol {
    li {
      list-style: inherit !important;
    }
  }

  ul {
    li {
      list-style: initial !important;
    }
  }

  img {
    width: 100% !important;
  }
}

.miniBold {
  font-weight: 600;
  font-size: 15px;
}

.wrap {
  flex-wrap: wrap;
}

.description_settings {
  position: relative;
  bottom: ptr(30);
  font-size: ptr(15);
}

.targetInvestorrange {
  .rc-slider-mark span.rc-slider-mark-text:nth-child(1) {
    transform: translateX(-30%) !important;
  }

  .rc-slider-mark .rc-slider-mark-text:first-child {
    width: auto !important;
  }
}

.font-weight-600 {
  font-weight: 600;
}

.visibility {
  font-size: ptr(14);
  margin: ptr(15) 0;
}

.forFinancing {
  height: ptr(100);

  .alternativeFinancing {
    width: ptr(81);
    height: auto;
    margin-top: ptr(25);
  }
}

.alternative {
  a {
    justify-content: center !important;
  }
}

.menuCompanyTab {
  &.p-locked {
    display: none;
  }
}

.findOutMore {
  /*Portfolio*/
  span {
    text-transform: none !important;
    font-weight: 600;
    color: #646f7b;
    flex-direction: row !important;
    text-decoration: underline;
    font-size: ptr(14) !important;
    height: ptr(20) !important;
  }

  img {
    width: ptr(10);
    margin: ptr(8);
  }
}

.p-wysiwyg {
  position: absolute;
  right: ptr(20);
  top: ptr(72);
  display: flex;
  color: #4f4f4f;
  text-transform: uppercase;
  font-weight: 600;

  @media (max-width: 768px) {
    top: ptr(100);
  }
}

.forPreview {
  .modal-body {
    img {
      width: ptr(300) !important;
      margin: 0 auto;
      display: block;
    }

    ul,
    li {
      list-style: revert;

      ul {
        padding-left: ptr(10);
      }
    }
  }
}

.forUnpublish {
  .modal-body {
    img {
      width: ptr(100);
      height: auto;
      display: block;
      margin: 0 auto;
    }

    .text-center {
      font-weight: 600;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.btnUnpublish {
  position: absolute;
  right: 0;
  top: ptr(15);
  border: 1px solid #b3b3b3;
  padding: ptr(5) ptr(10);
  background: #fff;
  cursor: pointer;
}

.strategycontainer,
.overviewcontainer,
.financialcontainer,
.pitchcontainer {
  white-space: pre-wrap;
}

/*Banner*/
.bannerAds {
  //display: none;
  /* à commenter ou supprimer rehefa alefa ny banner/ads */
  /*margin: ptr(10) 0 ptr(40) 0;*/

  img {
    width: ptr(300);
    height: ptr(300);
    object-fit: cover;
    margin-bottom: ptr(20);
  }

  &.dashboard {
    /*margin-top: 0;*/

    img {
      width: ptr(245);
      height: ptr(245);
      margin-bottom: ptr(20);
    }
  }

  &.rectangle {
    /*margin-bottom: ptr(20);*/

    img {
      width: ptr(815);
      height: ptr(135);
      object-fit: cover;
      margin-bottom: ptr(20);
    }
  }
}

.small {
  &.cdt-dbt {
    font-weight: 400;
    margin-bottom: 0;
  }

  &.color-light-gray {
    color: #999999;
  }

  &.line {
    text-decoration: line-through;
  }

  &.bold {
    font-weight: 600 !important;
  }
}

.color-orange {
  color: $orange;
}

.color-grey {
  color: #999999;
}

.color-black {
  color: #000;
}

.color-blue {
  color: $bleu;
}

.text-align-right {
  text-align: right;
}

.font-medium {
  font-size: ptr(18);
}

.w-375 {
  width: ptr(375);

  .btn {
    width: 90%;
  }
}

.plan_details {
  &.invest {
    button {
      top: ptr(-85) !important;
    }
  }
}

.isvAcc {
  button {
    bottom: ptr(120) !important;

    &.active {
      top: ptr(-120) !important;
    }
  }
}

.programCompanyName {
  /* margin-left: ptr(20);*/
  font-size: inherit;
  font-weight: normal;
  text-overflow: "...";
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.center-text {
  text-align: center;
}

.program {
  .role {
    .remInput {
      border: 1px solid #c1c1c1;
      padding: ptr(6) ptr(6) ptr(6) ptr(10);
    }
  }
}

.short-height {
  height: ptr(200);
}

.read_add,
.read_add:hover {
  color: $orange;
}

.company-title a {
  cursor: default;
}

.disabled-send-msg-btn {
  pointer-events: all !important;
  opacity: 1;
  background-color: #7e7f80 !important;
  cursor: not-allowed !important;
}

.disabled-send-msg-btn:hover {
  color: white !important;
}

.dynamicMenu .menuDynamic .item.hidden {
  display: none !important;
}

.disable-unlock {
  cursor: not-allowed !important;
}

.process-unlock-blocked {
  align-items: center;
  display: flex;
}

.process-blocked-description {
  padding-left: 10px;
}

section.discussion {
  min-height: 70vh;
}

.disable-click-name {
  cursor: auto !important;
}

.link-add-photo ~ p {
  margin-top: 0.5rem;
}

.post-preview {
  background: #999999;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin-bottom: 2rem;
  z-index: 10;

  button {
    background: #f7f7f7;
    border: none;
    border-radius: 3px;
    padding: 0.5rem 4rem;
    font-weight: 600;
    color: #333333;
    font-size: 18px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }

  // &::before {
  //   content: "";
  //   background: #222a;
  //   width: 100%;
  //   height: 50px;
  //   position: absolute;
  //   top: -50px;
  // }

  & ~ div {
    ul {
      a:hover {
        text-decoration: none;
      }
    }

    * {
      cursor: default;
    }
  }
}

.expert-getting-started .start-expert {
  min-height: 70vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#desactivate-subscription {
  .modal-title {
    color: #656565;
  }

  h3.subtitle {
    font-size: 16px;
    font-weight: 400;
    color: #888;
  }

  .modal-content {
    padding-bottom: 3rem;
  }

  .title-cancel {
    text-align: center;
    margin: 0 auto;
    color: #444;
    font-size: 18px;
    font-weight: 600;
  }

  .desactivepopup {
    .programtodeactivate {
      background: none;
      border: 2px solid #ddd;
      padding: 1rem;
    }

    .manage-text p {
      text-align: center;
      margin: 0 auto;
      font-size: 18px;
      width: 100%;
      font-weight: 600;
    }
  }

  .btn-devinsider:hover,
  .btn-devinsider:focus {
    background: #ff6600;
  }
}

.title-cancel.title {
  font-size: 18px;
  font-weight: 600;
}

.fade.modal-dialog.modal.show {
  margin: 0 auto;
}

.classFavorite {
  color: #ff6600 !important;
  background-color: transparent !important;
  font-size: 0 !important;
  cursor: auto;

  &::after {
    content: "Added to favorites";
    font-size: ptr(14);
  }

  &:hover {
    box-shadow: none !important;
  }
}

.css-1wy0on6 {
  display: none !important;
}

.removeAttachement {
  display: flex;
  background: none;
  border: none;

  button {
    background: none;
    border: none;
    padding: 0 !important;
    top: -3px;
    position: relative;
    right: 40px;

    .icon-close {
      background: red;
      color: #fff;
      border-radius: 2px;
    }

    .txt {
      display: none;
    }

    &:hover {
      right: 62px;
      top: 3px;

      .txt {
        display: block;
        position: relative;
        left: 62px;
        font-size: 14px;
        font-weight: normal;
        border: 1px solid;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.report {
  .dropdown-item {
    margin-left: -115px;
    margin-top: 25px;
  }
}

.rdw-image-modal-size {
  display: none !important;
}

.paiementBottom {
  h5 {
    font-size: ptr(18);
    font-weight: 600;
  }
}

.checkCard {
  .ml-0.form-group {
    margin-left: 2rem !important;
  }

  &.events {
    .ml-0.form-group {
      margin-left: 1.3rem !important;
    }
  }

  b:first-child {
    margin-left: 1rem;
  }
}
section.main.sponsors .container {
  min-height: 100vh;
}

#currencies .currencies__indicators {
  display: block !important;
}

#currencies {
  margin-left: auto !important;
}

#currencies .currencies__indicator-separator {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  height: 19px;
  box-sizing: border-box;
  display: inline-block;
  top: 0;
  bottom: 0;
  position: absolute;
}
.searchWord {
  &.w25 {
    width: 25rem !important;
  }
}
