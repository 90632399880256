.grid-completed {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-areas:
    ". header-title-complete ."
    ". txt-info-complete .";
}

.header-title-complete {
  grid-area: header-title-complete;
  text-align: center;
  position: relative;
  height: 8rem;
  width: 100%;
  border-bottom: 2px #dbdbdb solid;
}
.txt-center-complete {
  position: absolute;
  top: 25%;
  width: 100% !important;
  transform: translateY(-50%);
  transform: translateX(-50%);
  font-size: 1.8rem;
  color: #616161;
}
.txt-info-complete {
  grid-area: txt-info-complete;
}

.label-email-completed {
  color: #ff6e0e;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  font-family: "Source Sans Pro";
}

.label-description-completed {
  color: #616161;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Source Sans Pro";
}
.label-resend-completed {
  color: #85c8e2;
  text-align: center;
  cursor: pointer;
  font-family: "Source Sans Pro";
}

.txt-info-complete div {
  text-align: center;
  font-weight: 600;
}

.container-description-completed {
  padding: 2rem 0px;
}

.container-resend-completed {
  border-bottom: 2px #dbdbdb solid;
  padding-bottom: 1rem;
  cursor: pointer;
}

.container-email-completed {
  padding-top: 1rem;
  font-size: medium;
}
.error-occurred-complete {
  color: red;
  font-weight: 400;
  font-family: "Source Sans Pro";
}
