    #company-page.modal-dialog {
      position: fixed;
      top:0;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 1050;
      input:focus, .numberphone .phonenumber:focus~.selectFlag{
        outline: none!important;
        border: none;
      }
      textarea:focus{
          box-shadow: 0 0 0 0.2rem rgba(255,102,0,.2);
          border:none;
          outline: none!important;
      }
      .modal-footer button{
       font-size: 1rem;
       border-radius: 2px !important;
       min-width: 100px;
       max-width: 100px;

     }

     .cancelbtn{
      a{

        color: #787878; 
      }
      background: #e7e7e7;
      border: 1px solid #cecece;
      min-width: 5.9375rem;
      font-size: 1rem;
      font-weight: 600;
      margin-right: 1rem;

    }

    .modal-header{
     background-color: #e5e5e5;
     position: relative;
     height: 3.75rem;
     text-align: center;
     position: relative;
     .close{
      position: absolute;
      background: url(../../../../../resources/images/close.svg) center center/100% no-repeat;
      object-fit:contain;
      background-size: 70% auto;
      top: 1.5rem;
      right: 2rem;
      width: 6px;
      height: 6px;
      display: flex;
      align-items:center;
      justify-content: center;
      opacity: 1;
      cursor: pointer;
     }
     .modal-title{
      text-align: center;
      color: #656565;
      width: 100%;
      font-size: 18px;
      font-weight: 700;

    }
  }

  .modal-footer{
    border-top: 0;
  }

  textarea{

    height: 80px;
    width: 100%;
    border: 1px solid #ced4da;
  }

  .check-body{

    .check{
    position: relative;
    background: url(../../../../../resources/images/check-circle.svg) center center/100% no-repeat;
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }

  h4{
    font-weight: bold;
    font-size: 21px;
  }

  p{

    font-size: 16px;
  }

  }

  .modal-footer.done{

    height: 250px;
  }


}
