@import "../../../style.scss";
.btnAndConfirm{
    position: relative;
    margin: 0;
    padding: ptr(16) 0;
    position: relative;
}
.btn-confirm-wrap{
    position: absolute;
    height: ptr(100);
    width: 100%;
    background: #fff;
    top: 0;
    box-shadow: 0 0 10px rgba($color:#000, $alpha:0.1);
    .txt{
        height: ptr(50);
        @extend .d-flex, .align-items-center, .justify-content-center ;
    }
    .btnCtt{
        height: ptr(50);
        border-top: 1px solid #e3e2e2;
        button{
            width:50%;
            height: ptr(50);
            border: none;
            background: #fff;
            font-weight: bold;
            color: $gris;
            text-transform: uppercase;
            cursor: pointer;
            font-size: ptr(12);
            &.btn-confirm-ok{
                border-left: 1px solid #e3e2e2;
                color: $orange;
            }
        }
    }
}
ul.feedAffiliated{
    .btnAndConfirm{
        width: ptr(250);
        @extend .d-flex, .justify-content-end, .align-items-center;
        .btn-confirm-wrap{
            left: 25%;
        }
    }  
}