.custom-ui.alert-info{

	display: flex;
	flex-direction: column;
	justify-content: center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	background: #fff;
	width: auto;

	.pop-head{
	background: #e5e5e5;
	position: relative;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content:center;
	font-weight: 600;
	p{
		margin-bottom: 0;
		color: #6d6d6d;
		font-weight: 600;
	}

	.close{
	
		position: absolute;
		right: 10px;
		top:14px;
      background: url(../../../resources/images/close.svg) center center/100% no-repeat;
      object-fit:contain;
      background-size: 70% auto;
      top: 0.8rem;
      right: 1rem;
      width: 30px;
      height: 30px;
      display: flex;
      align-items:center;
      justify-content: center;
      opacity: 1;
      cursor: pointer;

	}
}

.modal-body {

	display: flex;
	flex-direction: column;
	justify-content:center;
	align-items: center;
	padding: 1rem 2rem;

	svg{
	color: #8a8a8d;
}

.info-missing{
    font-weight: 700;
    color: #222;
    margin-bottom: 1rem;
}

p{

	color: #404040;
	text-align: center;
	font-weight: 500;
	margin-bottom: 0;
}

}

button{
	margin-top: 5rem;
	background:#ff6600;
	color: #fff;
	padding: 0.3rem 2rem;
	border: none;	
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 3rem;
}

}

#react-confirm-alert .react-confirm-alert-overlay{

	    background: rgba(60, 60, 60, 0.9);
}