.guest-action {
    width: 22.5rem;
    max-width: 22.5rem;
    box-sizing: border-box;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    .editDoneIsv {
        width: 17.375rem;
    }
    .guest-company-logo {
        border: 1px solid #c1c1c1;
        padding: 0.9375rem;
        width: 17.375rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            margin-right: 0.5rem;
        }
        .editItem {
            position: relative !important;
        }
    }
}