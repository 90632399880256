*button:focus {
    outline: none;
}

input[type="text"],
input[type="password"] {
    &:focus-visible {
        border: $orange;
        outline: none;
    }
}

.btn-next-container {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;

    .loadIcon {}

    label {
        margin: 0;
        font-weight: normal;
    }
}

.required {
    label {
        position: relative;

        &:after {
            position: absolute;
            top: -1px;
            right: -10px;
            content: "*";
            color: $orange;
            font-weight: bold;
        }
    }
}

.aideContent {
    label {
        margin-right: ptr(15);
    }

    span {
        cursor: pointer;
    }
}

.geographicList {
    border: 1px solid #bdbdbd;
    width: 100%;
    margin: 0;
    min-height: ptr(20);

    .accordion {
        .collapse.show~.card-header {
            background-color: red;
        }
    }
}

.custom-control.custom-checkbox.orange {
    .custom-control-input {
        &:checked {
            &~.custom-control-label:before {
                background-color: $orange !important;
                border-color: $orange !important;
            }
        }
    }

    .custom-control-label {
        &:before {
            border-radius: 0;
        }
    }
}

.geographicList,
.contentCheckboxsolution,
.forStep5custom {
    .custom-control-input {
        &:checked {
            &~.custom-control-label:before {
                background-color: $orange;
                border-color: $orange;
            }
        }
    }

    .custom-control-label {
        &:before {
            border-radius: 0;
        }
    }

    .panel-wrap {
        .panel-header {
            button.panel-toggle {
                width: 100%;
                border: 0;
                text-align: left;
                color: #7b7b7b;
                padding: ptr(5) ptr(24);
                position: relative;

                &:after {
                    transition: all 500ms;
                    margin-left: 4px;
                    vertical-align: 4px;
                    content: "";
                    border-top: 5px solid;
                    border-right: 5px solid transparent;
                    border-bottom: 0;
                    border-left: 5px solid transparent;
                    top: 50%;
                    left: ptr(5);
                    position: absolute;
                    transform: rotate(-90deg);
                }

                &.selectedItem {
                    background-color: #e5e6e6;
                }
            }

            .colored {
                background-color: $orange !important;
                color: white !important;

                &.round {
                    background-color: #e5e6e6 !important;
                    color: $gris !important;
                }
            }
        }

        .panel-body {
            margin: 0;
            padding: ptr(10) ptr(24);

            .custom-checkbox {
                margin: 0 0 ptr(8) 0;
            }
        }

        &.active-panel {
            button.panel-toggle {
                &:after {
                    transform: rotate(0deg);
                }
            }
        }

        &.falseSelect {
            button.panel-toggle {
                background-color: #fff;

                &:after {
                    left: auto;
                    right: ptr(15);
                    transform: rotate(0deg);
                }
            }

            &.active-panel {
                button.panel-toggle {
                    border-bottom: 1px solid #bdbdbd;

                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &.lastRadio {
        @extend .d-flex;

        .checkcontainer {
            margin: 0;
            padding: ptr(10) ptr(40) 0 0;

            label {

                &:after,
                &:before {
                    border-radius: ptr(20);
                }
            }
        }
    }

    &.createCompanyGeographic {
        .accordion {
            button.panel-toggle {
                background-color: #fff;
            }

            &.selectedItem {
                background-color: #efefef;
            }
        }
    }
}

.contentCheckboxsolution {
    .panel-wrap {
        margin: 0 0 ptr(10) 0;

        .panel-header {
            button.panel-toggle {
                background: #f2f2f3;
                height: ptr(36);

                &:after {
                    border: 0;
                    content: "";
                    right: ptr(20);
                    left: auto;
                    top: calc(50% - 1px);
                    height: 2px;
                    width: ptr(14);
                    background: $gris;
                }

                &:before {
                    width: ptr(14);
                    height: ptr(2);
                    content: "";
                    position: absolute;
                    background-color: $gris;
                    right: ptr(20);
                    top: calc(50% - 1px);
                }

                &.noicon {

                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

.solutionChecked {
    width: 100%;
    @extend .d-flex,
    .flex-wrap;
    margin: 0;
    padding: ptr(10) 0 ptr(18) 0;

    .item {
        @extend .d-flex,
        .align-items-center;
        background: $orange;
        color: #fff;
        border-radius: ptr(30);
        padding: 0 ptr(6);
        font-size: ptr(14);
        margin: 0 ptr(8) ptr(8) 0;

        span {
            padding-left: ptr(6);
            cursor: pointer;
        }
    }
}

.contentCheckboxsolution {
    width: 100%;

    .flex-checkbox {
        @extend .d-flex,
        .flex-wrap;
        padding: 0 0 ptr(24) 0;

        .custom-control {
            margin: 0 1.3% ptr(10) 0;
            font-size: ptr(15);
            width: 32%;

            &:last-child {
                margin-right: 0;
            }
        }

        @media (max-width: 768px) {
            flex-direction: column;

            .custom-control {
                margin: 0 0 ptr(10) ptr(16);
                width: 100%;
            }
        }
    }
}

.contentTargetIndustry {
    @extend .d-flex,
    .flex-wrap;

    .col4,
    .col3 {
        width: 32%;
        margin: 0 1.95% 0 0;

        &:nth-child(3n + 3) {
            margin-right: 0;
        }
    }

    .col3 {
        @extend .d-flex,
        .flex-column;
    }

    .custom-control {
        width: 100%;
        padding: 0;

        label {
            width: 100%;
            padding: 0;
            margin: 0 0 ptr(10);
            background-color: #f2f2f3;
            padding: ptr(10) ptr(16);
            border-radius: ptr(3);
            cursor: pointer;
            font-weight: normal;

            &:after,
            &:before {
                display: none;
            }
        }

        input:checked~label {
            background-color: $orange;
            color: #fff;
        }
    }

    &.smallLabel {
        .col4 {
            label {
                font-size: ptr(15) !important;
            }
        }
    }

    @media (max-width: 768px) {
        .col4 {
            width: 100%;
            margin-right: 0;
        }
    }
}

.orangeselect {
    appearance: none;
    background: url(../../images/selectbg.svg) center right 5px no-repeat;
    background-size: auto 25px;
    height: ptr(30);
    border-radius: ptr(3);
    padding: 0 ptr(30) 0 ptr(10);
    border: 1px solid #c4c4c4;
    color: #666666;
    font-size: ptr(15);
    width: 100%;
}

.smallIcon {
    *[class*="icon-"] {
        font-size: ptr(10);
        color: #888888;
    }
}

.descCompany {
    label {
        display: block;
    }

    textarea {
        width: 100%;
        height: ptr(170);
        resize: none;
        border: 1px solid #c1c1c1;
        border-radius: 2px;
        padding: ptr(16);
    }

    .caractercount {
        display: block;
        text-align: right;
        color: #6d6d6d;
    }
}

.workMail {
    .error-red {
        margin-left: ptr(5);
    }
}

.getstartedContainer,
.partener {

    .error-red,
    .txt-error {
        font-size: 16px;
    }

    label {
        &.labelForEmailgetStarted {
            position: relative;
        }

        &.safeMail {
            .mailcheckOK {
                @extend .d-inline;
            }
        }

        .container-error {
            // @extend .d-inline
        }
    }

    .mailcheckOK {
        font-size: ptr(16);
        color: #fff;
        padding: ptr(3);
        background-color: #4caf50;
        border-radius: ptr(30);
        margin: 0 ptr(5) 0 0;
        /*@extend .d-none;*/
    }

    .containerBtnBtn {
        @extend .py-0;

        .btn-next-container {
            padding: 0;

            label {
                margin: 0;
                font-weight: normal;
            }
        }
    }
}

.linkedintext {
    @extend .d-flex,
    .align-items-center;

    input {
        margin: 0 0 0 ptr(10);
    }
}

.numberphone {
    position: relative;

    .selectFlag {
        border: 1px solid #ced4da;
        height: ptr(38);
        border-radius: 5px;

        button {
            border: none;
            width: ptr(48);
            padding: 0;
            height: ptr(38);
        }

        &:focus-within {
            border-color: $orange;
            box-shadow: 0 0 0 0.2rem rgba(255, 102, 0, 0.2);
        }
    }

    .phonenumber {
        position: absolute;
        top: 0;
        left: 54px;
        height: 36px;
        width: calc(100% - 60px);
        border: 0;
        background-color: transparent;
        z-index: 2;

        &:focus {
            outline: none;

            &~.selectFlag {
                border-color: $orange;
                box-shadow: 0 0 0 0.2rem rgba(255, 102, 0, 0.2);
            }
        }
    }
}

.multipleoptionPartner.btn {
    span {
        width: 95% !important;
    }

    color: #495057;
    font-size: ptr(16);
    background-color: #fff;
    border: 1px solid #ced4da;
    font-weight: normal;
    height: ptr(40);

    &:after {
        margin-left: 0rem;
        border-top: 7px solid #7f7f7f;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
    }
}

.lastStepInvestorRadio {
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: ptr(3);
    padding: ptr(20) ptr(30);
    margin: 0 0 ptr(30) 0;

    label {
        padding: 0 0 0 ptr(20);

        &:before {
            left: ptr(-10);
            top: 20px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(222, 222, 222, 1) 100%);
        }

        &:after {
            top: 24px;
            left: -6px;
        }
    }

    &.custom-radio .custom-control-input:checked~.custom-control-label::after {
        background-image: none;
        background-color: $orange;
        width: ptr(8);
        height: ptr(8);
        border-radius: 10px;
    }

    .custom-control-input:checked~.custom-control-label::before {
        background-color: transparent;
        border: #adb5bd solid 1px;
    }
}

.targetInvestorrange {
    .rc-slider-mark {
        .rc-slider-mark-text {
            color: $gris;
            font-size: ptr(16);
            width: ptr(50);

            &:last-child {
                left: calc(100% - 20px) !important;
                width: 100%;
                font-size: ptr(12);
            }

            &:first-child {
                left: ptr(15) !important;
                width: 100%;
                font-size: ptr(12);
            }
        }
    }

    .rc-slider-track {
        background-color: #4f4f4f;
    }

    .rc-slider-handle {
        border: 2px solid #4f4f4f;
        width: ptr(20);
        height: ptr(20);
        border-radius: ptr(20);
        top: ptr(2);

        &:hover,
        &:focus {
            border: 2px solid #4f4f4f;
        }
    }

    .rc-slider-dot {
        border: 0;
        background-color: #e9e9e9;
        width: 2px;
        bottom: 0;
        border-radius: 0;

        &-active {
            background-color: #4f4f4f;
        }

        &:last-child,
        &:first-child {
            display: none;
        }
    }

    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
        background-color: #4f4f4f;
        border-color: #4f4f4f;
        box-shadow: none;
    }
}

.selectalloverstyle {
    padding: 0 0 0 ptr(16);
}

.partnershipoption {
    .dropdown {
        width: 49%;
    }

    .dropdown-menu {
        button.actions-btn {
            display: none;
        }

        label.form-check-label {
            font-size: ptr(16);
            font-weight: normal;

            &:after {
                @extend .d-none;
            }
        }
    }
}

.loginForm {
    .form-group {
        margin: ptr(32) 0 0 0;

        &.passwordContent {
            position: relative;

            .showPassWord {
                position: absolute;
                font-size: ptr(14);
                color: #1897c8;
                right: ptr(12);
                top: ptr(8);
                cursor: pointer;
            }
        }
    }

    .rememberMe {
        margin: ptr(14) 0 0 0;
        @extend .d-flex;
        @extend .justify-content-between;

        a {
            color: $orange;
        }
    }

    .loginbtncontent {
        margin: 0;
        padding: ptr(54) 0;
        @extend .d-flex;
        @extend .justify-content-center;
    }

    input[type="text"],
    input[type="password"] {
        &::placeholder {
            color: #bcbbbb;
        }

        width: 100%;
        border: 1px solid #bdbdbd;
        border-radius: ptr(4);
        height: ptr(40);
        padding: 0 0 0 ptr(12);

        &:focus {
            border-color: $orange;
            outline: none;
            box-shadow: 0 0 0 0.2rem rgba($color: $orange, $alpha: 0.2);
        }
    }
}

.menuDevinsider {
    transition: all 500ms;

    @media (max-width: 1024px) {
        padding: 0;
        position: absolute;
        right: ptr(15);
        top: ptr(2);

        .menuAnimation {
            position: relative;
            width: ptr(40);
            height: ptr(40);

            span,
            &:before,
            &:after {
                position: absolute;
                width: 100%;
                height: ptr(2);
                background-color: #fff;
                border-radius: ptr(2);
                left: 0;
                transition: all 500ms;
            }

            span {
                top: calc(50% - 1px);
            }

            &:before {
                content: "";
                top: ptr(5);
            }

            &:after {
                content: "";
                bottom: ptr(5);
            }
        }

        &[aria-expanded="true"] {
            transform: rotate(225deg);

            span {
                top: 13%;
                left: 11%;
                width: 80%;
                height: 80%;
                border-radius: 100%;
            }

            :before {
                width: 50%;
                transform: rotate(90deg);
                top: 50%;
                left: 25%;
                background-color: $gris;
                z-index: 10;
            }

            :after {
                width: 50%;
                height: ptr(2);
                left: 25%;
                top: 50%;
                background-color: $gris;
            }
        }
    }
}

.form-control:focus,
.custom-select:focus {
    border-color: $orange;
    box-shadow: 0 0 0 0.2rem rgba(255, 102, 0, 0.2);
}

.btn-devinsider {
    border-radius: ptr(2.79);
    text-align: center;
    color: #fff;
    background-color: $orange;
    font-size: ptr(16);
    min-height: ptr(45.46);
    font-weight: normal;

    &.save {
        border: 1px solid $orange !important;
    }

    &.large {
        width: 100%;
    }

    &.medium {
        width: 100%;
        max-width: ptr(450);
        margin: 0 auto;
        display: table;
    }

    &:hover {
        color: #fff;
    }

    &.btn {
        border-radius: ptr(2.79);
    }
}

.contentBorder {
    label {
        font-weight: 600;
    }

    .custom-checkbox {
        label {
            font-weight: normal;
        }
    }

    .form-check {
        label {
            font-weight: 300;

            a {
                font-weight: normal;
            }
        }
    }
}

.react-select__indicator.react-select__dropdown-indicator, span.react-select__indicator-separator.css-1okebmr-indicatorSeparator{
    opacity: 0;
}


select.custom-select,
#selectable,
.multipleSelect {
    background: #fff url(../../images/select.svg) center right 10px no-repeat;
    background-size: ptr(13) auto;

    .css-yk16xz-control {
        background-color: transparent;
    }

    .css-1hb7zxy-IndicatorsContainer {
        display: none;
    }

    .css-1pahdxg-control {
        background-color: transparent;

        &:hover,
        &:focus {
            border-color: $orange;
            box-shadow: 0 0 0 0.2rem rgba($color: $orange, $alpha: 0.2);
        }
    }

}

/*.multipleSelect {
    svg {
        visibility: hidden;
    }
}*/

.getstartedcheckbox {
    margin: 0 1.5% ptr(12) 0;
    padding: 0;
    width: 32.33%;

    .custom-control-input {
        &:checked {
            &~ {
                label {
                    background-color: $gris;
                    color: #fff;

                    &:after {
                        background: #fff url(../../images/check.svg) center no-repeat !important;
                        background-size: 80% auto !important;
                    }
                }
            }

            &>.getstartedcheckbox {
                background-color: red;
            }
        }
    }

    label {
        background-color: $gris-disabled;
        border-radius: ptr(7.3);
        padding: ptr(10) ptr(10) ptr(10) ptr(32);
        width: 100%;
        cursor: pointer;

        &:after {
            border-radius: 0 !important;
            border: 1px solid $gris;
            background-color: #fff;
            width: ptr(18);
            height: ptr(18);
            left: ptr(9);
            top: ptr(19);
        }

        &:before {
            background-color: transparent !important;
            width: 0;
            height: 0;
            top: ptr(18);
            left: ptr(12);
        }
    }

    &:nth-child(3n + 3) {
        margin-right: 0;
    }

    @media (max-width: 600px) {
        margin: 0 0 ptr(12);
        width: 100%;
    }
}

.searchWord {
    margin: 0 auto;
    padding: ptr(36) 0;
    width: ptr(400);
    position: relative;

    .keyword {
        width: 100%;
        height: ptr(36);
        border: 1px solid $gris;
        border-radius: ptr(3);
        padding: 0 ptr(15) 0 ptr(40);
    }

    button {
        position: absolute;
        top: 33%;
        left: 0;
        border: none;
        color: $gris;
        font-size: ptr(30);
        background: transparent;
        height: ptr(36);
        padding: 0;
        height: ptr(36);
        width: ptr(40);
    }

    @media (max-width: 900px) {
        width: 80%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
}

.formGetInTouch {
    .form-group {
        position: relative;
        padding-bottom: ptr(30);
        margin-bottom: 0 !important;

        label {
            position: absolute;
            top: ptr(-23);
            margin-bottom: 0;

            &.noabs {
                position: static;
            }
        }

        &.checkContent {
            @extend .d-flex;

            input {
                margin: ptr(5) ptr(12) 0 0;
            }

            span {
                color: $bleu;
                cursor: pointer;
            }
        }
    }

    .lastandfirstname {
        @extend .d-flex;
        @extend .justify-content-between;

        .form-group {
            width: 48%;
        }

        @media (max-width: 768px) {
            flex-direction: column;

            .form-group {
                width: 100%;
            }
        }
    }
}

.multichoosecontent,
.itemEdit {
    .custom-radio {
        border-bottom: 1px solid #e8e8e8;
        padding: ptr(24) 0 ptr(24) ptr(30);
        font-size: ptr(15);

        &:last-child {
            border-bottom: 0;
        }

        .custom-control-label {
            &:before {
                background: linear-gradient(#ffff, #c1bdbe);
            }
        }

        input:checked {
            &~.custom-control-label {
                &:after {
                    background-image: none;
                    background-color: $bleu;
                    border-radius: ptr(10);
                    width: ptr(8);
                    height: ptr(8);
                    top: ptr(8);
                    left: ptr(-20);
                }

                &:before {
                    border-color: #adb5bd;
                }
            }
        }
    }
}

.itemEdit {
    .custom-radio {
        border: 0;
        padding: 0 0 0 ptr(20);
        margin: 0 0 0 ptr(30);

        label {
            &:before {
                top: 0;
            }
        }

        input:checked~label.custom-control-label:after {
            top: ptr(4);
        }
    }
}

.formForEmail,
.btncloseaccount {
    input {
        height: ptr(35);
        border: 1px solid #a59b9b;
        width: 100%;
        color: $gris;
        margin: 0 0 ptr(10) 0;
        border-radius: ptr(2);
        padding: 0 0 0 ptr(10);
    }

    button {
        margin: 0 0 0 ptr(10);
        padding: 0 ptr(25) !important;
        font-size: ptr(16);
        font-weight: 600;
        height: ptr(35);
        min-height: ptr(35);
        min-width: 5.9375rem;
        border-radius: ptr(2);
        border: 1px solid;

        &.cancel {
            background: #e7e7e7;
            border-color: #cecece;
            color: #787878;
            /* margin-left:0 */
        }

        &.saveEmail {
            border-color: $orange;
            background-color: $orange;
            color: #fff;
        }
    }

    button:disabled {
        opacity: 0.5;
    }

    &.changePassword {
        width: ptr(400);
        padding: ptr(20);
    }

    @media (max-width: 600px) {
        &.changePassword {
            width: 100%;
        }
    }
}

.readOnlyArea {
    position: relative;

    &:after {
        position: absolute;
        bottom: 0;
        background-color: $gris-disabled;
        content: "";
        left: ptr(10);
        border-radius: ptr(3);
        height: ptr(40);
        width: calc(100% - 10px);
    }

    input,
    label {
        display: none;
    }
}

.proMail {
    position: relative;

    .sufixProMail {
        position: absolute;
        right: ptr(10);
        top: ptr(6);
    }

    input {
        padding: 0 ptr(150) 0 ptr(10);
    }
}

.messageClaim.form-control {
    resize: none;
    height: ptr(100);
}

.finalpopup {
    padding: ptr(200) 0 0 0;
    background-position: center top 75px;
    background-repeat: no-repeat;
    background-size: ptr(95);
    text-align: center;

    &.success {
        background-image: url(../../images/sent.svg);
    }
}


/*
.rdw-image-wrapper {
  display: none;
}*/

.rdw-option-wrapper,
.rdw-dropdown-wrapper {
    &:hover {
        box-shadow: none !important;
        border: 1px solid #8b8989;
    }
}

.disabled-ma-btn {
    pointer-events: none !important;
    opacity: 1;
    background-color: #e9ecef !important;
    cursor: not-allowed !important;
}

.rdw-image-alignment-options-popup {
    display: none  !important ;
}
