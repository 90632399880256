@import "~bootstrap/scss/bootstrap";
@import "resources/scss/devinsider-custom/fonts";
@import "resources/scss/devinsider-custom/variables";
@import "resources/scss/devinsider-custom/icons";
@import "resources/scss/devinsider-custom/images";
@import "resources/scss/devinsider-custom/forms";
@import "resources/scss/devinsider-custom/link";
@import "resources/scss/devinsider-custom/layout";
@import "resources/scss/devinsider-custom/general";
@import "resources/scss/devinsider-custom/typography";

.match {
  color: red;
}
