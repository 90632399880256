 @import'../../../../../style.scss';
.financialcontainer{
    font-size: ptr(15);
    .title{
        font-size: ptr(15);
    }
    a {
        //color:$orange;
        color:#ff6600;
    }
    h5{
        font-weight: 600;
    }
    .bord{
        border: 1px solid #C1C1C1;
        padding: 15px;
        margin-bottom: 2rem;
        position: relative;
    }
    .bord.col-sm-6{
        flex: 0 0 46%;
        padding: 15px;
        margin: 10px auto 10px 15px;
    }
    .contentGraphFinancial{
        border: 2px dashed transparent;
        position: relative;
        canvas {
            background: #f7f7f7;
        }
    }
    .stat {
        margin-bottom: ptr(20);
    }
    @media screen and (max-width: 767px) {
        .bord.col-sm-6 {
            flex: initial;
            margin: 10px;
        }
        .textTop {
            width: auto;
        }
    }
}
.editPageCompany{
    .bord.funding{
        border-right: 0;
        margin-right: 0;
    }
    .bord.funding p, .bord.amount p {
        margin-bottom: 0.3rem;
    }
    .bord.amount{
        border-left: 0;
        margin-left: 0;
        flex: 1 0 50% !important;
    }
}
.companypage{
    &.editPageCompany {
        .linkEditLeftCompany{
            color: $bleu;
            top: ptr(3);
            right: 0;
            z-index: 99;
        }
        .contentGraphFinancial{
            border-color: #d0d2d3;
            min-height: 18.75rem;
        }
    }
}