@import "../../../../style.scss";
.forDesactivate {
    h2.title {
        font-size: ptr(18);
        font-weight: bold;
        margin: 0;
        padding: ptr(20) 0 ptr(20) ptr(30);
        text-align: left;
    }
    .headerpermission {
        background: #4f4f4f;
        color: white;
        border-bottom: 2px solid $orange;
        width: 100%;
        .close {
            position: absolute;
            border-radius: 100%;
            width: 1.25rem;
            height: 1.25rem;
            border: 1px solid white;
            padding: 1px 1.5px;
            margin: 0;
            top: 1.3rem;
            right: 0.625rem;
            opacity: 1;
            color: white;
            font-weight: 100 !important;
            font-size: 15px;
            span {
                display: block;
            }
        }
    }
    .icon-info {
        color: #ffcc00;
    }
    .modal-dialog {
        width: 25rem;
        max-width: 90%;
        position: relative;
    }
    .modal-header {
        padding: 0;
        margin: 0;
        position: relative;
    }
    .modal-body {
        p {
            text-align: center;
        }
    }
}

.role_details {
    .icon-help-circle {
        color: #fff;
        background-color: #000;
        border-radius: 50%;
    }
    .test {
        text-align: left;
    }
}
#roledetails {
    width: ptr(470);
    font-size: ptr(12);
    inset: auto auto -535px 205px !important;
    &.show {
        opacity: 1;
    }
    .tooltip-inner {
        max-width: 100%;
        padding: 20px;
        color: #000;
        text-align: left;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 1px 1px 4px;
    }
    ul,
    li{
        list-style-type: disc;
        font-size: ptr(12);
    }
    ul {
        padding-left: 30px;
    }
}

.forRemove {
    .program {
        .image {
            img {
                border-radius: 50%;
            }
        }
    }
    .desc {
        .titleProgram {
            color: $bleu;
        }
        input {
            padding: 5px;
        }
    }
}

select {
    &.select_role {
        min-width: ptr(230);
    }
    &.custom-select {
        &:first-child {
            min-width: ptr(220);
        }
    }
}