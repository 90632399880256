@import "../../../style.scss";
.headerNonFixed {
    margin-top: ptr(-47);
    header {
        position: relative;
    }
}

.headPerso {
    .rightMenuHeader {
        @extend .d-flex;
    }
    .leftMenuHeader {
        @include display-desktop-only("flex");
    }
    .mobileOnly {
        @include display-tablet-mobile("flex");
    }
    .menuHeader {
        position: relative;
    }
    .longMenu {
        display: flex;
        color: #fff;
        justify-content: space-between;
    }
    .logoContent {
        img {
            width: ptr(140);
            margin-top: ptr(-5);
        }
    }
    .menuPerso {
        display: flex;
        height: ptr(50);
        margin: 0;
        padding: 0 0 0 ptr(25);
        li {
            display: flex;
            flex-direction: column;
            height: auto;
            overflow: visible;
            padding: 0 ptr(20) 0 0!important;
            a {
                height: ptr(50);
                color: #cccccc;
                text-transform: uppercase;
                font-weight: 600;
                font-size: ptr(17);
                @extend .d-flex,
                .align-items-center;
                padding: 0 ptr(10);
                @media(max-width: 1024px) {
                    font-size: ptr(16);
                    font-weight: normal;
                    text-transform: none;
                }
            }
            div.subMenu {
                width: 100%;
                height: auto;
                background-color: #fff;
                z-index: 1;
                top: ptr(50);
                left: 0;
                box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.16);
                color: $gris;
                .title {
                    border-bottom: 2px solid $orange;
                    padding: ptr(5) 0;
                    .container {
                        @extend .d-flex,
                        .align-items-center;
                    }
                    span {
                        font-size: ptr(24);
                        font-weight: 600;
                        padding: 0 ptr(16) 0 0;
                        margin: 0 ptr(16) 0 0;
                        border-right: 1px solid #969696;
                        cursor: pointer;
                    }
                }
                a {
                    border-radius: 3px;
                    text-transform: none;
                    color: $gris;
                    flex-direction: column;
                    width: 23%;
                    margin: ptr(20) 1%;
                    height: auto;
                    padding: ptr(5) ptr(20);
                    align-items: flex-start!important;
                    span {
                        padding: 0 0 ptr(20) 0;
                    }
                    p {
                        font-weight: normal;
                        font-size: ptr(15);
                    }
                    &:hover {
                        background-color: #e9ecef;
                    }
                }
            }
            &:hover {
                a.parent {
                    color: #fff;
                    span {
                        border-color: #fff;
                    }
                    &:after {
                        position: absolute;
                        content: "";
                        bottom: -3px;
                        left: calc(50% - 10px);
                        width: 0;
                        height: 0;
                        border: 0 solid transparent;
                        border-right-width: 10px;
                        border-left-width: 10px;
                        border-bottom: 10px solid #fff;
                    }
                }
            }
        }
    }
    @media(max-width: 1024px) {
        .menuPerso {
            opacity: 0;
            width: 100%;
            height: calc(100vh - 47px)!important;
            position: absolute;
            top: ptr(47);
            left: -120vw;
            background-color: rgb(68, 68, 68);
            z-index: 300;
            flex-direction: column;
            overflow-y: auto;
            padding: 0!important;
            transition: all 500ms;
            li {
                padding: 0!important;
                a {
                    border-bottom: 1px solid #3a3a3a;
                    &:after {
                        display: none;
                    }
                    span {
                        border-color: transparent!important;
                    }
                    i {
                        display: inline-block;
                        margin: 0 0 0 ptr(5);
                        font-style: normal!important;
                        font-size: ptr(10);
                        font-weight: 600;
                        background-color: #e65c5c;
                        border-radius: 20px;
                        color: #fff;
                        min-height: ptr(25);
                        width: ptr(25);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .subMenu {
                    position: static;
                    display: none;
                    overflow: hidden;
                    transition: all 2000ms;
                    a {
                        border-color: #cccccc;
                    }
                }
                &:active,
                &:hover,
                &:focus,
                &:focus-visible,
                &:focus-within,
                &:active {
                    .subMenu {
                        display: block;
                    }
                }
            }
        }
        .subMenu {
            div.title {
                display: none;
            }
            .container {
                margin: 0;
                padding: 0!important;
                flex-direction: column;
                min-height: auto;
                a {
                    margin: 0!important;
                    width: 100%!important;
                    padding: ptr(10) ptr(16);
                    p {
                        display: none;
                    }
                    span {
                        padding: 0!important;
                    }
                }
            }
        }
    }
    .profilImg.empty {
        background-color: #fff;
    }
}

.menuMobile {
    @include display-tablet-mobile("block");
    position: absolute;
    right: ptr(16);
    top: ptr(5);
    width: ptr(40);
    height: ptr(40);
    p {
        position: relative;
        width: ptr(40);
        height: ptr(40);
        margin: 0;
        padding: 0;
        transition: all 500ms;
        &:before,
        &:after {
            position: absolute;
            width: 100%;
            height: 2px;
            content: "";
            left: 0;
            background-color: #fff;
            transition: all 500ms;
        }
        &:before {
            top: ptr(8);
        }
        &:after {
            bottom: ptr(8);
        }
        span {
            width: 100%;
            height: 2px;
            background-color: #fff;
            position: absolute;
            top: calc(50% - 1px);
            left: 0;
            transition: all 500ms
        }
    }
}

.subMenu {
    display: none;
    position: absolute;
}

li:hover,
li:active,
li:focus,
li:focus-visible,
li:focus-within,
li:active {
    .subMenu {
        display: block;
    }
}

a.parent {
    position: relative;
    z-index: 1;
    span {
        border-bottom: 2px solid transparent;
    }
}

.menuConnected {
    @extend .d-flex,
    .align-items-center;
    height: ptr(50);
    li {
        margin: 0 ptr(10) 0 0;
        position: relative;
    }
    .iconLink {
        height: ptr(50);
        @extend .d-flex,
        .align-items-center;
        padding: ptr(10);
        font-size: ptr(20);
        color: #fff;
    }
    .subMenu {
        width: ptr(300);
        min-height: ptr(50);
        background-color: #fff;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: .16);
        left: calc(50% - 150px);
        &.notif {
            font-size: ptr(12);
            color: $gris;
            .title {
                padding: ptr(10);
                text-align: center;
            }
            .notifItem {
                font-size: ptr(12);
                padding: ptr(5) ptr(10) ptr(10) ptr(40);
                background: url(../../../resources/images/comment.svg) left 13px top 9px no-repeat;
                background-size: ptr(16) auto;
                width: 100%;
                display: block;
                color: $gris;
                border-top: 1px solid #c7c7c7;
            }
        }
    }
}

a.allNotif {
    font-size: ptr(13);
    text-align: center;
    padding: ptr(5);
    border-top: 1px solid #c7c7c7;
    color: $gris;
    display: block;
    &:hover {
        background-color: #e9ecef;
        color: $gris;
    }
}

.secondHeader {
    width: 100%;
    height: ptr(58);
    background-color: #efedee;
    @extend .d-flex,
    .align-items-stretch;
    .container {
        @extend .d-flex,
        .align-items-center,
        .justify-content-between;
        .leftSecond {
            @extend .d-flex,
            .align-items-center;
            h1.title {
                font-size: ptr(24);
                font-weight: 300;
                color: $gris;
                width: ptr(140);
                padding: 0 ptr(5) 0 0;
                margin: 0 ptr(30) 0 0;
                border-right: 1px solid #969696;
            }
        }
    }
    &.fixedSecondHeader {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 20;
        box-shadow: 0 0 5px rgba($color: #000000, $alpha: .5);
    }
}

.MenuSecondMenu {
    @extend .d-flex,
    .align-items-stretch;
    height: ptr(58);
    li {
        margin: 0 ptr(65) 0 0;
        padding: 0;
        position: relative;
        @extend .d-flex,
        .align-items-center;
        a {
            color: $gris;
            &.active {
                font-weight: 600;
            }
        }
        &:last-child {
            margin: 0;
        }
        .subMenu {
            background-color: #fff;
            box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.16);
            width: ptr(300);
            left: calc(50% - 150px);
            top: ptr(55);
            z-index: 20;
            a {
                width: 100%;
                padding: ptr(16);
                display: block;
                border-bottom: 1px solid #cccccc;
                &:last-child {
                    border-bottom: 0;
                }
                &:hover {
                    background-color: #e9ecef;
                }
            }
            &~a {
                position: relative;
                padding-right: ptr(30);
                &:after {
                    content: "";
                    width: ptr(20);
                    height: ptr(20);
                    right: ptr(5);
                    top: ptr(2);
                    background: url(../../../resources/images/selectbg.svg) center no-repeat;
                    background-size: 100% auto;
                    position: absolute;
                    transition: all 500ms;
                }
            }
        }
        &:hover {
            a:after {
                transform: rotate(180deg);
            }
        }
    }
}

.startPost {
    width: ptr(190);
    height: ptr(32);
    @extend .d-flex,
    .align-items-center,
    .justify-content-center;
    color: #fff;
    background: $orange;
    border-radius: 3px;
    &:hover {
        color: #fff;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: .16);
    }
    &.parent {
        width: 100%;
        color: #fff!important;
        justify-content: start!important;
        border-radius: 0;
    }
}

.userMenu {
    li {
        margin: 0;
        padding: 0;
        a {
            border-bottom: 1px solid #c7c7c7;
            color: $gris;
            padding: ptr(10);
            width: 100%;
            display: block;
            font-size: ptr(16);
            &:hover {
                background-color: #e9ecef;
            }
        }
        &:last-child a {
            border-bottom: 0;
        }
    }
}

.listPost {
    width: 100%;
    @extend .d-flex,
    .justify-content-center
}

.choicePostcontainer {
    width: ptr(780)!important;
}

a.choicePost {
    width: ptr(180);
    height: ptr(180);
    @extend .d-flex,
    .flex-column,
    .justify-content-center,
    .align-items-center;
    text-align: center;
    background-color: #eeeeee;
    color: $gris;
    font-size: ptr(13);
    font-weight: bold!important;
    text-transform: uppercase;
    border-radius: 8px;
    margin: ptr(7);
    position: relative;
    span[class *="icon-"] {
        color: $orange;
        font-size: ptr(40);
        margin-bottom: ptr(10);
    }
    &:hover,
    &.active {
        background-color: $gris;
        color: #fff;
        span {
            color: #fff;
        }
    }
    &.active {
        &:before {
            position: absolute;
            top: 10px;
            right: 10px;
            content: "";
            width: ptr(24);
            height: ptr(24);
            background: url(../../../resources/images/checked-active.svg) center no-repeat;
            background-size: 100% auto;
        }
    }
}

.notConnected {
    .item {
        margin: 0!important;
        a {
            font-family: 'Poppins';
            font-size: 15px;
            //height: ptr(30) !important;
        }
    }
    a {
        @extend .d-flex,
        .align-items-center;
        font-size: ptr(17);
        font-weight: 600;
        text-transform: uppercase!important;
        &.signinHeader {
            color: $orange!important;
            margin-right: ptr(20);
        }
        &.getstartedHeader {
            margin: ptr(5) 0;
            height: ptr(36);
            background: #fff;
            //border-radius: ptr(3);
            padding: 0 ptr(30);
            color: $orange!important;
            text-transform: none !important;
            font-weight: normal;
            &:hover {
                color: #fff!important;
                background-color: $orange;
            }
            &.orange {
                background: $orange;
                color: #fff !important;
                &:hover {
                    color: $orange!important;
                    background-color: #fff;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .headerNonFixed {
        margin-top: 0;
    }
    .openMenu {
        overflow: hidden;
        .menuMobile {
            span {
                width: 100%;
                height: 100%;
                border-radius: 50px;
                top: 0;
                left: 0;
                opacity: 0;
            }
            p {
                transform: rotate(225deg);
                width: ptr(30);
                height: ptr(30);
                &:after {
                    left: 2%;
                    top: 45%;
                    transform: rotate(90deg);
                }
                &:before {
                    top: calc(50% - 1px);
                }
            }
        }
        .headPerso {
            .menuPerso {
                opacity: 1;
                left: 0;
            }
        }
    }
    .ifConnectedLink {
        display: none!important;
    }
}

.dynamicMenu {
    position: static;
    min-height: auto;
    .container {
        @extend .d-flex,
        .align-items-center,
        .justify-content-between;
        .logoHeader {
            width: auto;
            height: 100%;
            padding-right: ptr(34);
        }
    }
    .principalMenu {
        @extend .d-flex,
        .align-items-stretch;
        width: 100%;
        height: ptr(76);
        background: #163a64;
        position: relative;
        z-index: 90;
        /* border-bottom: 3px solid $orange; */
    }
    .secondMenu {
        @extend .d-flex,
        .align-items-stretch;
        width: 100%;
        background-color: #efedee !important;
        @media (max-width: 768px) {
            height: 135px !important;
            display: none !important;
        }
        .container {
            @extend .align-items-stretch;
        }
        .leftItemHeader {
            @extend .align-items-stretch;
            h2.title {
                @extend .d-flex,
                .align-items-center;
                height: ptr(30);
                font-size: ptr(24);
                font-weight: 300;
                color: $gris;
                min-width: ptr(140);
                margin: auto ptr(30) auto 0;
                padding: 0 ptr(10) 0 0;
                border-right: 1px solid #969696;
            }
        }
        .rightItemHeader {
            @extend .d-flex,
            .align-items-center;
        }
        .logoRound {
            //margin: 0 ptr(20) 0 0;
            width: 0;
            height: auto;
            transition: all 200ms;
            margin-right: 1rem;
        }
        &.fixedSecondMenu {
            .logoRound {
                width: ptr(40);
            }
        }
        .leftItemHeader {
            height: ptr(53) !important;
        }
    }
    .leftItemHeader {
        @extend .d-flex,
        .align-items-stretch;
        height: ptr(76);
        @media (max-width: 768px) {
            height: auto;
        }
    }
    .menuDynamic {
        @extend .d-flex,
        .align-items-stretch;
        .item {
            @extend .d-flex,
            .align-items-center;
            //position: relative;
            //margin: 0 ptr(36) 0 0;
            a {
                @extend .d-flex,
                .align-items-center
            }
            .submenu {
                position: absolute;
                display: none;
                z-index: 10;
            }
            &:hover {
                .submenu {
                    display: block;
                }
            }
            &:last-child {
                margin-right: 0;
            }
            span.mobile {
                display: none;
            }
        }
        &.tl {
            .item {
                &.noArrow {
                    &:hover {
                        background: none;
                    }
                }
                &:hover {
                    background: url(../../../resources/images/hoverMenu.svg);
                    background-repeat: no-repeat;
                    background-position: center bottom;
                    background-size: 25px;
                }
                & > .linkHeader{
                    &:first-child {
                        font-family: 'Poppins';
                        font-size: ptr(18);
                        font-weight: normal;
                        text-transform: none;
                        color: #ffffff;
                        height: ptr(20);
                        @media (max-width: 768px) {
                            height: auto;
                        }
                    }
                    &:hover {
                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -7px;
                            left: 11px;
                            width: calc(100% - 20px);
                            height: 3px;
                            background-color: #fff;
                        }
                    }
                }
                .borRight {
                    border-right: 3px solid;
                    height: 20px;
                    @media (max-width: 768px) {
                        border-right: none !important;
                    }
                }
                a {
                    font-size: ptr(17);
                    text-transform: uppercase;
                    color: #cccccc;
                    font-weight: 600;
                    padding: 0 ptr(10);
                    position: relative;
                    &.active {
                        color: #fff;
                    }
                    span.puissance {
                        color: #30D64E;
                        font-size: ptr(10);
                        position: absolute;
                        top: 8px;
                        right: -1px;
                        border: 1.5px solid #30D64E;
                        border-radius: 2px;
                        text-transform: none;
                        font-weight: 600;
                        line-height: 1.25;
                        @media(max-width: 1024px) {
                            position: relative;
                            top: -8px;
                            right: 7px;
                            z-index: -1;
                        }
                    }
                }
                /* &:hover {
                    a {
                        &:after {
                            content: "";
                            position: absolute;
                            bottom: ptr(14);
                            left: 0;
                            width: 100%;
                            height: 2px;
                            background: #fff;
                        }
                        &.parent {
                            &:before {
                                content: "";
                                border: 10px solid transparent;
                                border-bottom: 10px solid #fff;
                                width: 0;
                                left: calc(50% - 5px);
                                bottom: 0;
                                position: absolute;
                            }
                        }
                    }
                } */
                .submenu {
                    width: 100%;
                    height: auto;
                    background: #fff;
                    left: 0;
                    top: ptr(76);
                    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.16);
                    .container {
                        @extend .d-flex,
                        .align-items-start,
                        .justify-content-start;
                    }
                    .title {
                        border-bottom: 2px solid $orange;
                        padding: ptr(5) 0;
                        .container {
                            @extend .align-items-center;
                        }
                        span {
                            font-size: ptr(24);
                            font-weight: 600;
                            padding: 0 ptr(16) 0 0;
                            margin: 0 ptr(16) 0 0;
                            border-right: 1px solid #969696;
                            cursor: pointer;
                        }
                    }
                    a {
                        border-radius: 3px;
                        text-transform: none;
                        color: $gris;
                        flex-direction: column;
                        width: 23%;
                        margin: ptr(20) 1%;
                        height: ptr(150);
                        padding: ptr(5) ptr(20);
                        align-items: flex-start!important;
                        &:before,
                        &:after {
                            display: none!important;
                        }
                        span {
                            padding: 0 0 ptr(20) 0;
                        }
                        p {
                            font-weight: normal;
                            font-size: ptr(15);
                        }
                        &:hover {
                            background-color: #e9ecef;
                            &:before,
                            &:after {
                                display: none!important;
                            }
                        }
                    }
                }
                .comingSoon {
                    position: relative;
                    cursor: no-drop;
                    &::after {
                        content: "Coming soon!";
                        color: #fff;
                        font-size: 11px;
                        font-family: 'Poppins';
                        position: absolute;
                        bottom: -16px;
                        left: 10px;
                    }
                    &:hover {
                        &:after {
                            content: "Coming soon!";
                            background-color: transparent;
                            bottom: -3px;
                        }
                    }
                }
            }
            &.justForProgram {
                .item {
                    margin-right: ptr(10);
                    z-index: 10;
                }
            }
        }
        &.tr {
            @extend .justify-content-end;
            .item {
                position: relative;
                margin-left: 20px !important;
                &.special {
                    border-left: 1px solid #cccccc;
                    width: ptr(100);
                    a {
                        height: ptr(40);
                        width: 100%;
                        padding: 0 ptr(5);
                        @extend .flex-column,
                        .align-items-center,
                        .justify-content-start;
                        .nameProgram {
                            @extend .d-flex,
                            .align-items-center,
                            .justify-content-center;
                            width: 100%;
                            .name {
                                font-size: ptr(12);
                                width: 80%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            .icon-sparow {
                                display: block;
                                transform: rotate(90deg);
                                font-size: ptr(10);
                            }
                        }
                    }
                }
            }
            a {
                color: #fff;
                span[class *="icon-"] {
                    font-size: ptr(20);
                }
            }
            .profilImg {
                background-color: #fff;
                margin-right: 0;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .submenu {
                background: #fff;
                top: ptr(40);
                width: ptr(200);
                height: auto;
                left: calc(50% - 182px);
                box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.16);
                a {
                    padding: ptr(10);
                    border-bottom: 1px solid #969696;
                    color: $gris;
                    &:last-child {
                        border-bottom: none;
                    }
                    &:hover {
                        font-weight: 600;
                    }
                }
            }
            &.justForProgram {
                .item {
                    margin-right: ptr(25);
                }
            }
        }
        &.bl {
            a {
                color: $gris;
                padding-right: 1.25rem;
                &.active,
                &:target {
                    font-weight: 600;
                }
                &:hover {
                    color: #000
                }
            }
            .item {
                position: relative;
                a.parent {
                    padding-right: ptr(20);
                    &:after {
                        background: url(../../../resources/images/selectbg.svg) center no-repeat;
                        background-size: ptr(20) auto;
                        content: "";
                        width: ptr(20);
                        height: ptr(20);
                        transition: all 500ms;
                    }
                }
                .submenu {
                    background: #fff;
                    top: ptr(50);
                    width: ptr(250);
                    height: auto;
                    left: calc(50% - 100px);
                    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.16);
                    a {
                        padding: ptr(10);
                        border-bottom: 1px solid #969696;
                        &:last-child {
                            border-bottom: none;
                        }
                        &:hover {
                            font-weight: 600;
                        }
                    }
                }
                &:hover {
                    a.parent:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    &.firstFix {
        margin-top: ptr(76);
        @media(max-width: 1024px) {
            margin-top: 0;
        }
        .principalMenu {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 100;
        }
    }
    &.secondFix {
        .fixedSecondMenu {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 90;
            box-shadow: 0 0 10px rgba($color: #000000, $alpha: .16);
        }
    }
    @media(max-width: 1024px) {
        height: ptr(47);
        background-color: #163a64;
        border-bottom: 3px solid $orange;
        width: 100%;
        position: relative;
        .containerMenuForMobile {
            display: flex;
            flex-direction: column;
            transition: all 500ms;
            background-color: #163a64;
            height: calc(100vh - 47px);
            width: 100%;
            position: absolute;
            top: ptr(47);
            left: -100vw;
            overflow-y: auto;
            .logoHeader,
            .secondMenu .leftItemHeader h2.title {
                display: none!important;
            }
            .principalMenu,
            .secondMenu,
            .secondMenu.fixedSecondMenu {
                background: transparent;
                border: 0;
                height: auto;
                min-height: auto;
                position: relative;
            }
            .container {
                width: 100%;
                margin: 0;
                padding: 0;
                flex-direction: column;
                align-items: flex-start;
                .leftItemHeader,
                .rightItemHeader {
                    width: 100%;
                    .item {
                        width: 100%;
                        a.startPost {
                            color: $orange;
                            background: transparent;
                            width: 100%;
                            padding: ptr(10) ptr(16)!important;
                            text-align: left;
                            justify-content: flex-start!important;
                            height: auto;
                        }
                        &.special {
                            border: none;
                            span[class *="icon-"] {
                                display: none!important;
                            }
                            .nameProgram {
                                .name {
                                    width: 100%;
                                    font-size: ptr(16);
                                }
                            }
                        }
                    }
                }
                .menuDynamic {
                    width: 100%;
                    align-items: flex-start!important;
                    flex-direction: column!important;
                    .item {
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        a {
                            @media (max-width: 768px) {
                                color: #525252;
                            }
                            text-transform: none;
                            width: 100%;
                            text-align: left;
                            padding: ptr(10) ptr(16);
                            font-weight: normal!important;
                            font-size: ptr(16);
                            color: #fff;
                            border-bottom: 1px solid #525252;
                            span[class *="icon-"] {
                                color: #fff;
                                font-size: ptr(12);
                                margin: 0 ptr(10) 0 0;
                            }
                            span.mobile {
                                display: inline-block;
                            }
                            .profilImg {
                                display: none!important;
                            }
                            &.parent {
                                position: relative;
                                &:after {
                                    background: url(../../../resources/images/selectbg.svg) center left no-repeat;
                                    width: ptr(20);
                                    height: ptr(20);
                                    background-size: ptr(20) auto;
                                    content: "";
                                    position: absolute;
                                    right: ptr(10)!important;
                                    left: auto!important;
                                    top: ptr(10);
                                    transition: all 500ms;
                                }
                            }
                            &.getstartedHeader,
                            &.signinHeader {
                                color: #fff!important;
                                background-color: transparent;
                                text-transform: none!important;
                            }
                        }
                        .submenu {
                            width: 100%!important;
                            a {
                                margin: 0;
                                color: $gris;
                                padding: ptr(10);
                                width: 100%;
                                height: auto;
                                span {
                                    padding: 0;
                                }
                            }
                        }
                        &:hover {
                            a.parent {
                                &:before {
                                    display: none;
                                }
                                &:after {
                                    transform: rotate(180deg);
                                }
                                span {
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
            .item:last-child {
                a {
                    border-bottom: 1px solid #525252;
                }
            }
        }
        .menuDynamic {
            .item {
                flex-direction: column;
            }
            .submenu {
                position: static!important;
                width: 100%;
                .title,
                p {
                    display: none!important;
                }
            }
        }
    }
}

@media(max-width: 1024px) {
    .openMenu {
        .containerMenuForMobile {
            left: 0;
        }
    }
}

.settings .dynamicMenu .containerMenuForMobile .container {
    padding-top: 0;
}

.listOfProgramFixed {
    position: fixed;
    width: ptr(350);
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.16);
    top: 0;
    right: ptr(-400);
    transition: all 500ms;
    z-index: 99;
    h2.title {
        font-weight: 600;
        color: $gris;
        font-size: ptr(24);
        height: ptr(47);
        border-bottom: 3px solid $orange;
        width: 100%;
        @extend .d-flex,
        .flex-wrap,
        .align-items-center;
        padding: 0 ptr(20);
        margin: 0;
        position: relative;
        a {
            position: absolute;
            right: ptr(5);
            top: ptr(5);
            font-size: ptr(30);
            font-weight: normal;
            color: $gris;
        }
    }
    .listProgramUl {
        width: 100%;
        height: calc(100vh - 47px);
        overflow: auto;
        li {
            a {
                width: 100%;
                height: ptr(47);
                font-size: ptr(14);
                color: $gris;
                padding: 0 ptr(16);
                margin: 1px 0;
                @extend .d-flex,
                .align-items-center;
                &:hover,
                &.active {
                    background-color: #f4f6f9;
                }
            }
        }
    }
    &.openMenu {
        right: 0;
    }
    @media(max-width: 1024px) {
        width: 100%;
        right: -110%;
        &.openMenu {
            right: 0;
        }
    }
}