img {
  padding: 0;
}
.testsvg {
  width: ptr(100);
  height: auto;
  fill: red;
}
nav {
  .getstartedNav {
    img {
      width: ptr(140);
      height: auto;
    }
  }
  .logoHeaderCenter {
    height: ptr(24);
    width: auto;
    padding: 0;
    cursor: pointer;
  }
}
.imageAffiliated {
  width: ptr(60);
  height: ptr(60);
  border-radius: ptr(100);
  /*background: url(../../images/user.svg) center top 5px no-repeat;
  background-size: 110% auto;*/
  border: 1px solid #cccccc;
  @extend .d-flex, .justify-content-center, .align-items-center;
  overflow: hidden;
  img{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
.imageProfil {
  width: ptr(150);
  height: ptr(150);
  border-radius: ptr(300);
  overflow: hidden;
  background: url(../../images/user.svg) top 3px center no-repeat;
  background-size: 120% auto;
  @extend .d-flex, .justify-content-center, .align-items-center;
  border: 1px solid #cccccc;
  margin-right: ptr(20);
  img{
    width: 100%;
    height: 10rem;
    margin: 0;
    padding: 0;
    object-fit: cover;
  }
  &.noBg{
    background-image: none;
  }
  &.overnoBg{
    background-image: none;
  }
}
.profilImg, .leftDashboard .imageAffiliatedcompany{
  width:ptr(40);
  height: ptr(40);
  @extend .d-flex, .align-items-center, .justify-content-center;
  border-radius: ptr(40);
  border:1px solid #ced4da;
  margin: 0 ptr(10) 0 0;
  &.empty{
    background: url(../../images/user.svg) center no-repeat;
    background-size: 80% auto;
  }
  img{
    width: 100%;
    height: 4rem;
    margin: 0;
    padding: 0;
    object-fit: cover;
  }
}
.affiliatedPopup{
  .profilImg{
    width: ptr(60);
    height: ptr(60);
    border-radius: ptr(60);
    overflow: hidden;
  }
  img{
    width: 100%;
    height: 4rem;
    margin: 0;
    padding: 0;
    object-fit: cover;
  }
}
.listAffiliatedleft, .feedList{
  .imageAffiliatedcompany{
    width: ptr(60);
    height: ptr(60);
    overflow: hidden;
    border-radius: ptr(60);
    @extend .d-flex, .align-items-center, .justify-content-center;
    border:1px solid #707070;
    background-size: 80% auto;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    &.empty{
      background-image: url(../../images/user.svg);
    }
    img{
      margin: 0;
      padding:0 ;
      width: ptr(60);
      height:ptr(60);
      border-radius: ptr(60);
      object-fit: cover;
    }
  }
}
