@import"../../../style.scss";
.createCompanypopup.large.logincomp .modal-dialog {
    width: 25rem !important;
    max-width: 90%;
    position: relative;
}

.createCompanypopup.large.logincomp .headerpopupCompany {
    background: $gris;
    color: white;
    border-bottom: 2px solid $orange;
    h2 {
        text-align: left;
        padding-left: 2rem;
    }
    .close {
        position: absolute;
        border-radius: 100%;
        width: 1.25rem;
        height: 1.25rem;
        border: 1px solid white;
        padding: 0;
        margin: 0;
        top: 1.3rem;
        right: 0.625rem;
        opacity: 1;
        color: white;
        font-weight: 100 !important;
        font-size: 15px;
    }
}

.labeled {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1.5rem;
    &.int {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.orc {
    background: $orange;
    border: 1px solid $orange;
    border-radius: 3px;
    padding: 0.5rem;
    width: 100%;
    color: white;
}

.blexp {
    background: $bleu;
    border: 1px solid $bleu;
    border-radius: 3px;
    padding: 0.5rem;
    width: 100%;
    color: white;
    &.blue {
        background-color: #15a085;
        text-transform: capitalize;
        border-color: #15a085;
    }
}