@import "../../../../../style.scss";

.container-head-manage {
  @extend .d-flex;
  position: relative;
  width: 100%;

  .payement-info,
  .txt {
    width: 50%;
  }

  .txt {
    padding-right: 1rem;
  }

  .payement-info {
    @extend .radius;
    padding: 0 ptr(16);
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;

    .little {
      font-size: 0.8rem;
      color: $gris;
    }

    .bold {
      font-weight: 700;

      &.title {
        font-size: 1rem;
      }
    }
  }
}

.unlock-limit {
  .info {
    min-height: 160px;
    @extend .d-flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
