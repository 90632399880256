@import "../../../../../style.scss";
.contentBorder.forPitch {
    width: ptr(700);
    margin: 0 auto;
    @media (max-width: 1024px) {
        width: 100%;
    }
}

.strategycontainer {
    .geographicList .custom-checkbox .custom-control-label:before {
        background-color: none !important;
        border: none;
        content: none;
    }
    .geographicList .custom-control-label {
        margin-left: -1rem;
    }
    .liste {
        margin-bottom: 0.5rem;
    }
    .geographicList {
        border: none;
        .panel-toggle {
            display: flex;
        }
        .na {
            margin-left: ptr(10);
            .icon-check {
                font-size: ptr(11);
                font-weight: bold;
                padding-right: ptr(3);
            }
        }
    }
    button.panel-toggle {
        background: none;
        color: #4f4f4f !important;
    }
    button.panel-toggle:after {
        left: -0.3rem;
    }
    font-size: ptr(15);
    .linkEditLeftCompany {
        top: 0.22rem;
        right: 0;
    }
    .title {
        font-size: ptr(15);
    }
    .partnership,
    .licensing,
    .distribution,
    .target,
    .milestones,
    .product,
    .expansion {
        margin-bottom: 2rem;
        position: relative;
    }
    .target .accordion a,
    .partner .link a {
        color: $orange !important;
        font-weight: normal;
    }
    .partner .link {
        padding: 0;
        margin-top: 0.459rem;
    }
    h5 {
        font-weight: 600;
    }
    .bord {
        border: 1px solid #C1C1C1;
        margin: 10px auto 0 5px;
        padding: 15px;
    }
    .bord.col-sm-6 {
        flex: 0 0 49%;
        padding: 15px;
    }
    .list {
        margin-left: 0;
        margin-right: 0;
    }
    .target {
        li {
            padding-left: 15px;
        }
        ul li::before {
            content: "";
            border-color: transparent #111;
            border-style: solid;
            border-width: 0.35em 0 0.35em 0.45em;
            display: block;
            height: 0;
            width: 0;
            left: -1em;
            top: 1.1em;
            position: relative;
        }
        p {
            margin-top: 1rem;
        }
        p a {
            //color:$orange;
            color: #ff6600;
        }
    }
    .partner {
        ul li::before {
            content: "";
            border-color: transparent;
            border-style: solid;
            border-width: 0.35em 0 0.35em 0.45em;
            display: block;
            height: 0;
            width: 0;
            left: -1em;
            top: 1.1em;
            position: relative;
        }
        p {
            margin-top: 1rem;
        }
    }
    .partnership {
        img {
            width: ptr(40);
            height: ptr(50) !important;
            padding: 0;
            margin-right: ptr(5);
            object-fit: contain;
            @media (max-width: 767px) {
                width: ptr(60) !important;
                min-width: ptr(60);
                height: auto !important;
            }
        }
        ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-flow: row wrap;
            margin: 10px auto;
            @media (max-width: 767px) {
                display: block;
            }
            li {
                margin-right: 1.5rem;
                width: 20%;
                min-height: 75px;
                display: flex;
                align-items: center;
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
    .milestones {
        margin: 10px auto 0 5px;
        h5 {
            margin-top: 15px;
            margin-bottom: 0;
            font-size: 1.063rem;
        }
        .borderBottom {
            border-bottom: 1px solid #E5E5E5;
            padding-bottom: 1rem;
        }
        .fauxText {
            padding: 0;
        }
    }
    @media screen and (max-width: 767px) {
        .bord.col-sm-6 {
            flex: initial;
            margin: 10px;
        }
    }
}

.editPageCompany {
    .bord.licensing {
        border-right: 0;
        margin-right: 0;
    }
    .bord.licensing p,
    .bord.distribution p {
        margin-bottom: 0.3rem;
    }
    .bord.distribution {
        border-left: 0;
        margin-left: 0;
        flex: 1 0 50% !important;
    }
}

.textTop {
    width: 35rem;
    margin: 0.655rem auto 2rem auto;
}

.textTop ul {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8F8F8F;
    text-align: center;
}

.textTop li {
    margin-right: 0.5rem;
    font-size: 0.845rem;
    text-align: left;
}

.textTop span {
    font-size: 1.8rem;
}

.contentBorder.forStrategy {
    width: ptr(700);
    margin: 0 auto;
    .formForEmail,
    .px-5 {
        margin-right: 0 !important;
    }
    .form-group,
    .descCompany {
        padding-right: 0;
    }
    @media (max-width: 1024px) {
        width: 100%;
    }
}

.lockedFile {
    img {
        width: 100%;
        height: auto;
    }
}